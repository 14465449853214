import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";

import ListItemText from "@material-ui/core/ListItemText";
import {
  setNewCompanyDetails,
  viewOrganizationEsgGpt,
} from "../../../redux/actions/gpt/esgGptAction";
import CompanyA from "../../../images/CompanyA.png";
import companyB from "../../../images/companyB.png";
import companyC from "../../../images/companyC.png";
import companyX from "../../../images/companyX.png";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    // border: "solid",
    // height: "50%",
    // overflow: "scroll",
  },
}));

export const ListItem = withStyles((theme) => ({
  root: {
    "&$selected": {
      ...theme.typography.body1,
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.dark,
      border: `1px solid ${theme.palette.grey[400]}`,
      // fontSize: "14px",
      // fontWeight: 700,

      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },
    "&$selected:hover": {
      ...theme.typography.body1,
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.dark,
      // color: "#15314E",
      // fontSize: "14px",
      // fontWeight: 600,
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },
    "&:hover": {
      ...theme.typography.body1,
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.dark,
      // fontSize: "14px",
      // fontWeight: 600,
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },
    ...theme.typography.body2,
    color: theme.palette.primary.main,
    // border:`1px solid ${theme.palette.grey[400]}`,

    // fontSize: "14px",
    // fontWeight: 400,
    cursor: "pointer",
    marginTop: "5px",
  },
  selected: {},
}))(MuiListItem);

export default function EsgGptCompanyList() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const login = useSelector((state) => state.login);

  const { organizations } = useSelector((state) => state.esgGptReducer);

  // console.log(organizations);

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (event, index, organization) => {
    setSelectedIndex(index);
    dispatch(setNewCompanyDetails(organization));
  };

  const companiesArray = [
    { name: "Company A", logo: CompanyA, _id: 0, persona: 1, mark:"$40 Million as of March 2022", industry:"Information technology, Consulting ,Outsourcing" },
    { name: "Company B", logo: companyB, _id: 1, persona: 2, mark:"$40 Million as of March 2022", industry:"IT Consulting" },
    { name: "Company C", logo: companyC, _id: 2, persona: 3, mark:"$40 Million as of March 2022", industry:"IT Consulting" },
    { name: "Company D", logo: companyX, _id: 3, persona: 4, mark:"$40 Million as of March 2022", industry:"IT Consulting"  },
  ];

  React.useEffect(() => {
    // dispatch(viewOrganizationEsgGpt(login.token));
    dispatch(setNewCompanyDetails(companiesArray[0]));
  }, []);

  return (
    <div className={classes.root}>
      <List
        component="nav"
        aria-label="main mailbox folders"
        style={{
          // padding: "10px 20px 10px 5px",
          width: "94%",
          // marginLeft: "10px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {companiesArray.map((organizations, i) => {
          return (
            <ListItem
              key={organizations?._id}
              button
              selected={selectedIndex === i}
              onClick={(event) => handleListItemClick(event, i, organizations)}
              style={{ borderRadius: "8px" }}
            >
              <ListItemText primary={organizations?.name} />
            </ListItem>
          );
        })}
        {/* {organizations.map((organizations, i) => {
          return (
            <ListItem
              key={organizations?._id}
              button
              selected={selectedIndex === i}
              onClick={(event) => handleListItemClick(event, i, organizations)}
              style={{ borderRadius: "8px" }}
            >
              <ListItemText primary={organizations?.name} />
            </ListItem>
          );
        })} */}
      </List>
    </div>
  );
}
