import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Icon from "@material-ui/core/Icon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Drawer from "@material-ui/core/Drawer";
import DrawerListItem from "../listItem/drawerListItem";
import "../../../styles/drawer.css";
import logo from "../../../images/logo.svg";
import { Link, useHistory } from "react-router-dom";
import DrawerUseStyles from "./drawerStyles";
import vector from "../../../images/vector.png";
import settings from "../../../images/settings.png";
import reportListing from "../../../images/reportListing.png";
import surveyListing from "../../../images/surveyListing.png";
import getStarted from "../../../images/getStarted.png";

//removed.
function NavCustomDrawer({
  isOpen,
  setIsOpen,
  logOut,
  token,
  redirect,
  setRedirectValue,
  login,
}) {
  let history = useHistory();

  const classes = DrawerUseStyles();
  const [open, setOpen] = React.useState(false);
  const [render, setRender] = useState(false);
  const handleDrawerToggle = () => {
    setOpen(!open);
    setIsOpen(!isOpen);
  };
  const [tnc, setTnc] = useState(false);
  const [selectedIndex, setSelectedId] = React.useState(null);
  const setSelectedIndex = (index) => {
    setSelectedId(index);
  };
  useEffect(() => {
    setRender(!render);
    setSelectedId(null);
  }, []);
  // const userType = 'RA';
  const userType = localStorage.getItem("user_type");

  useEffect(() => {
    if (history.location.pathname === "/corporate_questions") {
      setSelectedId(0);
    }
    if (history.location.pathname === "/risk_evaluator") {
      setSelectedId(1);
    }
    if (history.location.pathname === "/risk_analysis") {
      setSelectedId(2);
    }
    if (history.location.pathname === "/risk_admin") {
      setSelectedId(3);
    }
  }, [history.location.pathname]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      {!tnc && (
        <Drawer
          onClose={handleDrawerToggle}
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <List className={classes.head}>
            <ListItem className={classes.head}>
              <ListItemIcon>
                {open ? (
                  <IconButton
                    className={classes.iconButton}
                    onClick={handleDrawerToggle}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                ) : (
                  <IconButton name="expandPanel" onClick={handleDrawerToggle}>
                    <Icon className={classes.iconButton}>menu</Icon>
                  </IconButton>
                )}
              </ListItemIcon>
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemIcon>
                {open &&
                  isOpen &&
                  login.selectedOrganization &&
                  login.selectedOrganization.domain_logo &&
                  login.selectedOrganization.domain_logo.after_login.logo && (
                    <div
                      style={
                        login.selectedOrganization &&
                        login.selectedOrganization.domain_logo.after_login.logo
                          ? login.selectedOrganization.domain_logo.after_login
                              .logo_styles
                          : JSON.parse(localStorage.getItem("logo_styles"))
                          ? JSON.parse(localStorage.getItem("logo_styles"))
                          : {}
                      }
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                        src={
                          login.selectedOrganization &&
                          login.selectedOrganization.domain_logo.after_login
                            .logo
                            ? login.selectedOrganization.domain_logo.after_login
                                .logo
                            : localStorage.getItem("logo") &&
                              localStorage.getItem("logo")
                        }
                        alt="logo"
                      />
                    </div>
                  )}
                {open &&
                  isOpen &&
                  login.selectedOrganization &&
                  login.selectedOrganization.domain_logo &&
                  !login.selectedOrganization.domain_logo.after_login.logo && (
                    <div>
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                        src={logo}
                        alt="logo"
                      />
                    </div>
                  )}
                {/* {open && isOpen && (
                <img src={localStorage.getItem('logo') && localStorage.getItem('logo')!== 'undefined' && localStorage.getItem('logo')!== 'null' ? localStorage.getItem('logo') : login.selectedOrganization && login.selectedOrganization.domain_logo.after_login.logo ? login.selectedOrganization.domain_logo.after_login.logo : logo } className={classes.img} alt="log" />
              )} */}
              </ListItemIcon>
            </ListItem>
          </List>
          <List className={classes.list2}>
            {userType === "corp" && (
              <Link to="/corporate_questions" className={classes.link}>
                <DrawerListItem
                  selected={selectedIndex === 0}
                  className={classes.listitems}
                  onClick={() => setSelectedIndex(0)}
                  button
                  data-tut="reactour__get_started"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={getStarted}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary={"Get Started"} />
                </DrawerListItem>
              </Link>
            )}

            {userType === "RA" && (
              <Link to="/risk_admin" className={classes.link}>
                <DrawerListItem
                  selected={selectedIndex === 3}
                  className={classes.listitems}
                  onClick={() => setSelectedIndex(1)}
                  button
                  data-tut="reactour__report_listing"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={vector}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="Dashboard" />
                </DrawerListItem>
              </Link>
            )}
            {userType === "RE" && (
              <Link to="/risk_evaluator" className={classes.link}>
                <DrawerListItem
                  selected={selectedIndex === 1}
                  className={classes.listitems}
                  onClick={() => setSelectedIndex(1)}
                  button
                  data-tut="reactour__report_listing"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={vector}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="Dashboard" />
                </DrawerListItem>
              </Link>
            )}
            {userType === "RM" && (
              <Link to="/risk_analysis" className={classes.link}>
                <DrawerListItem
                  selected={selectedIndex === 2}
                  className={classes.listitems}
                  button
                  data-tut="reactour__survery_listing"
                  onClick={() => {
                    setSelectedIndex(2);
                    // if (redirect) {
                    //   setRedirectValue(false);
                    // }
                  }}
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={surveyListing}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="Assessments" />
                </DrawerListItem>
              </Link>
            )}
            {(userType === "A" || userType === "SA") && (
              <Link to="/report_listing" className={classes.link}>
                <DrawerListItem
                  selected={selectedIndex === 3}
                  className={classes.listitems}
                  onClick={() => setSelectedIndex(3)}
                  button
                  data-tut="reactour__report_listing"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={reportListing}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="Tasks" />
                </DrawerListItem>
              </Link>
            )}
            {userType === "SA" && (
              <Link to="/data_hub/survey" className={classes.link}>
                <DrawerListItem
                  selected={selectedIndex === 4}
                  className={classes.listitems}
                  onClick={() => setSelectedIndex(4)}
                  button
                  data-tut="reactour__report_listing"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={vector}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="Data Hub" />
                </DrawerListItem>
              </Link>
            )}
            {
              <Link to="/general_settings" className={classes.link}>
                <DrawerListItem
                  selected={selectedIndex === 5}
                  className={classes.listitems}
                  onClick={() => setSelectedIndex(5)}
                  button
                  data-tut="reactour__general_settings"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={settings}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="General Settings" />
                </DrawerListItem>
              </Link>
            }
          </List>
          <Divider style={{ marginTop: "auto" }} />
          <List className={classes.list}>
            <Link to="/login" className={classes.link}>
              <DrawerListItem
                selected={selectedIndex === 6}
                className={classes.listitems}
                onClick={() => {
                  setSelectedId(null);
                  setIsOpen(false);
                  logOut(token);
                }}
                button
                name="logoutBtn"
              >
                <ListItemIcon>
                  <>
                    {open && <Icon fontSize="small">chevron_right</Icon>}
                    <Icon fontSize="small">logout</Icon>
                  </>
                </ListItemIcon>
                <ListItemText secondary="Logout" />
              </DrawerListItem>
            </Link>
          </List>
        </Drawer>
      )}
    </div>
  );
}
export default React.memo(NavCustomDrawer);
