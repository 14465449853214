import React from "react";

import EsgGptlayout from "../EsgGptlayout";
import EsgGptCompanyRight from "./EsgGptCompanyRight";
import EsgGptChatField from "../EsgGptChatField";
import EsgGptCompanyList from "../EsgGptCompanyList";

function EsgGptCompanyProfiles() {
  return (
    <EsgGptlayout EsgCompanyList={<EsgGptCompanyList />}>
      <EsgGptCompanyRight />
      <EsgGptChatField />
    </EsgGptlayout>
  );
}

export default EsgGptCompanyProfiles;
