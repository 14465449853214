import { createTheme } from "@material-ui/core/styles";
// import './utils/fonts/fonts.css'
import gradient from "./images/gradient.svg";
import latoRegular from "./utils/fonts/Lato-Regular.ttf";
import latoBold from "./utils/fonts/Lato-Bold.ttf"

const Lato_regular = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Lato'),
    local('Lato-Regular'),
    url(${latoRegular}) format('TrueType')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const Lato_Bold = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('Lato'),
    local('Lato-Bold'),
    url(${latoBold}) format('TrueType')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};


export const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#333F48", // text/primary
      light: "#EAEEF5", // paletone/blue 2  border-primary
      dark: "#11184F",
      contrastText: "#EAEEF5", // text/primary-active
      // grey: "#ffffffb3", // text/primary-active
      contrastColor: "#3E8DDD",
      green: "#32A05F",
    },
    secondary: {
      main: "#3E8DDD", // surface/surface-brand
      dark: "#294E95", // deeptone/deepBlue  primary button
    },
    grey: {
      100: "#f6f6f6",
      300: "#DDDDDD", // surface/secondary-default
    },
    action: {
      hover: "#EBF3FB", // card/primary-hover
    },
    background: {
      paper: "#FFFFFF",
      default: "#FFFFFF", // surafce/primary
      contentBG: "#242D33",
    },

    // primary:red
  },
  shape: {
    borderRadius: "8px",
  },
  typography: {
    fontFamily: ["poppins", "sans-serif", "Lato"].join(","),
    body1: {
      fontSize: "0.875rem",
      fontFamily: "Lato",
      fontWeight: 700,
    },
    body2: {
      fontSize: "0.875rem",
      fontFamily: "Lato",
      fontWeight: 400,
    },
    body3: {
      fontSize: "0.75rem",
      fontFamily: "Lato",
      fontWeight: 400,
    },
    h6: {
      fontSize: "1rem",
      fontFamily: "Lato",
      fontWeight: 700,
    },
    h5: {
      fontSize: "1.5rem",
      fontFamily: "Lato",
      fontWeight: 400,
      textTransform: "uppercase",
      letterSpacing: "2px",
    },
  },
  overrides: {
    MuiTabs: {
      indicator: {
        // color: "#5198E0",
        border: "1px solid #5198E0",
        // backgroundColor: "#32A05F",
      },
    },
    // MuiGrid:{
    //   root:{
    //     backgroundColor:"red"
    //   }
    // }
  },
});

// export const darkTheme = createTheme({
//   palette: {
//     type: "dark",
//     primary: {
//       main: "#FCFCFC", // text/primary
//       light: "#2E3942", // paletone/blue 2  border-primary
//       dark: "#FCFCFC", // text/primary-active
//       contrastText: "#EAEEF5",
//       green: "#2E3942",
//       grey: "#ffffffb3",
//     },
//     secondary: {
//       main: "#3E8DDD", // surface/surface-brand
//       dark: "#294E95", // deeptone/deepBlue  primary button
//     },
//     grey: {
//       100: "#1C2328",
//       300: "#DDDDDD", // surface/secondary-default
//     },
//     action: {
//       hover: "#2E3942", // card/primary-hover
//     },
//     background: {
//       paper: "#151A1E",
//       contentBG: "#242D33",
//       // default:"red" // surafce/primary
//     },

//     // primary:red
//   },
//   shape: {
//     borderRadius: "8px",
//   },
//   typography: {
//     fontFamily: ["poppins", "sans-serif", "Lato"].join(","),
//     body1: {
//       fontSize: "0.875rem",
//       fontFamily: "Lato",
//       fontWeight: 700,
//     },
//     body2: {
//       fontSize: "0.875rem",
//       fontFamily: "Lato",
//       fontWeight: 400,
//     },
//     body3: {
//       fontSize: "0.75rem",
//       fontFamily: "Lato",
//       fontWeight: 400,
//     },
//     h6: {
//       fontSize: "1rem",
//       fontFamily: "Lato",
//       fontWeight: 700,
//     },
//     h5: {
//       fontSize: "1.5rem",
//       fontFamily: "Lato",
//       fontWeight: 400,
//       textTransform: "uppercase",
//       letterSpacing: "2px",
//     },
//   },
//   overrides: {
//     MuiTabs: {
//       indicator: {
//         // color: "#5198E0",
//         border: "1px solid #5198E0",
//         // backgroundColor: "#32A05F",
//       },
//     },
//     // MuiGrid:{
//     //   root:{
//     //     backgroundColor:"red"
//     //   }
//     // }
//   },
// });

export const glassTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#E6E2E4", // text/primary
      light: "rgba(224, 224, 224, 0.40)", // paletone/blue 2  border-primary
      dark: "#E6E2E4", // text/primary-active
      contrastText: "#EAEEF5",
      contrastColor: "#EAEEF5",
      green: "#2E3942",
      grey: "#ffffffb3",
    },
    secondary: {
      main: "#3E8DDD", // surface/surface-brand
      dark: "#294E95", // deeptone/deepBlue  primary button
    },
    grey: {
      100: "rgba(224, 224, 224, 0.20)",
      300: "rgba(224, 224, 224, 0.10)",
      400: "rgba(224, 224, 224, 0.40)", // surface/secondary-default
    },
    action: {
      hover: "rgba(224, 224, 224, 0.20)", // card/primary-hover
    },
    background: {
      paper: "rgba(255, 255, 255, 0)",
      contentBG: "#294E95",
      default: "rgba(224, 224, 224, 0.10)", // surafce/primary
    },

    // primary:red
  },
  shape: {
    borderRadius: "8px",
  },
  typography: {
    fontFamily: ['Lato'].join(","),
    body1: {
      fontSize: "0.875rem",
      fontFamily: Lato_Bold,
      fontWeight: 700,
    },
    body2: {
      fontSize: "0.875rem",
      fontFamily: Lato_regular,
      fontWeight: 400,
    },
    body3: {
      fontSize: "0.75rem",
      fontFamily: Lato_regular,
      fontWeight: 400,
    },
    h6: {
      fontSize: "1rem",
      fontFamily: Lato_Bold,
      fontWeight: 700,
    },
    h5: {
      fontSize: "1.5rem",
      fontFamily: Lato_regular,
      fontWeight: 400,
      textTransform: "uppercase",
      letterSpacing: "2px",
    },
  },
  overrides: {
    MuiCssBaseline:{
      "@global":{
    "@font-face": [Lato_regular, Lato_Bold]
      }
    },
    MuiTabs: {
      indicator: {
        // color: "#5198E0",
        border: "1px solid #5198E0",
        // backgroundColor: "#32A05F",
      },
    },
    // MuiPaper: {
    //   root: {
    //     background: `url(${gradient})`,
    //   },
    // },
    MuiContainer: {
      root: {
        backgroundImage: `url(${gradient})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-rpeat",
        backgroundSize: "cover",
      },
    },
    // MuiGrid:{
    //   root:{
    //     backgroundColor:"red"
    //   }
    // }
  },
});
