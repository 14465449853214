import React from "react";

import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

import star from "../../../../images/star.svg";
import archive from "../../../../images/archive.svg";
import deleteIcon from "../../../../images/deleteButtonSvg.svg";
import timeFast from "../../../../images/time-fast.svg";

import EsgGptCompanyList from "../EsgGptCompanyList";
import { IconButton, useTheme } from "@material-ui/core";
import useHoverEffect from "../../../../customHooks/getHoveringEffects";

const Accordion = withStyles({
  root: {
    // border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export function ModifiedList() {
  const theme = useTheme();
  const { selectedItem, handleMouseEnter, handleMouseLeave } = useHoverEffect();
  return (
    <List style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}>
      <ListItem
        style={{
          borderRadius: "8px",
          backgroundColor: theme.palette.action.hover,
          width: "100%",
        }}
      >
        <ListItemText
          primary="New Company"
          style={{ cursor: "pointer" }}
          // secondary={secondary ? "Secondary text" : null}
        />
        <ListItemSecondaryAction
          style={{
            width: "25%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            variant="contained"
            style={{
              color: "#3374B9",
              marginLeft: "auto",
              // backgroundColor: "#FBFBFB",
              borderRadius: "10px",
              height: "35px",
              // boxShadow:
              //   selectedItem === 1
              //     ? "1px 1px 4px 1px  #B1D4E0"
              //     : "1px 1px 4px 1px  #EDF2F3",
              // marginRight: "5px",
              padding: "5px",
            }}
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={() => handleMouseLeave(1)}
            aria-label="add to shopping cart"
          >
            <EditOutlinedIcon
              style={{
                color: "#3374B9",
                cursor: "pointer",
                fontSize: "20px",
              }}
            />
          </IconButton>
          <IconButton
            style={{
              color: "#3374B9",
              marginLeft: "auto",
              // backgroundColor: "#FBFBFB",
              // boxShadow:
              //   selectedItem === 2
              //     ? "1px 1px 4px 1px  #B1D4E0"
              //     : "1px 1px 4px 1px  #EDF2F3",
              borderRadius: "10px",
              height: "35px",
              // marginRight: "15px",
              padding: "5px",
            }}
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={() => handleMouseLeave(2)}
            aria-label="add to shopping cart"
          >
            <DeleteOutlineOutlinedIcon
              style={{
                color: "#3374B9",
                cursor: "pointer",
                fontSize: "20px",
              }}
            />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}

export default function EsgGptUpdatedList() {
  const theme = useTheme();

  const { selectedItem, handleMouseEnter, handleMouseLeave } = useHoverEffect();

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        // style={{ border: "1px solid white" }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon style={{ color: theme.palette.secondary.main }} />
          }
          aria-controls="panel1d-content"
          id="panel1d-header"
          style={{ backgroundColor: theme.palette.grey[100] }}
        >
          <img src={star} style={{ marginRight: "7px" }} alt="pdf icon" />

          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              color: theme.palette.primary.main,
            }}
          >
            My Favorites{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "5px" }}>
          <List style={{ width: "100%" }}>
            <ListItem
              style={{
                borderRadius: "8px",
                backgroundColor: theme.palette.action.hover,
                width: "100%",
              }}
            >
              <ListItemText
                primary="New Company"
                style={{ cursor: "pointer" }}
                // secondary={secondary ? "Secondary text" : null}
              />
              <ListItemSecondaryAction
                style={{
                  width: "25%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <IconButton
                  style={{
                    color: "#3374B9",
                    marginLeft: "auto",
                    // border: `1px solid ${theme.palette.secondary.main}`,
                    // backgroundColor: theme.palette.background.paper,
                    borderRadius: "10px",
                    height: "35px",
                    // marginRight: "15px",
                    padding: "5px",
                  }}
                  onMouseEnter={() => handleMouseEnter(2)}
                  onMouseLeave={() => handleMouseLeave(2)}
                  aria-label="add to shopping cart"
                >
                  <img
                    src={timeFast}
                    style={{ cursor: "pointer" }}
                    alt="pdf icon"
                  />
                </IconButton>

                <IconButton
                  style={{
                    color: "#3374B9",
                    marginLeft: "auto",
                    // border: `1px solid ${theme.palette.secondary.main}`,
                    // backgroundColor: theme.palette.background.paper,
                    borderRadius: "10px",
                    height: "35px",
                    // marginRight: "15px",
                    padding: "5px",
                  }}
                  onMouseEnter={() => handleMouseEnter(2)}
                  onMouseLeave={() => handleMouseLeave(2)}
                  aria-label="add to shopping cart"
                >
                  <DeleteOutlineOutlinedIcon
                    style={{
                      color: "#3374B9",
                      cursor: "pointer",
                      fontSize: "20px",
                    }}
                  />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        elevation={0}
        // style={{ border: "1px solid white" }}
      >
        <AccordionSummary
          aria-controls="panel2d-content"
          id="panel2d-header"
          expandIcon={
            <ExpandMoreIcon style={{ color: theme.palette.secondary.main }} />
          }
          style={{ backgroundColor: theme.palette.grey[100] }}
        >
          <img src={archive} style={{ marginRight: "7px" }} alt="pdf icon" />

          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              color: theme.palette.primary.main,
            }}
          >
            Repository
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "0px" }}>
          <EsgGptCompanyList />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
