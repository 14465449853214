import React from "react";

import { useHistory } from "react-router-dom";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import {
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import analytics from "../../../images/roboAi.svg";
import promptIcon from "../../../images/promptIcon.png";
import useHoverEffect from "../../../customHooks/getHoveringEffects";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // border: "1px solid #EAEEF5",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.primary.light,

    borderRadius: "8px",
    // backgroundColor: isHovering ? "#EBF1F8" : "#FFFFFF",
    padding: "13px",
    justifyContent: "flex-start",
    width: "49%",
    // marginBottom: "10px",
    cursor: "pointer",
    alignItems: "center",

    backgroundColor: theme.palette.grey[100],

    "&:hover": {
      backgroundColor: "#294E95",
    },

    [theme.breakpoints.down("md")]: {
      width: "49%",
      padding: "10px",
      alignItems: "center",

      "@media (orientation: portrait)": {
        width: "80%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "49%",
      padding: "10px",
      alignItems: "center",

      "@media (orientation: portrait)": {
        width: "90%",
      },
    },
    // [theme.breakpoints.up("xs")]: {
    //   width: "49%",
    //   alignItems: "center",
    // },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      alignItems: "center",
    },
  },
  mainGpt: {
    height: "80vh",
    // flexWrap: "wrap",
    display: "flex",

    // flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // border: "solid",
    [theme.breakpoints.down("md")]: {
      height: "70vh",
      "@media (orientation: portrait)": {
        flexDirection: "column",
      },
    },
    [theme.breakpoints.down("sm")]: {
      height: "65vh",
      flexDirection: "column",
      // padding: "0px 20px",
      
    },
    // [theme.breakpoints.down("sm")]: {
    [theme.breakpoints.down("xs")]: {
      height: "98%",

      flexDirection: "column",
      padding: "0px 20px",
      // width:'100%'
    },
    // [theme.breakpoints.down("sm")]: {
    //   height: "65vh",
    //   flexDirection:'column'
    // },
  },
  typography: {
    ...theme.typography.body1,
    color: theme.palette.primary.main,
    fontSize: "13px",
  },
  typography2: {
    ...theme.typography.body2,
    color: theme.palette.primary.main,
    padding: "10px 0px",
  },
  typograph3: {
    ...theme.typography.body1,
    fontSize: "20px",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      paddingTop: "10px",
    },
  },
  iconButton: {
    marginLeft: "auto",
    borderRadius: "200px",
    borderWidth: "1px",
    borderStyle: "solid",
    padding: "5px",
    width: "30px",
    borderColor: theme.palette.secondary.main,
  },
  icon: {
    color: theme.palette.secondary.main,
    fontSize: "18px",
    fontWeight: 400,
  },
  roboAi: {
    border: `1px solid ${theme.palette.grey[500]}`,
    marginBottom: "30px",
    height: "100%",
    alignItems: "center",
    borderRadius: "675px",
    [theme.breakpoints.down("md")]: {
      "@media (orientation: portrait)": {
        height: "80%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      height: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      height: "60%",
    },
  },
  imgContainer: {
    height: "50%",
    marginTop: "5px",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      "@media (orientation: portrait)": {
        height: "45%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      height: "30%",
    },
  },
}));

function EsgGptMainTitle({ title, newPath }) {
  const classes = useStyles();
  const theme = useTheme();

  const history = useHistory();
  const { isHovering, handleMouseEnter, handleMouseLeave } = useHoverEffect();
  const handleChangeRoute = () => {
    let path = newPath;
    history.push(path);
  };

  return (
    <Grid
      item
      className={classes.root}
      onClick={handleChangeRoute}
      onMouseEnter={() => handleMouseEnter(true)}
      onMouseLeave={() => handleMouseLeave(true)}
    >
      <img
        style={{ width: "24px", height: "24px", paddingRight: 8 }}
        src={promptIcon}
        alt=""
      />
      <Typography className={classes.typography}>{title}</Typography>
      {/* <IconButton
        variant="contained"
        className={classes.iconButton}
        aria-label="add to shopping cart"
      >
        <ArrowForwardIosIcon
          className={classes.icon}
          style={{
            color: theme.palette.primary.contrastColor,
          }}
          // style={{  fontSize: "20px" }}
          // fontSize="medium"
        />
      </IconButton> */}
    </Grid>
  );
}

function EsgGptMainRight() {
  const classes = useStyles();
  return (
    <Grid
      container
      // component={Paper}
      // elevation={0}
      // justifyContent="center"
      // direction="column"
      // alignItems="center"
      md={12}
      className={classes.mainGpt}
    >
      <Grid item md={4} sm={3} className={classes.imgContainer}>
        <img src={analytics} alt="analytics" className={classes.roboAi} />
      </Grid>
      <Grid
        item
        md={7}
        sm={8}
        style={{
          // height: "50%",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "flex-start",
          // width: "50%",
          alignItems: "center",
          // border: "solid",
          // flexWrap: "wrap",
        }}
      >
        <div style={{ width: "100%" }}>
          <Typography
            className={classes.typograph3}
            // style={{
            //   fontSize: "14px",
            //   fontWeight: 600,
            //   letterSpacing: "0.014px",
            //   textTransform: "capitalize",
            //   //   cursor:"pointer"
            // }}
          >
          Lenovo Intelligent Sustainability Solutions Advisor (L.I.S.S.A) 
           {/* Lenovo's Intelligent Sustainability Solutions Designer */}
          </Typography>
          <Typography
            className={classes.typography2}
            // style={{
            //   fontSize: "14px",
            //   fontWeight: 600,
            //   letterSpacing: "0.014px",
            //   textTransform: "capitalize",
            //   //   cursor:"pointer"
            // }}
          >
            Click the prompts below or type in your question to get started.
          </Typography>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            gap: 10,
            flexWrap: "wrap",
          }}
        >
          <EsgGptMainTitle
            title={"View my customer’s ESG profile"}
            newPath={"/esg_gpt/company_profiles"}
          />
          <EsgGptMainTitle
            title={"Lenovo Sustainability Solutions for Circularity"}
            newPath={"/esg_gpt/company_profiles"}
          />
          <EsgGptMainTitle
            title={"Recommended solutions for Energy Efficiency"}
            newPath={"/esg_gpt/company_profiles"}
          />
          <EsgGptMainTitle
            title={"Suggested IT solutions for Climate Action"}
            newPath={"/esg_gpt/company_profiles"}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default EsgGptMainRight;
