import blueIcon from "../../images/IconBlue.svg";
import greenIcon from "../../images/IconGreen.svg";
import purpuleIcon from "../../images/IconPurpule.svg";
import yellowIcon from "../../images/IconYellow.png";
import orangeIcon from "../../images/IconOrange.png";

export const getColoredLogo = (topic) => {
  if (topic === "Environment") return greenIcon;
  if (topic === "Social Topics") return purpuleIcon;
  if (topic === "Governance") return blueIcon;
  if (topic === "Business") return orangeIcon;
  if (topic === "Human Capital") return yellowIcon;
  else return null
};

export const riskArea = [
  {
    topic: "Human Capital",
    heading: "Labor Standards and Human Rights",
    description:
      "Poor labor standards and human rights violations can result in reputational damage, legal liabilities, and loss of investor confidence.",
  },
  {
    topic: "Governance",
    heading: "Risk Management",
    description:
      "Potential for business disruptions and financial losses, suggestions for enterprise risk management frameworks",
  },
  {
    topic: "Environment",
    heading: "Regulatory Compliance",
    description:
      "Poor compliance with environmental regulations may result in fines, legal penalties, and reputational damage",
  },
  {
    topic: "Environment",
    heading: "Greenhouse Gas Emissions",
    description:
      "High emissions contribute to climate change, which poses a significant threat to the environment and human well-being",
  },
  {
    topic: "Human Capital",
    heading: "Community Relations",
    description:
      "Best practices in employee education and career development, potential for enhanced employee retention and productivity",
  },
 

];

export const strongAreas = [
  {
    topic: "Human Capital",
    heading: "Board Composition and Diversity",
    description:
      "A diverse and effective board can provide oversight and strategic guidance, improving corporate performance and reducing risk.",
  },
  {
    topic: "Governance",
    heading: "Corporate Governance Policies and Procedures",
    description:
      "Implementing strong corporate governance policies and procedures can reduce risk, increase transparency, and enhance investor confidence.",
  },
  {
    topic: "Environment",
    heading: "Ethical Business Practices:",
    description:
      "Maintaining ethical business practices can improve reputation, reduce risk, and enhance long-term value creation",
  },
  {
    topic: "Environment",
    heading: "Transparency and Disclosure",
    description:
      "Transparency and disclosure can enhance investor confidence, improve reputation, and promote accountability.",
  },
];

export const improvementAreas = [
  {
    topic: "Governance",
    heading: "Transparency and Disclosure",
    description:
      "Transparency and disclosure can enhance investor confidence, improve reputation, and promote accountability.",
  },
  {
    topic: "Environment",
    heading: "Waste Reduction and Recycling",
    description:
      "Implementing waste reduction and recycling programs can reduce waste generation, conserve resources, and reduce costs",
  },
  {
    topic: "Human Capital",
    heading: "Employee Training and Development",
    description:
      "Providing employee training and education programs can help improve workplace safety and reduce the risk of accidents and injuries",
  },
];

export const financialInsights = [
  {
    heading: "For the financial year",
    description:
      "Report shows strong financial health, with a high current ratio indicating a good ability to pay off short-term liabilities. However, the company's debt-to-equity ratio is relatively high, indicating a potential risk of overleveraging. The company has a good history of meeting its debt obligations, with timely payments and no defaults. Overall, the report suggests that ABC Company has a solid financial footing and is a low-risk investment or lending opportunity.",
  },
];