import {
  SET_ACKNOWLEDGED,
  SET_ORGANIZATION,
  SET_NEW_ORGANIZATION,
  SET_NEW_ORGANIZATION_LOGO,
  SET_ASK_MESSAGE,
  SET_CHANGE_THEME
} from "../../../constants/esgGptConstant";

const initial_state = {
  acknowledged: false,
  organizations: [],
  newOrganization: {},
  newLogo: "",
  askMessage: "",
  hasDefaultTheme:true
};

export default function esgGptReducer(state = initial_state, action) {
  switch (action.type) {
    case SET_ACKNOWLEDGED:
      return (state = { ...state, acknowledged: action.payload });
    case SET_ORGANIZATION:
      return (state = { ...state, organizations: action.payload });
    case SET_NEW_ORGANIZATION:
      return (state = { ...state, newOrganization: action.payload });
    case SET_NEW_ORGANIZATION_LOGO:
      return (state = { ...state, newLogo: action.payload });
    case SET_ASK_MESSAGE:
      return (state = { ...state, askMessage: action.payload });
      case SET_CHANGE_THEME:
      return (state = { ...state, hasDefaultTheme: action.payload });
    default:
      return state;
  }
}
