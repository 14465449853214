import {
  SET_LOGIN,
  SET_LOGOUT,
  SET_TOKEN,
  SET_USER_PROFILE,
} from "./loginActionType";
import { set_loader, unset_loader } from "../loader/loader_action";
import encrypt from "../../actions/shared/sharedActions";

import UNIVERSAL from "../../../config/config";
import { set_snack_bar } from "../snackbar/snackbar_action";
import { setChangeTheme } from "../gpt/esgGptAction";

export function setLogin(payload) {
  return {
    type: SET_LOGIN,
    payload: payload,
  };
}

export function setToken(payload) {
  return {
    type: SET_TOKEN,
    payload: payload,
  };
}

export function setLogOut() {
  return {
    type: SET_LOGOUT,
  };
}

export function setUserProfile(payload) {
  return {
    type: SET_USER_PROFILE,
    payload: payload,
  };
}

export function login_with_email(email, password) {
  const data = encrypt({
    email,
    password,
  });

  const responseJson = {
    status: true,
    message: "Login successful",
    result: {
      _id: "63e5f4b4a419ced88b27ba61",
      name: "thikshna rm",
      email: "thikshna.sg@gmail.com",
      bank_id: "63da5f8a6369492c38bedef1",
      user_type: "gpt",
      organization_id: "63e0b943264f518a40a25d58",
      user_token:
        "eyJhbGciOiJIUzI1NiJ9.dGhpa3NobmEuc2dAZ21haWwuY29t.-KFN-ZQNAHJsZK_ejqx69X0bb5MoS9R4Vfsgr2rnqZI",
      organization_data: {
        _id: "63da5f8a6369492c38bedef1",
        name: "Baibhav's Bank",
      },
    },
  };

  // if (password !== "Thikshna@1") {
  //   responseJson.status = false;
  // }
  return (dispatch) => {
    dispatch(set_loader());

    if (responseJson.status) {
      localStorage.setItem("user_token", responseJson.result?.user_token);
      localStorage.setItem("bank_id", responseJson.result?.bank_id);
      localStorage.setItem("user_type", responseJson.result?.user_type);
      localStorage.setItem("userName", responseJson.result?.name);
      responseJson.result?.profile_img &&
        localStorage.setItem(
          "userProfile",
          JSON.stringify(responseJson.result?.profile_img)
        );
      localStorage.setItem(
        "organization_data",
        JSON.stringify(responseJson.result?.organization_data)
      );
      localStorage.setItem("user_id", responseJson.result?.user_id);

      localStorage.setItem("userEmail", email);
      localStorage.setItem(
        "signup_process_no",
        responseJson.result.signup_process
      );

      dispatch(setLogin(responseJson.result));
      dispatch(set_snack_bar(responseJson.status, responseJson.message));
    } else {
      dispatch(set_snack_bar(responseJson.status, responseJson.message));
    }
    if (responseJson.result) {
    }
    dispatch(unset_loader());

    // return fetch(UNIVERSAL.BASEURL + "/users/login", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "content-type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     data: data,
    //   }),
    // })
    //   .then((response) => response.json())
    //   .then((responseJson) => {
    //     if (responseJson.status) {
    //       localStorage.setItem("user_token", responseJson.result?.user_token);
    //       localStorage.setItem("bank_id", responseJson.result?.bank_id);
    //       localStorage.setItem("user_type", responseJson.result?.user_type);
    //       localStorage.setItem("userName", responseJson.result?.name);
    //       responseJson.result?.profile_img &&
    //         localStorage.setItem(
    //           "userProfile",
    //           JSON.stringify(responseJson.result?.profile_img)
    //         );
    //       localStorage.setItem(
    //         "organization_data",
    //         JSON.stringify(responseJson.result?.organization_data)
    //       );
    //       localStorage.setItem("user_id", responseJson.result?.user_id);

    //       localStorage.setItem("userEmail", email);
    //       localStorage.setItem(
    //         "signup_process_no",
    //         responseJson.result.signup_process
    //       );

    //       dispatch(setLogin(responseJson.result));
    //     } else {
    //       dispatch(set_snack_bar(responseJson.status, responseJson.message));
    //     }
    //     if (responseJson.result) {
    //     }
    //     dispatch(unset_loader());
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };
}

export function logOut(token) {
  const data = encrypt({
    "user-token": token,
  });
  const responseJson = {
    status: true,
    message: "Logged Out successfully.",
  };
  return (dispatch) => {
    dispatch(set_loader());
    localStorage.clear();
    if (responseJson.status) {
      localStorage.clear();
      dispatch(setChangeTheme(true));
      dispatch(setLogOut());
    }
    dispatch(unset_loader());
    dispatch(set_snack_bar(responseJson.status, responseJson.message));
    // return fetch(UNIVERSAL.BASEURL + "/users/logout", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "content-type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     data: data,
    //   }),
    // })
    //   .then((response) => response.json())
    //   .then((responseJson) => {
    //     if (responseJson.status) {
    //       localStorage.clear();
    //       dispatch(setChangeTheme(true));

    //       dispatch(setLogOut());
    //     }
    //     dispatch(unset_loader());
    //     dispatch(set_snack_bar(responseJson.status, responseJson.message));
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };
}

export const forgetPassword = (email, domain_name) => {
  return (dispatch) => {
    dispatch(set_loader());

    let url = window.origin;
    let domain_name = window.location.hostname;
    const data = encrypt({
      email: email,
      domain_name,
      url,
    });
    return fetch(UNIVERSAL.BASEURL + "/users/forgot_password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          //
        }
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const resetForgetPassword = (password, reset_code) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      password,
      reset_code,
    });

    return fetch(UNIVERSAL.BASEURL + "/users/reset_forgot_password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          //
        }
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};
