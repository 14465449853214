const sectorIndustriesData = [
  {
    _id: "6197687b956d95419441252a",
    Sector: "Transportation",
    industry_data: [
      {
        _id: "6197687b956d954194412577",
        Industry: "Road Transportation",
        industry_code: "TR-RO",
      },
      {
        _id: "6197687b956d954194412575",
        Industry: "Marine Transportation",
        industry_code: "TR-MT",
      },
      {
        _id: "6197687b956d954194412576",
        Industry: "Rail Transportation",
        industry_code: "TR-RA",
      },
      {
        _id: "6197687b956d954194412574",
        Industry: "Car Rental & Leasing",
        industry_code: "TR-CR",
      },
      {
        _id: "6197687b956d954194412570",
        Industry: "Airlines",
        industry_code: "TR-AL",
      },
      {
        _id: "6197687b956d954194412573",
        Industry: "Cruise Lines",
        industry_code: "TR-CL",
      },
      {
        _id: "6197687b956d954194412571",
        Industry: "Auto Parts",
        industry_code: "TR-AP",
      },
      {
        _id: "6197687b956d95419441256f",
        Industry: "Air Freight & Logistics",
        industry_code: "TR-AF",
      },
      {
        _id: "6197687b956d954194412572",
        Industry: "Automobiles",
        industry_code: "TR-AU",
      },
    ],
  },
  {
    _id: "6197687b956d954194412523",
    Sector: "Financials",
    industry_data: [
      {
        _id: "6197687b956d954194412544",
        Industry: "Consumer Finance",
        industry_code: "FN-CF",
      },
      {
        _id: "6197687b956d954194412545",
        Industry: "Security & Commodity Exchanges",
        industry_code: "FN-EX",
      },
      {
        _id: "6197687b956d954194412548",
        Industry: "Mortgage Finance",
        industry_code: "FN-MF",
      },
      {
        _id: "6197687b956d954194412546",
        Industry: "Investment Banking & Brokerage",
        industry_code: "FN-IB",
      },
      {
        _id: "6197687b956d954194412542",
        Industry: "Asset Management & Custody Activities",
        industry_code: "FN-AC",
      },
      {
        _id: "6197687b956d954194412543",
        Industry: "Commercial Banks",
        industry_code: "FN-CB",
      },
      {
        _id: "6197687b956d954194412547",
        Industry: "Insurance",
        industry_code: "FN-IN",
      },
    ],
  },
  {
    _id: "6197687b956d954194412524",
    Sector: "Health Care",
    industry_data: [
      {
        _id: "6197687b956d95419441254e",
        Industry: "Medical Equipment & Supplies",
        industry_code: "HC-MS",
      },
      {
        _id: "6197687b956d954194412549",
        Industry: "Biotechnology & Pharmaceuticals",
        industry_code: "HC-BP",
      },
      {
        _id: "6197687b956d95419441254a",
        Industry: "Health Care Distributors",
        industry_code: "HC-DI",
      },
      {
        _id: "6197687b956d95419441254d",
        Industry: "Managed Care",
        industry_code: "HC-MC",
      },
      {
        _id: "6197687b956d95419441254b",
        Industry: "Drug Retailers",
        industry_code: "HC-DR",
      },
      {
        _id: "6197687b956d95419441254c",
        Industry: "Health Care Delivery",
        industry_code: "HC-DY",
      },
    ],
  },
  {
    _id: "6197687b956d954194412521",
    Sector: "Extractives & Minerals Processing",
    industry_data: [
      {
        _id: "6197687b956d954194412538",
        Industry: "Oil & Gas – Refining & Marketing",
        industry_code: "EM-RM",
      },
      {
        _id: "6197687b956d954194412533",
        Industry: "Coal Operations",
        industry_code: "EM-CO",
      },
      {
        _id: "6197687b956d954194412534",
        Industry: "Oil & Gas – Exploration & Production",
        industry_code: "EM-EP",
      },
      {
        _id: "6197687b956d954194412535",
        Industry: "Iron & Steel Producers",
        industry_code: "EM-IS",
      },
      {
        _id: "6197687b956d954194412537",
        Industry: "Metals & Mining",
        industry_code: "EM-MM",
      },
      {
        _id: "6197687b956d954194412532",
        Industry: "Construction Materials",
        industry_code: "EM-CM",
      },
      {
        _id: "6197687b956d954194412536",
        Industry: "Oil & Gas – Midstream",
        industry_code: "EM-MD",
      },
      {
        _id: "6197687b956d954194412539",
        Industry: "Oil & Gas – Services",
        industry_code: "EM-SV",
      },
    ],
  },
  {
    _id: "6197687b956d954194412522",
    Sector: "Food & Beverage",
    industry_data: [
      {
        _id: "6197687b956d954194412541",
        Industry: "Tobacco",
        industry_code: "FB-TB",
      },
      {
        _id: "6197687b956d954194412540",
        Industry: "Restaurants",
        industry_code: "FB-RN",
      },
      {
        _id: "6197687b956d95419441253f",
        Industry: "Processed Foods",
        industry_code: "FB-PF",
      },
      {
        _id: "6197687b956d95419441253c",
        Industry: "Food Retailers & Distributors",
        industry_code: "FB-FR",
      },
      {
        _id: "6197687b956d95419441253b",
        Industry: "Agricultural Products",
        industry_code: "FB-AG",
      },
      {
        _id: "6197687b956d95419441253e",
        Industry: "Non-Alcoholic Beverages",
        industry_code: "FB-NB",
      },
      {
        _id: "6197687b956d95419441253a",
        Industry: "Alcoholic Beverages",
        industry_code: "FB-AB",
      },
      {
        _id: "6197687b956d95419441253d",
        Industry: "Meat, Poultry & Dairy",
        industry_code: "FB-MP",
      },
    ],
  },
  {
    _id: "6197687b956d954194412527",
    Sector: "Resource Transformation",
    industry_data: [
      {
        _id: "6197687b956d954194412561",
        Industry: "Industrial Machinery & Goods",
        industry_code: "RT-IG",
      },
      {
        _id: "6197687b956d95419441255d",
        Industry: "Aerospace & Defense",
        industry_code: "RT-AE",
      },
      {
        _id: "6197687b956d954194412560",
        Industry: "Electrical & Electronic Equipment",
        industry_code: "RT-EE",
      },
      {
        _id: "6197687b956d95419441255f",
        Industry: "Containers & Packaging",
        industry_code: "RT-CP",
      },
      {
        _id: "6197687b956d95419441255e",
        Industry: "Chemicals",
        industry_code: "RT-CH",
      },
    ],
  },
  {
    _id: "6197687b956d954194412520",
    Sector: "Consumer Goods",
    industry_data: [
      {
        _id: "6197687b956d95419441252f",
        Industry: "Household & Personal Products",
        industry_code: "CG-HP",
      },
      {
        _id: "6197687b956d954194412531",
        Industry: "Toys & Sporting Goods",
        industry_code: "CG-TS",
      },
      {
        _id: "6197687b956d95419441252d",
        Industry: "Building Products & Furnishings",
        industry_code: "CG-BF",
      },
      {
        _id: "6197687b956d95419441252c",
        Industry: "Appliance Manufacturing",
        industry_code: "CG-AM",
      },
      {
        _id: "6197687b956d95419441252e",
        Industry: "E-Commerce",
        industry_code: "CG-EC",
      },
      {
        _id: "6197687b956d95419441252b",
        Industry: "Apparel, Accessories & Footwear",
        industry_code: "CG-AA",
      },
      {
        _id: "6197687b956d954194412530",
        Industry: "Multiline and Specialty Retailers & Distributors",
        industry_code: "CG-MR",
      },
    ],
  },
  {
    _id: "6197687b956d954194412529",
    Sector: "Technology & Communications",
    industry_data: [
      {
        _id: "6197687b956d95419441256c",
        Industry: "Semiconductors",
        industry_code: "TC-SC",
      },
      {
        _id: "6197687b956d95419441256b",
        Industry: "Internet Media & Services",
        industry_code: "TC-IM",
      },
      {
        _id: "6197687b956d95419441256a",
        Industry: "Hardware",
        industry_code: "TC-HW",
      },
      {
        _id: "6197687b956d95419441256d",
        Industry: "Software & IT Services",
        industry_code: "TC-SI",
      },
      {
        _id: "6197687b956d95419441256e",
        Industry: "Telecommunication Services",
        industry_code: "TC-TL",
      },
      {
        _id: "6197687b956d954194412569",
        Industry:
          "Electronic Manufacturing Services & Original Design Manufacturing",
        industry_code: "TC-ES",
      },
    ],
  },
  {
    _id: "6197687b956d954194412528",
    Sector: "Services",
    industry_data: [
      {
        _id: "6197687b956d954194412568",
        Industry: "Professional & Commercial Services",
        industry_code: "SV-PS",
      },
      {
        _id: "6197687b956d954194412564",
        Industry: "Education",
        industry_code: "SV-ED",
      },
      {
        _id: "6197687b956d954194412563",
        Industry: "Casinos & Gaming",
        industry_code: "SV-CA",
      },
      {
        _id: "6197687b956d954194412567",
        Industry: "Media & Entertainment",
        industry_code: "SV-ME",
      },
      {
        _id: "6197687b956d954194412562",
        Industry: "Advertising & Marketing",
        industry_code: "SV-AD",
      },
      {
        _id: "6197687b956d954194412565",
        Industry: "Hotels & Lodging",
        industry_code: "SV-HL",
      },
      {
        _id: "6197687b956d954194412566",
        Industry: "Leisure Facilities",
        industry_code: "SV-LF",
      },
    ],
  },
  {
    _id: "6197687b956d954194412525",
    Sector: "Infrastructure",
    industry_data: [
      {
        _id: "6197687b956d954194412552",
        Industry: "Home Builders",
        industry_code: "IF-HB",
      },
      {
        _id: "6197687b956d954194412550",
        Industry: "Electric Utilities & Power Generators",
        industry_code: "IF-EU",
      },
      {
        _id: "6197687b956d954194412553",
        Industry: "Real Estate",
        industry_code: "IF-RE",
      },
      {
        _id: "6197687b956d95419441254f",
        Industry: "Engineering & Construction Services",
        industry_code: "IF-EN",
      },
      {
        _id: "6197687b956d954194412556",
        Industry: "Water Utilities & Services",
        industry_code: "IF-WU",
      },
      {
        _id: "6197687b956d954194412554",
        Industry: "Real Estate Services",
        industry_code: "IF-RS",
      },
      {
        _id: "6197687b956d954194412555",
        Industry: "Waste Management",
        industry_code: "IF-WM",
      },
      {
        _id: "6197687b956d954194412551",
        Industry: "Gas Utilities & Distributors",
        industry_code: "IF-GU",
      },
    ],
  },
  {
    _id: "6197687b956d954194412526",
    Sector: "Renewable Resources & Alternative Energy",
    industry_data: [
      {
        _id: "6197687b956d954194412557",
        Industry: "Biofuels",
        industry_code: "RR-BI",
      },
      {
        _id: "6197687b956d95419441255c",
        Industry: "Wind Technology & Project Developers",
        industry_code: "RR-WT",
      },
      {
        _id: "6197687b956d95419441255a",
        Industry: "Pulp & Paper Products",
        industry_code: "RR-PP",
      },
      {
        _id: "6197687b956d954194412558",
        Industry: "Fuel Cells & Industrial Batteries",
        industry_code: "RR-FC",
      },
      {
        _id: "6197687b956d954194412559",
        Industry: "Forestry Management",
        industry_code: "RR-FM",
      },
      {
        _id: "6197687b956d95419441255b",
        Industry: "Solar Technology & Project Developers",
        industry_code: "RR-ST",
      },
    ],
  },
];


function useSector() {
  return {sectorIndustries: sectorIndustriesData}
}

export default useSector
