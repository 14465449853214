import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core";

import aerofusion from "../../../../../images/CompanyA.png";
import EsgGptNewCompanyDetails from "../../esgGptAddNewCompany/EsgGptNewCompanyDetails";
import { Typography, Paper } from "@material-ui/core";
import useHoverEffect from "../../../../../customHooks/getHoveringEffects";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    marginTop: "10px",
  },
  table: {
    minWidth: 700,
    // marginTop: "10px",
    width: "100%",
    // border:"1px solid"
  },
  tableCell: {
    padding: "5px",
  },
  titleRow: {
    // backgroundColor: "#F6F6F6",
  },
  backgroundColorPrimary: {
    backgroundColor:
      theme?.palette?.type === "dark"
        ? theme.palette.background.default
        : theme.palette.background.paper,
    borderBottom:
      theme?.palette?.type !== "dark" &&
      `1px solid ${theme.palette.primary.light}`,
    borderRadius: "8px 8px 0px 0px",
  },
  typoColorSecondary: {
    color: theme.palette.primary.dark,
  },
}));

export function HeadingCard({
  primaryHead,
  secondaryHead,
  justifyCenter = false,
  isInsight = true,
  hideBorder = false,
  backgroundColor = "#FEFEFE",
  borderRadius = "0px",
  type,
  children,
}) {
  const classes = useStyles();
  const { isHovering, handleMouseEnter, handleMouseLeave } = useHoverEffect();

  return (
    <Paper
      className={classes.backgroundColorPrimary}
      style={{
        // border: hideBorder ? "0px solid #F6F6F6" : "1px solid #F6F6F6",
        width: "100%",
        height: "70px",
        backgroundColor: type === "dark" && "rgba(255, 255, 255, 0)",
        // borderRadius,
        display: "flex",
        justifyContent: justifyCenter
          ? "center"
          : isInsight
          ? "flex-start"
          : "space-between",
        alignItems: "center",
      }}
      elevation={0}
      onMouseEnter={() => handleMouseEnter(justifyCenter)}
      onMouseLeave={() => handleMouseLeave(justifyCenter)}
    >
      {children}
      <Typography
        className={classes.typoColorSecondary}
        style={{
          fontSize: "14px",
          fontWeight: secondaryHead ? 400 : 700,
          // color: "#242424",
          padding: "15px",
          lineHeight: "20px",
          fontFamily: "Lato",
        }}
      >
        {primaryHead}
      </Typography>
      <Typography
        className={classes.typoColorSecondary}
        style={{
          fontSize: "14px",
          fontWeight: 400,
          // color: "#242424",
          padding: "15px",
        }}
      >
        {secondaryHead}
      </Typography>
    </Paper>
  );
}

function EsgGptCompanyInfo({ esgScore, esgCScore }) {
  const classes = useStyles();
  const { newOrganization } = useSelector((state) => state.esgGptReducer);
  return (
    <Paper className={classes.root} elevation={0}>
      <img
        // src={aerofusion}
        src={newOrganization?.logo}
        style={{ marginLeft: "5px", maxWidth: "12rem" }}
        // width={"10%"}
        // height={"60%"}
        alt="star"
      />
      <div style={{ width: "80%", height: "90%" }}>
        <EsgGptNewCompanyDetails />
      </div>
    </Paper>
  );
}

export default EsgGptCompanyInfo;
