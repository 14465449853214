import React from "react";
import { useHistory } from "react-router-dom";
import {
  makeStyles,
  MuiThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  tab: {
    minWidth: "130px",
  },
  tabs: {
    padding: "1px 5px",
    // border:"solid"
  },
  tabLabel: {
    ...theme.typography.h6,
    color: theme.palette.primary.main,
  },
}));

export default function EsgGptDrawerTabs() {
  const classes = useStyles();
  const history = useHistory();
  const { location } = history;

  const tabValue = location.pathname === "/esg_gpt/chat" ? 1 : 0;

  const [value, setValue] = React.useState(tabValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeRoute = (newPath) => {
    let path = newPath;
    history.push(path);
  };

  return (
    <Tabs className={classes.tabs} value={value} onChange={handleChange}>
      <Tab
        className={classes.tab}
        onClick={() => handleChangeRoute("/esg_gpt/company_profiles")}
        label={<span className={classes.tabLabel}>Companies</span>}
      />
      {/* <Tab
        className={classes.tab}
        onClick={() => handleChangeRoute("/esg_gpt/chat")}
        label={<span className={classes.tabLabel}>Chats</span>}
      /> */}
    </Tabs>
  );
}
