import React, { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";

import {
  Avatar,
  Divider,
  IconButton,
  Paper,
  TextareaAutosize,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import DoneIcon from "@material-ui/icons/Done";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  ChatTable,
  MoreActionButtons,
} from "../esgGptCompanyProfile/EsgGptCompanyRight";
import { HeadingCard } from "../esgGptCompanyProfile/EsgGptCompany/EsgGptCompanyInfo";
import arrowUp from "../../../../images/arrow-up-circle.svg";
import boat from "../../../../images/boat.svg";
import avatarQuestion from "../../../../images/avatarQuestion.svg";

// import AILoader from "../../../../images/AILoader.png";
import AILoaderAnim from "../../../../images/GPT-Icons/animated/AILoader.gif";
import searching from "../../../../images/GPT-Icons/animated/Searching.gif";
import comparing from "../../../../images/GPT-Icons/animated/Comparing.gif";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import useChatData from "./useChatData";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "70vh",
    width: "100%",
    overflow: "scroll",
    marginBottom: "4%",
    // border:"solid",
    backgroundColor: theme.palette.background.paper,
    padding: "1px",
  },
  table: {
    minWidth: 700,
    // marginTop: "10px",
    width: "100%",
    // border:"1px solid"
  },
  tableCell: {
    padding: "15px",
  },
  titleRow: {
    // border:"solid",
    // backgroundColor: "#F6F6F6",
  },
  typography: {
    ...theme.typography.body1,
    color: theme.palette.primary.main,
    padding: "19px 20px",
    // border:"solid",
    // textAlign:"center"
  },
  mainHeadinBackground: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",

    borderBottom: `1px solid ${theme.palette.primary.light}`,

    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "10px",
  },
  typography2: {
    ...theme.typography.h5,
    color: theme.palette.primary.dark,
    padding: "10px",
  },
  typography3: {
    ...theme.typography.body3,
    color: theme.palette.primary.grey,
    // padding: "10px",
    // fontSize: "12px",
    fontWeight: 400,
    // color: "#ffffffb3",
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  borderPrimary: {
    borderBottom: `1px solid ${theme.palette.primary.light}`,
  },

  typoColorSecondary: {
    color: theme.palette.primary.dark,
  },
  backgroundColorPrimary: {
    backgroundColor: theme.palette.grey[100],
  },
  iconBackground: {
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.background.paper,
  },
  secondaryColorMain: {
    color: theme.palette.secondary.main,
  },
  iconBackground2: {
    // border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  suggestion: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#294E95",
    },
  },
  sourceTypo: {
    backgroundColor: theme.palette.background.contentBG,
    color: theme.palette.primary.main,
    border: "1px solid grey",
    borderRadius: "80px",
    padding: "4px 8px",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: 500,
  },
}));

export function UserChatQuestion({ question }) {
  const classes = useStyles();
  return (
    <Paper
      style={{
        display: "flex",
        alignItems: "center",
        padding: "10px",
        // backgroundColor: "#EBF1F8",
      }}
      className={classes.backgroundColorPrimary}
      elevation={0}
    >
      <img src={avatarQuestion} style={{ height: "40%" }} alt="arrow icon" />
      <Typography
        style={{
          fontSize: "14px",
          fontWeight: 700,
          // color: "#15314E",
          padding: "15px",
          fontStyle: "Lato",
        }}
        className={classes.primaryColor}
      >
        {question}
      </Typography>
      <IconButton
        style={{ color: "#3374B9", marginLeft: "auto" }}
        aria-label="add to shopping cart"
      >
        <EditOutlinedIcon style={{ color: "#E0E0E0", cursor: "pointer" }} />
      </IconButton>
    </Paper>
  );
}

export function BoatAcknowledged({ boatAcknowledged }) {
  const classes = useStyles();
  return (
    <Paper
      elevation={0}
      style={{ display: "flex", alignItems: "center", padding: "10px" }}
    >
      <img src={boat} style={{ height: "40%" }} alt="arrow icon" />
      <Typography
        style={{
          fontSize: "14px",
          fontWeight: 400,
          // color: "#15314E",
          padding: "15px",
          fontStyle: "Lato",
        }}
        className={classes.primaryColor}
      >
        {boatAcknowledged}
      </Typography>
    </Paper>
  );
}

export function UserChatAnswerLayout({
  isLoading,
  hideAvatar = false,
  children,
}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",

        // padding: "10px",
        marginLeft: hideAvatar && "30px",
      }}
    >
      {/* {!hideAvatar && (
        <Avatar
          style={{
            alignSelf: isLoading ? "flex-start" : "center",
            marginRight: "10px",
            color:"red"
          }}
        >
          IG
        </Avatar>
      )} */}
      {children}
    </div>
  );
}

export function UserChatAnswerLoading({ handleExpandMore, responseExpand }) {
  return (
    <>
      <CircularProgress
        style={{ color: "#3374B9", padding: "15px", zIndex: 9999 }}
        size={20}
      />

      <Typography
        style={{
          fontSize: "14px",
          fontWeight: 600,
          color: "#15314E",
          padding: "15px 15px 15px 0px",
        }}
      >
        Working on the profile
      </Typography>
      <CircularProgress
        style={{ color: "#3374B9", padding: "15px" }}
        size={20}
      />
      {responseExpand ? (
        <IconButton
          style={{ color: "#3374B9" }}
          aria-label="add to shopping cart"
          onClick={handleExpandMore}
        >
          <ExpandLessIcon style={{ fontSize: "30px" }} />
        </IconButton>
      ) : (
        <IconButton
          style={{ color: "#3374B9" }}
          aria-label="add to shopping cart"
          onClick={handleExpandMore}
        >
          <ExpandMoreIcon style={{ fontSize: "30px" }} />
        </IconButton>
      )}
    </>
  );
}

export function DummyAnswer({ firstSection, secondSection }) {
  const classes = useStyles();
  return (
    <>
      <Typography
        style={{
          fontSize: "14px",
          fontWeight: 400,
          // color: "#15314E",
          padding: "15px 15px 0px 15px",
          fontStyle: "Lato",
        }}
        className={classes.primaryColor}
      >
        {firstSection}
      </Typography>
      <List style={{ overflow: "scroll", width: "100%" }}>
        {secondSection.map((v) => {
          return (
            <ListItem>
              <ListItemAvatar>
                <img
                  src={arrowUp}
                  // style={{ border: "solid" }}
                  width={"50px"}
                  height={"100%"}
                  alt="star"
                />
              </ListItemAvatar>
              <ListItemText primary={v?.title} secondary={v?.descriptionL} />
            </ListItem>
          );
        })}
      </List>

      {/* <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <MoreActionButtons />
      </div> */}
    </>
  );
}

export function PointsData({ firstSection, secondSection, questionNumber }) {
  const classes = useStyles();
  // console.log("questionNumber", questionNumber);
  return (
    <>
      <Typography
        style={{
          fontSize: "14px",
          fontWeight: 400,
          // color: "#15314E",
          padding: "10px 15px 0px 15px",
          fontStyle: "Lato",
        }}
        className={classes.primaryColor}
      >
        {firstSection}
      </Typography>
      <List
        style={{
          overflow: "scroll",
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {secondSection.map((v, i) => {
          return (
            <ListItem
              style={{
                width: questionNumber === 2 ? "33%" : i === 5 ? "52%" : "49%",
              }}
            >
              <ListItemAvatar>
                <img
                  src={v?.logo} // icons for pointsResponse
                  // style={{ border: "solid" }}
                  width={"50px"}
                  height={"100%"}
                  alt="star"
                />
              </ListItemAvatar>
              {/* <ListItemText primary={v?.title} secondary={v?.descriptionL} tertiary={v?.descriptionL} /> */}
              <div>
                <Typography style={{ color: "#FFB9A2" }}>{v?.title}</Typography>
                {v?.points ? (
                  v?.points.map((typo) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {!typo.includes("Total") && (
                        <FiberManualRecordIcon
                          style={{ fontSize: "10px", paddingRight: 3 }}
                        />
                      )}
                      <Typography className={classes.typography3}>
                        {typo}
                      </Typography>
                    </div>
                  ))
                ) : (
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: 400,
                      color: "#ffffffb3",
                    }}
                  >
                    {v?.descriptionL}
                  </Typography>
                )}
              </div>
            </ListItem>
          );
        })}
      </List>

      {/* <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <MoreActionButtons />
      </div> */}
    </>
  );
}

export function UserChatAnswer({
  followUpResponse,
  followUpSuggestions,
  handleFolloUpSuggestions,
  children,
}) {
  const classes = useStyles();
  React.useEffect(() => {
    handleFolloUpSuggestions(followUpSuggestions);
  }, []);
  return (
    <div style={{ borderRadius: "8px", width: "100%", padding: "1px" }}>
      {children}
      <div style={{ padding: "10px" }}>
        {followUpResponse?.split(".").map(
          (folloUp, i) =>
            folloUp && (
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  // color: "#15314E",
                  padding: i !== 0 ? "25px 0px 5px 3px" : "10px 0px",
                  fontStyle: "Lato",
                }}
                className={classes.primaryColor}
              >
                {folloUp?.trim()}
                {folloUp?.substr(-1) === "?" ? "" : "."}
              </Typography>
            )
        )}
      </div>
    </div>
  );
}

export function FollowUpQuestion({ question }) {
  const classes = useStyles();
  return (
    <div
      style={{
        borderRadius: "80px",
        display: "flex",
        alignItems: "center",
        // width: "fit-content",
        cursor: "pointer",
        marginTop: "10px",
        height: "50px",
      }}
      className={classes.suggestion}
    >
      <img
        src={arrowUp}
        style={{ marginLeft: "5px", height: "40%", color: "red" }}
        alt="arrow icon"
      />
      <Typography
        className={classes.typoColorSecondary}
        style={{
          fontSize: "14px",
          // fontWeight: secondaryHead ? 400 : 700,
          // color: "#242424",
          padding: "2px 6px 2px 3px",
          // lineHeight: "20px",
          whiteSpace: "nowrap",
          // overflow: "hidden",
          // textOverflow: "ellipsis",
          fontFamily: "Lato",
        }}
      >
        {question}
      </Typography>
    </div>
  );
}

const followUpQuestions = [
  "mcdonald's sustainability efforts",
  "mcdonald's sustainability practices",
  "mcdonald's sustainability sourcing",
];
function EsgGptChatMessageLayout() {
  const { askMessage, newOrganization } = useSelector(
    (state) => state.esgGptReducer
  );

  const { chatData } = useChatData();

  const [chats, setChats] = React.useState([]);
  const [persona, setPersona] = useState(1);
  // const [loading, setLoading] = React.useState(false);
  // console.log("check", newOrganization, persona);
  const [responseExpand, setResponseExpand] = React.useState(false);

  const [followUpSuggestions, setFollowUpSuggestions] = React.useState([]);
  useEffect(() => {
    setPersona(newOrganization?.persona || 1);

    // chats.length > 0 && setChats([]);
    // setFollowUpSuggestions([]);
  }, [newOrganization]);
  // React.useEffect(
  //   function () {
  //     const timeout = setTimeout(function () {
  //       setChats((preValue) => preValue.map((v, i) => 1));
  //       setResponseExpand(false);
  //     }, 5000);

  //     return function () {
  //       clearTimeout(timeout);
  //     };
  //   },
  //   [loading]
  // );

  const Loading = ({ question }) => {
    console.log("question", question);
    const classes = useStyles();

    const returnLoader = () => {
      // switch (question) {
      //   case question.includes("for purchasing 1000 Lenovo laptops?"):
      //     return [searching, "Getting the Details for the Laptops."];
      //   default:
      //     return [AILoaderAnim, "Processing your request."];
      // }

      if (question.includes("for purchasing ")) {
        return [searching, "Getting the Details for the Laptops."];
      } else if (question.includes("CO2 Offset services") && persona === 1) {
        return [comparing, "Searching Details about CO2 Offset services."];
      } else if (question.includes("CO2 Offset services") && persona !== 1) {
        return [comparing, "Processing your request for CO2 Offset services."];
      } else if (question.toLowerCase().includes("asset recovery services")) {
        if (persona === 1 || persona === 2)
          return [comparing, "Getting details for the Services."];
        if (persona === 3)
          return [comparing, "Searching for Other Additional Services."];
        if (persona === 4)
          return [comparing, "Getting details about Asset Recovery Services."];
      } 
      return [AILoaderAnim, "Processing your request..."];
    };

    return (
      <Paper
        elevation={0}
        style={{ display: "flex", alignItems: "center", padding: "10px" }}
      >
        <img src={boat} style={{ height: "40%" }} alt="arrow icon" />
        <img
          src={returnLoader()[0]}
          style={{ width: "50px", marginLeft: 10 }}
          alt="arrow icon"
        />
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 400,
            // color: "#15314E",
            padding: "15px",
            fontStyle: "Lato",
          }}
          className={classes.primaryColor}
        >
          {returnLoader()[1]}
        </Typography>
      </Paper>
    );
  };
  // console.log("chats", chats);
  React.useEffect(() => {
    if (askMessage) {
      // console.log("ask", persona, askMessage);
      setIsLoading((prev) => !prev);
      persona === 1 &&
        setChats((preValue) =>
          preValue.map((c, i) =>
            i === 5
              ? {
                  ...c,
                  question: askMessage,
                  boatAcknowledged: `Your Email ID ${askMessage} has been added. Our team will get back to you on this.`,
                }
              : c
          )
        );

      (persona === 2 || persona === 4) &&
        setChats((preValue) =>
          preValue.map((c, i) =>
            i === 6
              ? {
                  ...c,
                  question: askMessage,
                  boatAcknowledged: `Your Email ID ${askMessage} has been added. Our team will get back to you on this.`,
                }
              : c
          )
        );
      persona === 3 &&
        setChats((preValue) =>
          preValue.map((c, i) =>
            i === 4
              ? {
                  ...c,
                  question: askMessage,
                  boatAcknowledged: `Your Email ID ${askMessage} has been added. Our team will get back to you on this.`,
                }
              : c
          )
        );

      setTimeout(() => {
        setIsLoading((prev) => !prev);
      }, [1200]);
    } else {
      setIsLoading((prev) => !prev);
      setChats([chatData[0]]);
      setTimeout(() => {
        setIsLoading((prev) => !prev);
      }, [1200]);
    }
  }, [askMessage]);

  const handleFollowUpQuestion = (questionNumber) => {
    // setLoading((preValue) => !preValue);
    // setChats((preValue) => [...preValue, 0]);
    setIsLoading((prev) => !prev);
    chats.length !== 7 &&
      setChats((preValue) => [...preValue, chatData[questionNumber]]);
    setTimeout(() => {
      setIsLoading((prev) => !prev);
    }, [1200]);
  };

  const handleFolloUpSuggestions = (suggestions) => {
    setFollowUpSuggestions(suggestions);
  };

  // console.log("chats", chats);
  const Source = ({ source }) => {
    const classes = useStyles();

    const isLink = (source) => {
      return (
        source.includes("GHG Equivalencies Calculator") ||
        source.includes("Lenovo TruScale cost to value ebook")
      );
    };

    const handleredirect = (source) => {
      source.includes("GHG Equivalencies Calculator") &&
        window.open(
          "https://www.epa.gov/energy/greenhouse-gas-equivalencies-calculator#results"
        );

      source.includes("Lenovo TruScale cost to value ebook") &&
        window.open(
          "https://techtoday.lenovo.com/sites/default/files/2023-08/lenovo-truScale-cost-to-value-eBook-ww.pdf"
        );
    };
    return (
      <>
        {/* {(persona === 1 || persona === 4) && (
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              marginBottom: "5px",
            }}
          >
            <div style={{ padding: "15px 15px 0px 15px" }}>
              <Typography
                className={classes.sourceTypo}
                style={{ backgroundColor: "#E0E0E01A" }}
              >
                Source
              </Typography>
            </div>
            <div>
              {source?.map((source, i) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {source.length > 1 && (
                    <FiberManualRecordIcon
                      style={{ fontSize: "10px", paddingRight: 3 }}
                    />
                  )}
                  <Typography
                    onClick={() => isLink(source) && handleredirect(source)}
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      // color: "#15314E",
                      cursor: isLink(source) && "pointer",
                      textDecoration: isLink(source) && "underline",
                      // padding: "15px 15px 0px 15px",
                      fontStyle: "Lato",
                    }}
                    className={classes.primaryColor}
                  >
                    {source}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        )} */}
        {(persona === 2 || persona === 3 || persona === 1 || persona === 4) && (
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <div style={{ padding: "15px 15px 0px 15px" }}>
              <Typography
                className={classes.sourceTypo}
                style={{ backgroundColor: "#E0E0E01A" }}
              >
                Source
              </Typography>
            </div>
            <div>
              {source?.map((source, i) => (
                <div style={{ display: "flex", alignItems: "top" }}>
                  {source.length > 1 && (
                    <Typography
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        // color: "#15314E",
                        // padding: "15px 15px 0px 15px",
                        paddingRight: "5px",
                        fontStyle: "Lato",
                      }}
                      className={classes.primaryColor}
                    >
                      {`${i + 1}) `}
                    </Typography>
                  )}
                  <Typography
                    onClick={() => isLink(source) && handleredirect(source)}
                    style={{
                      fontSize: "13px",
                      fontWeight: 400,
                      cursor: isLink(source) && "pointer",
                      textDecoration: isLink(source) && "underline",
                      // color: "#15314E",
                      // padding: "15px 15px 0px 15px",
                      fontStyle: "Lato",
                    }}
                    className={classes.primaryColor}
                  >
                    {source}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        )}
        {/* {persona === 3 && (
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <div style={{ padding: "15px 15px 0px 15px" }}>
              <Typography
                className={classes.sourceTypo}
                style={{ backgroundColor: "#E0E0E01A" }}
              >
                Source
              </Typography>
            </div>
            <div>
              {source?.map((source, i) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {source.length > 1 && (
                    <Typography
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        // color: "#15314E",
                        // padding: "15px 15px 0px 15px",
                        paddingRight: "5px",
                        fontStyle: "Lato",
                      }}
                      className={classes.primaryColor}
                    >
                      {`${i + 1}) `}
                    </Typography>
                  )}
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontWeight: 400,
                      // color: "#15314E",
                      // padding: "15px 15px 0px 15px",
                      fontStyle: "Lato",
                    }}
                    className={classes.primaryColor}
                  >
                    {source}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        )} */}
      </>
    );
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      // inline: "nearest",
    });
  };

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    scrollToBottom();
  }, [chats.length, isLoading]);

  return (
    <>
      <div>
        <div>
          {(persona === 1 || persona === 3 || persona === 4) &&
            chats.map(
              (
                {
                  question,
                  boatAcknowledged,
                  followUpResponse,
                  tableResponse,
                  mainRespone,
                  mainRespone1,
                  pointsResponse,
                  followUpSuggestions,
                  hasTableType,
                  hasTableType2,
                  tableResponse2,
                  hasPoints,
                },
                questionNumber
              ) => (
                <div
                  key={questionNumber}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <UserChatQuestion question={question} />

                  {isLoading && questionNumber === chats.length - 1 ? (
                    <>
                      <Loading question={question} />
                      <div ref={messagesEndRef} />
                    </>
                  ) : (
                    <>
                      <BoatAcknowledged boatAcknowledged={boatAcknowledged} />
                      <UserChatAnswerLayout>
                        <UserChatAnswer
                          followUpResponse={followUpResponse}
                          handleFolloUpSuggestions={handleFolloUpSuggestions}
                          followUpSuggestions={followUpSuggestions}
                        >
                          {hasTableType2 && (
                            <ChatTable
                              row={tableResponse2?.firstSection}
                              rowsData={tableResponse2?.secondSection}
                              desc={tableResponse2?.desc}
                              desc2={tableResponse2?.desc2}
                              desc3={tableResponse2?.desc3}
                            />
                          )}
                          {mainRespone && (
                            <PointsData
                              firstSection={mainRespone?.firstSection}
                              secondSection={mainRespone?.secondSection}
                              questionNumber={questionNumber}
                            />
                          )}

                          {mainRespone?.source && (
                            <Source source={mainRespone?.source} />
                          )}
                          {hasPoints && (
                            <PointsData
                              firstSection={pointsResponse?.firstSection}
                              secondSection={pointsResponse?.secondSection}
                            />
                          )}
                          {hasTableType && (
                            <ChatTable
                              desc={tableResponse?.desc}
                              row={tableResponse?.firstSection}
                              rowsData={tableResponse?.secondSection}
                            />
                          )}

                          {mainRespone1 && (
                            <PointsData
                              firstSection={mainRespone1?.firstSection}
                              secondSection={mainRespone1?.secondSection}
                            />
                          )}
                          {mainRespone1?.source && (
                            <Source source={mainRespone1?.source} />
                          )}
                        </UserChatAnswer>
                      </UserChatAnswerLayout>
                      {/* <div ref={messagesEndRef} /> */}
                    </>
                  )}
                </div>
              )
            )}

          {persona === 2 &&
            chats.map(
              (
                {
                  question,
                  boatAcknowledged,
                  followUpResponse,
                  tableResponse,
                  mainRespone,
                  mainRespone1,
                  pointsResponse,
                  followUpSuggestions,
                  hasTableType,
                  hasTableType2,
                  tableResponse2,
                  hasPoints,
                },
                questionNumber
              ) => (
                <div
                  key={questionNumber}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <UserChatQuestion question={question} />

                  {isLoading && questionNumber === chats.length - 1 ? (
                    <>
                      <Loading question={question} />
                      <div ref={messagesEndRef} />
                    </>
                  ) : (
                    <>
                      <BoatAcknowledged boatAcknowledged={boatAcknowledged} />
                      <UserChatAnswerLayout>
                        <UserChatAnswer
                          followUpResponse={followUpResponse}
                          handleFolloUpSuggestions={handleFolloUpSuggestions}
                          followUpSuggestions={followUpSuggestions}
                        >
                          {hasTableType2 && (
                            <ChatTable
                              row={tableResponse2?.firstSection}
                              rowsData={tableResponse2?.secondSection}
                              desc={tableResponse2?.desc}
                              desc2={tableResponse2?.desc2}
                              desc3={tableResponse2?.desc3}
                            />
                          )}
                          {mainRespone && (
                            <PointsData
                              firstSection={mainRespone?.firstSection}
                              secondSection={mainRespone?.secondSection}
                              questionNumber={questionNumber}
                            />
                          )}

                          {mainRespone?.source && (
                            <Source source={mainRespone?.source} />
                          )}
                          {hasPoints && (
                            <PointsData
                              firstSection={pointsResponse?.firstSection}
                              secondSection={pointsResponse?.secondSection}
                            />
                          )}
                          {hasTableType && (
                            <ChatTable
                              desc={tableResponse?.desc}
                              row={tableResponse?.firstSection}
                              rowsData={tableResponse?.secondSection}
                            />
                          )}

                          {mainRespone1 && (
                            <PointsData
                              firstSection={mainRespone1?.firstSection}
                              secondSection={mainRespone1?.secondSection}
                            />
                          )}
                          {mainRespone1?.source && (
                            <Source source={mainRespone1?.source} />
                          )}
                          {/* <div ref={messagesEndRef} /> */}
                        </UserChatAnswer>
                      </UserChatAnswerLayout>
                    </>
                  )}
                </div>
              )
            )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Divider variant="middle" />

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              // overflowX: "scroll",
              justifyContent: "center",
              alignItems: "center",
              // border:"solid",
              gap: "5px",

              // border:"solid"
            }}
          >
            {followUpSuggestions.map((question, i) => (
              <>
                <div
                  key={i}
                  onClick={() => handleFollowUpQuestion(chats.length)}
                >
                  <FollowUpQuestion question={question} />
                </div>
              </>
            ))}
          </div>
        </div>
      </div>

      {!isLoading && (
        <div
          style={{ border: "0px solid", marginTop: "50px" }}
          ref={messagesEndRef}
        />
      )}
    </>
  );
}

export default EsgGptChatMessageLayout;
