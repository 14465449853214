import React from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";

function GoogleMaps(props) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyANHKsD0fLKPZqTULELWr5bUIou-kHLBPc',
  });
  console.log(isLoaded);
// const classes= 
  const MyMaps = () => {
    return (
      <GoogleMap
        zoom={10}
        center={{ lat: 44, lng: -80 }}
        mapContainerClassName={{ width: "100%", height: "100&" }}
      ></GoogleMap>
    );
  };
  return (
    <div>
      {!isLoaded ? <div>...Loading</div> : <MyMaps />}
    </div>
  );
}

export default GoogleMaps;
