import { useSelector } from "react-redux";
import rightIdea from "../../../../images/GPT-Icons/SVG/Icon-13.svg";
import ringsConnect from "../../../../images/GPT-Icons/SVG/Icon-12.svg";
import recycle from "../../../../images/GPT-Icons/SVG/Icon-11.svg";
import thunder from "../../../../images/GPT-Icons/SVG/Icon-10.svg";
import Icon9 from "../../../../images/GPT-Icons/SVG/Icon-9.svg";
import Icon8 from "../../../../images/GPT-Icons/SVG/Icon-8.svg";
import co2Cloud from "../../../../images/GPT-Icons/SVG/Icon-7.svg";
import Icon6 from "../../../../images/GPT-Icons/SVG/Icon-6.svg";
import fuel from "../../../../images/GPT-Icons/SVG/Icon-5.svg";
import Icon4 from "../../../../images/GPT-Icons/SVG/Icon-4.svg";
import Icon3 from "../../../../images/GPT-Icons/SVG/Icon-3.svg";
import hardwareReuse from "../../../../images/GPT-Icons/SVG/Icon-2.svg";
import truck from "../../../../images/GPT-Icons/SVG/Icon-1.svg";
import Icon from "../../../../images/GPT-Icons/SVG/Icon.svg";
import tree from "../../../../images/GPT-Icons/SVG/treeIcon.svg";
import pay from "../../../../images/GPT-Icons/PNG/pay.png";
import co2footprint from "../../../../images/GPT-Icons/PNG/co2footprint.png";
import battery from "../../../../images/GPT-Icons/PNG/battery.png";
import data from "../../../../images/GPT-Icons/PNG/data.png";
import dataCloud from "../../../../images/GPT-Icons/PNG/dataCloud.png";
import timer from "../../../../images/GPT-Icons/PNG/timer.png";
import speedo from "../../../../images/GPT-Icons/PNG/speedo.png";
import costReduction from "../../../../images/GPT-Icons/PNG/costReduction.png";
import monitor from "../../../../images/GPT-Icons/PNG/monitor.png";
import savings from "../../../../images/GPT-Icons/PNG/savings.png";

const chatData = [
  {
    question: "Can you give details for purchasing 1000 Lenovo laptops?",
    hasTableType: true,
    followUpSuggestions: [
      "Yes, I would like to know more about Bulk packaging",
      "Can you give a final costing",
      "I would like to proceed with the regular service.",
    ],
    tableResponse: {
      firstSection: ["Product", "PCF (Kg CO2e) "],
      secondSection: [
        {
          column1: "THINKPAD T14 GEN3 AMD 21CF",
          column2: "218",
        },
        { column1: "THINKPAD X13 GEN3 AMD 21CM", column2: "197" },
      ],
    },
    followUpResponse:
      "If you would like to see more sustainable ways to purchase these products, you can learn more about our Bulk packaging services.",
    boatAcknowledged:
      "Sure, here is a list with suitable laptops. I have also highlighted their associated sustainability parameters.",
  },
  {
    question: "Yes, tell me more about sustainable bulk packaging.",
    hasTableType: true,
    hasPoints: true,
    followUpSuggestions: [
      "Yes, I would like to know more about the mode of transportation impact.",
      "Can you give a final costing",
      "I would like to proceed with the regular service.",
    ],
    pointsResponse: {
      firstSection:
        "Considering 1000 laptops being shipping from China to Sydney",
      secondSection: [
        {
          title: "Normal Packaging:",
          descriptionL:
            "Increasing the use of recycled and renewable materials in packaging.",
          points: [
            "1000 x T14 Gen3, Gwt: 2.40 kg",
            "1000 × X13 Gen3, Gwt: 2.00 kg",
            "Total 4.40 tons of cargo",
          ],
          logo: Icon8,
        },
        {
          title: "Bulk Packaging:",
          descriptionL:
            "Increasing the use of recycled and renewable materials in packaging.",
          points: [
            "1000 x T14 Gen3, Gwt: 2.05 kg",
            "1000 × X13 Gen3, Gwt: 1.73 kg",
            "Total 3.78 tons of cargo",
            "Total GHG emissions CO2e (WTW) in tons",
          ],
          logo: Icon8,
        },
      ],
    },
    mainRespone: {
      // firstSection:
      //   "Considering 1000 laptops being shipping from China to Mexico City, MX.",
      secondSection: [
        {
          title: "Packaging:",
          descriptionL:
            "Increasing the use of recycled and renewable materials in packaging.",
          // points: [
          // "1000 x T14 Gen3, Gwt: 2.40 kg",
          // "1000 × X13 Gen3, Gwt: 2.00 kg",
          // "Total 4.40 tons of cargo",
          // ],
          logo: Icon8,
        },
        {
          title: "Bio Material:",
          descriptionL: "Increasing the use of bio-based materials.",
          // points: [
          //   "1000 x T14 Gen3, Gwt: 2.05 kg",
          //   "1000 × X13 Gen3, Gwt: 1.73 kg",
          //   "Total 3.78 tons of cargo",
          //   "Total GHG emissions CO2e (WTW) in tons",
          // ],
          logo: Icon6,
        },
        {
          title: "Recycling and reuse:",
          descriptionL: "Reducing the size of product packaging.",
          // points: [
          //   "1000 x T14 Gen3, Gwt: 2.05 kg",
          //   "1000 × X13 Gen3, Gwt: 1.73 kg",
          //   "Total 3.78 tons of cargo",
          //   "Total GHG emissions CO2e (WTW) in tons",
          // ],
          logo: recycle,
        },
        {
          title: "Reducing packaging waste:",
          descriptionL:
            "Expanding the use of bulk and reusable packaging solutions.",
          // points: [
          //   "1000 x T14 Gen3, Gwt: 2.05 kg",
          //   "1000 × X13 Gen3, Gwt: 1.73 kg",
          //   "Total 3.78 tons of cargo",
          //   "Total GHG emissions CO2e (WTW) in tons",
          // ],
          logo: Icon3,
        },
      ],
    },
    tableResponse: {
      firstSection: [
        "",
        "Total Cargo (Tons)",
        "Sea Freight  + Truck",
        "Air Freight + Truck",
      ],
      secondSection: [
        {
          column1: "Single boxes",
          column2: "4.4",
          column3: "1.133",
          column4: "43.626",
        },
        {
          column1: "Bulk packaging",
          column2: "3.78",
          column3: "0.973",
          column4: "37.479",
        },

        {
          column1: "Total tons saved (%)",
          column2: "",
          column3: "~10%",
          column4: "~10%",
        },
      ],
    },
    mainRespone1: {
      secondSection: [
        {
          title: "~10% reduction in CO2 Emission",
          descriptionL: "Just by choosing bulk packaging",
          logo: co2Cloud,
        },
        {
          title: "620 Kg",
          descriptionL: "Of packaging waste saved",
          logo: Icon8,
        },
      ],
    },
    followUpResponse:
      "Furthermore we have options on how we ship packages, You can consider choosing a mode of transport with lower carbon footprint to further reduce your emissions. Would you like to know more about it ?",
    boatAcknowledged:
      "Lenovo’s intent with bulk packaging is to minimize the amount of packaging materials used while maintaining adequate protection for our products. Lenovo’s bulk packaging priorities focus on reducing our packaging waste, and thereby reducing carbon emissions during transit:",
  },
  {
    question: "Yes, tell me more about mode of transportation impact.",
    hasTableType: true,
    followUpSuggestions: [
      "Yes, I would like to know more about CO2 Offset services.",
      "Can you give a final costing for the 1000 laptops ?",
      "No, I would like to purchase them without any additional services",
    ],

    mainRespone1: {
      firstSection:
        "By choosing Reduced Carbon Transport Service on the above sample shipment, you will reduce ~122.2 Tons of CO2e which is equivalent to:",
      secondSection: [
        {
          title: "313,266 miles",
          descriptionL:
            "the distance an average gasoline-powered passenger car would drive to emit the same amount of GHG emissions as this activity¹.",
          logo: fuel,
        },
        {
          title: "2,017 tree seedlings",
          descriptionL:
            "that grow for 10 years can sequester the same amount of carbon as this activity¹",
          logo: tree,
        },
        {
          title: "Mimimum 70% CO2 emissions reduction²",
          descriptionL:
            "through the purchase of Sustainable Aviation Fuel credits.",
          logo: co2Cloud,
        },
      ],
      source: [
        "GHG Equivalencies Calculator, EPA",
        "Approximate percentage LCA GHG reduction versus fossil jet based on feedstock used in SAF such as waste and residue lipids, oilseed bearing trees on low-ILUC degraded land or as a rotational oil cover crop. Indirect CO2 emissions reduction benefit is obtained through the purchase of Sustainable Aviation Fuel credits.",
      ],
    },
    tableResponse: {
      desc: "Consider the same sample  shipment of ~19,400 kg from Hefei, China to Denmark :",

      firstSection: [
        "Total Order Weight (kg)",
        "Amount of Sustainable Aviation Fuel Claimed",
        "Total Scope 3 Emissions Reduced (kg)",
      ],
      secondSection: [
        {
          column1: "19,468.18",
          column2: "44,524 Liters",
          column3: "122.2 Tons CO2",
        },
      ],
    },
    hasTableType2: true,
    tableResponse2: {
      desc: "Assuming a sample shipment of  ~ 19,400 Kg from Hefei, China to Denmark, Sweden, and Finland, I’ve created a comparison between Sea and Air freight with their associated sustainability parameters. ",
      desc2: `Can’t afford to wait but still want a lower-carbon option to deliver your IT purchases?  You can opt for our Reduced Carbon Transport Service.`,
      desc3:
        " Lenovo Reduced Carbon Transport service gives you the option to ship your IT devices via air with lower-carbon impact. This is made possible with Sustainable Aviation Fuel (SAF) credits and allocating them to your new IT purchases. Additionally, you may report the resulting CO2 emissions reduction from airfreight against your own Scope 3 emissions​.",
      firstSection: [
        "Origin",
        "Destination",
        "Sea Freight (MT CO2)",
        "Air Freight (MT CO2)",
        "Potential savings (%)",
      ],
      secondSection: [
        {
          column1: "Hefei",
          column2: "Finland",
          column3: "3.34",
          column4: "104.86",
          column5: "90%",
        },
        {
          column1: "Hefei",
          column2: "Sweden",
          column3: "3.20",
          column4: "120.01",
          column5: "90%",
        },
        {
          column1: "Hefei",
          column2: "Denmark",
          column3: "3.36",
          column4: "110.25",
          column5: "90%",
        },
      ],
    },
    followUpResponse:
      "Furthermore, our CO2 offset services can help you offset carbon emissions across the IT lifecycle of your new purchases. Would you like to know about our CO2 Offset Services?",
    boatAcknowledged:
      "Lenovo offers different options to deliver your IT devices. Here's a quick look at the comparison of the different modes of transportation:",
  },
  {
    question:
      "Can you give details of CO2 Offset Services for 1000 Lenovo laptops?",
    hasTableType: true,
    followUpSuggestions: [
      "Yes, I would like to know more about Asset Recovery services.",
      "Can you give a final costing for the 1000 laptops ?",
      "What other device options do you provide?",
    ],

    mainRespone: {
      firstSection: "Some features are:",
      secondSection: [
        {
          title: "Easy and convenient:",
          descriptionL:
            "Offset the carbon cost for every hardware transaction, old and new, at point of purchase.",
          logo: rightIdea,
        },
        {
          title: "Transparent and verifiable:",
          descriptionL:
            "View details of the environmental project that your offset supports through your hardware serial number. You can also download a confirmation of offsets.",
          logo: ringsConnect,
        },
        {
          title: "Meaningful and sustainable:",
          descriptionL:
            "Your offsets support climate action projects verified by United Nations, Gold Standard®, and Climate Action Reserve, so you can trust the integrity of the climate action projects that your offsets support.",
          logo: Icon6,
        },
      ],
    },
    mainRespone1: {
      firstSection:
        "If you choose to add CO2 Offset Services to  your IT purchase of 1000 devices  you will offset 500 metric tons of CO2e, which is equivalent to .",
      secondSection: [
        {
          title: "97 Homes",
          descriptionL: "Electricity used for one year.",
          logo: thunder,
        },
        {
          title: "1,281,774 miles",
          descriptionL:
            "Is the distance that an average gasoline-powered passenger car would drive to emit the same amount of GHG emissions as this activity",
          logo: fuel,
        },
        {
          title: "8,268 tree seedlings",
          descriptionL:
            "that grow for 10 years can sequester the same amount of carbon as this activity",
          logo: tree,
        },
      ],
      source: ["GHG Equivalencies Calculator, EPA"],
    },
    tableResponse: {
      firstSection: [
        "Product",
        "PCF (Kg CO2e) ",
        "CO2 Offsets for purchase (In Tons)",
        "Quantity of devices",
        "Total CO2 Offsets  for purchase (Tons)",
      ],
      secondSection: [
        {
          column1: "THINKPAD T14 GEN3 AMD 21CF",
          column2: "218",
          column3: "0.5",
          column4: "500",
          column5: "250",
        },
        {
          column1: "THINKPAD X13 GEN3 AMD 21CM",
          column2: "197",
          column3: "0.5",
          column4: "500",
          column5: "250",
        },
        // {
        //   column1: "THINKPAD X1 YOGA GEN7 21CE",
        //   column2: "214",
        //   column3: "0.5",
        //   column4: "300",
        //   column5: "150",
        // },
      ],
    },

    followUpResponse:
      "If you would like to see more sustainable solutions, you can learn more about our Asset Recovery Services which can help recycle your old hardware. Would you like to know more ?",
    boatAcknowledged:
      "Lenovo CO2 Offset Services helps offset emissions across the average lifecycle from production, shipping, usage and end of life by supporting global climate action projects.",
  },
  {
    question: "Yes, I would like know more about your Asset Recovery services",
    hasTableType: false,
    followUpSuggestions: [
      "Yes, I would like to proceed with your recommendations.",
      "Can you give a final costing for the 1000 laptops ?",
      "What other device options do you provide?",
    ],

    mainRespone: {
      // firstSection:
      //   "By availing your current purchases and opting for RCT will have ~137 Kg of Carbon saving equivalent impact:",
      secondSection: [
        {
          title: "Data sanitization and disk destruction",
          descriptionL:
            "We can erase or destroy your data according to NIST 800-88 standards.",
          logo: Icon,
        },
        {
          title: "Pickup and transport",
          descriptionL:
            "We can arrange to collect and transport your devices from your location.",
          logo: truck,
        },
        {
          title: "Recycling and reuse",
          descriptionL:
            "You can lower your environmental impact by refurbishing devices, reusing parts and  recycling assets to support a circular economy.",
          logo: hardwareReuse,
        },
        {
          title: "Fair market value",
          descriptionL:
            "You can get a fair value for your re-marketable assets and receive detailed reports on your devices.",
          logo: Icon3,
        },
        {
          title: "Donate to a charity of your choice",
          descriptionL:
            "After securely wiping the data of your devices, we can refurbish and donate your old IT assets to support a charity of your choice, giving the device a purposeful second life.",
          logo: Icon4,
        },
        {
          title: "89% of organisations",
          descriptionL:
            "Recycle less than 10% of their IT hardware¹. We can help you change that.",
          logo: recycle,
        },
        {
          title: "31,532 Metric Tons",
          descriptionL: `Products were collected for reuse and recycle through Lenovo ARS and product take-back programs in 2022. This is the equivalent of 3 times the weight of an Eiffel Tower.`,
          logo: Icon6,
        },
      ],
      source: [
        "Sustainable IT, Capgemini Research",
        "Lenovo ESG Report FY2022/23",
      ],
    },

    followUpResponse:
      "Would you like to proceed with any of these services ? I can help you with more information and help clear doubts, if you have any.",
    boatAcknowledged:
      "Lenovo Asset Recovery Services help mitigate end-of-life IT and data security risks by refurbishing and recycling your old IT assets in an environmentally conscious way.  Below are some features:",
  },
  // {
  //   question: "Yes, tell me more about mode of transportation impact.",
  //   hasTableType: true,
  //   followUpSuggestions: [
  //     "I would like to proceed with the regular service",
  //     "Please tell me about your other serices.",
  //     "Yes,I would like to proceed with DaaS Service",
  //   ],
  //   tableResponse: {
  //     firstSection: [
  //       "Product",
  //       "Quantity",
  //       "Product Carbon Footprint",
  //       "Total Carbon Savings",
  //     ],
  //     secondSection: [
  //       {
  //         column1: "ThinkPad T10",
  //         column2: "300",
  //         column3: "10",
  //         column4: "3%",
  //       },
  //       { column1: "Tru laaS", column2: "100", column3: "8", column4: "12%" },
  //       {
  //         column1: "MoT Air Carbon Footprint",
  //         column2: "100",
  //         column3: "8",
  //         column4: "12%",
  //       },
  //       {
  //         column1: "MoT Sea Carbon ",
  //         column2: "100",
  //         column3: "8",
  //         column4: "12%",
  //       },
  //     ],
  //   },
  //   followUpResponse:
  //     "By availing your current purchases through DaaS Service with Mot Air will have a Carbon equivalent impact: \n GHG emissions from 2,563,549 miles driven by an average gasoline-powered passenger vehicle \n Carbon sequestered by 16,535 tree seedlings grown for 10 years \n Would you like to proceed to opt for this service ?",
  //   boatAcknowledged:
  //     "Sure, Here is the table with your purchase history. I have also highlighted their associated sustainability parameters.",
  // },
  {
    question: "Yes, I would like to proceed with your recommendations.",
    hasTableType: false,
    followUpSuggestions: [
      "Yes, I would like to proceed with Asset Recovery Service.",
      "Can you give a final costing for the 1000 laptops ?",
      "What other device options do you provide?",
    ],
    mainRespone: {
      firstSection: "",
      secondSection: [],
    },
    followUpResponse: "",
    boatAcknowledged:
      "Please enter your email id and submit we will get back to you over email with the details of our conversation and take it forward.",
  },
  {
    question: "Useremail@gmail.com",
    hasTableType: false,
    followUpSuggestions: [
      "Yes,I would like to proceed with DaaS Service",
      "I would like to proceed with the regular service",
      "Please tell me about your other serices.",
    ],
    mainRespone: {
      firstSection: "",
      secondSection: [],
    },
    followUpResponse: "Would you need any other details ?",
    boatAcknowledged:
      "Your Email ID Useremail@gmail.com has been added. Our team will get back to you on this.",
  },
];

const chatData2 = [
  {
    question: "Can you give details for purchasing 50 Lenovo laptops?",
    boatAcknowledged:
      "Sure, here is a recommended list of laptops with their sustainability attributes. I have also highlighted how they compare to similar devices from Dell.",
    hasTableType: true,
    followUpSuggestions: [
      "Yes, I would like to know more about Bulk packaging",
      "No I don't need any additional services.",
      "Let’s move on to the final costing.",
    ],
    tableResponse: {
      firstSection: [
        "Lenovo Product",
        "PCF (Kg CO2e)",
        "Dell",
        "PCF (Kg CO2e)",
        "PCF (KgCO2e) saving",
      ],
      secondSection: [
        {
          column1: "ThinkPad L14 Gen 3/4",
          column2: "216",
          column3: "Dell 5430/40",
          column4: "290",
          column5: "~74",
        },
        {
          column1: "ThinkPad T16 Gen 12",
          column2: "224",
          column3: "Dell 7640 (23)",
          column4: "349",
          column5: "~125",
        },
        {
          column1: "Lenovo X1 Carbon Gen 10/11",
          column2: "187",
          column3: "Dell 9430/40 2in1",
          column4: "391",
          column5: "~204",
        },
      ],
    },
    followUpResponse:
      "If you would like to see more sustainable ways to purchase these products, you can learn more about our Bulk packaging services.",
  },
  {
    question: "Yes, tell me more about sustainable bulk packaging.",
    hasTableType: true,
    hasPoints: true,
    followUpSuggestions: [
      "Yes, I would like to know more about the mode of transportation impact",
      "No I don't need any additional services.",
      "Let’s move on to the final costing.",
    ],
    pointsResponse: {
      firstSection:
        "Considering 50 laptops being shipping from Hefei to Copenhagen.",
      secondSection: [
        {
          title: "Normal Packaging:",
          descriptionL:
            "Increasing the use of recycled and renewable materials in packaging.",
          points: [
            "50 x T16 Gen3, Gwt: 0.16 Ton",
            "50 × L14 Gen3, Gwt: 0.14 Ton",
            "50 x X1 Gen 10, Gwt: 0.10 Ton",
            "Total 0.4 ton of cargo",
          ],
          logo: Icon8,
        },
        {
          title: "Bulk Packaging:",
          descriptionL:
            "Increasing the use of recycled and renewable materials in packaging.",
          points: [
            "50 x T16 Gen3, Gwt: 0.14 Ton",
            "50 × L14 Gen3, Gwt: 0.12 Ton",
            "50 x X1 Gen 10, Gwt: 0.08 Ton",
            "Total 3.78 tons of cargo",
            "Total 0.34 ton of cargo",
          ],
          logo: Icon8,
        },
      ],
    },
    mainRespone: {
      // firstSection:
      //   "Considering 50 laptops being shipping from Hefei to Copenhagen.",
      secondSection: [
        {
          title: "Packaging:",
          descriptionL:
            "Increasing the use of recycled and renewable materials in packaging.",
          // points: [
          // "1000 x T14 Gen3, Gwt: 2.40 kg",
          // "1000 × X13 Gen3, Gwt: 2.00 kg",
          // "Total 4.40 tons of cargo",
          // ],
          logo: Icon8,
        },
        {
          title: "Bio Material:",
          descriptionL: "Increasing the use of bio-based materials.",
          // points: [
          //   "1000 x T14 Gen3, Gwt: 2.05 kg",
          //   "1000 × X13 Gen3, Gwt: 1.73 kg",
          //   "Total 3.78 tons of cargo",
          //   "Total GHG emissions CO2e (WTW) in tons",
          // ],
          logo: Icon6,
        },
        {
          title: "Recycling and reuse:",
          descriptionL: "Reducing the size of product packaging.",
          // points: [
          //   "1000 x T14 Gen3, Gwt: 2.05 kg",
          //   "1000 × X13 Gen3, Gwt: 1.73 kg",
          //   "Total 3.78 tons of cargo",
          //   "Total GHG emissions CO2e (WTW) in tons",
          // ],
          logo: recycle,
        },
        {
          title: "Reducing packaging waste:",
          descriptionL:
            "Expanding the use of bulk and reusable packaging solutions.",
          // points: [
          //   "1000 x T14 Gen3, Gwt: 2.05 kg",
          //   "1000 × X13 Gen3, Gwt: 1.73 kg",
          //   "Total 3.78 tons of cargo",
          //   "Total GHG emissions CO2e (WTW) in tons",
          // ],
          logo: Icon3,
        },
      ],
    },
    tableResponse: {
      desc: "Total GHG emissions CO2e (WTW) in tons",

      firstSection: ["", "Total Cargo (Tons)", "Sea Freight", "Air Freight"],
      secondSection: [
        {
          column1: "Single boxes",
          column2: "0.4",
          column3: "0.0731",
          column4: "2.3368",
        },
        {
          column1: "Bulk packaging",
          column2: "0.34",
          column3: "0.0621",
          column4: "1.9864",
        },

        {
          column1: "Total tons saved (%)",
          column2: "",
          column3: "15%",
          column4: "15%",
        },
      ],
    },
    mainRespone1: {
      secondSection: [
        {
          title: "~15% reduction in CO2 Emission",
          descriptionL: "Just by choosing bulk packaging",
          logo: co2Cloud,
        },
        {
          title: "60 Kg",
          descriptionL: "Of packaging waste saved",
          logo: Icon8,
        },
      ],
    },
    followUpResponse:
      "Furthermore we have options on how we ship packages, You can consider choosing a mode of transport with lower carbon footprint to further reduce your emissions.Would you like to know more about it ?",
    boatAcknowledged:
      "Lenovo’s intent with bulk packaging is to minimize the amount of packaging materials used while maintaining adequate protection for our products. Lenovo’s bulk packaging priorities focus on reducing our packaging waste, and thereby reducing carbon emissions during transit:",
  },
  {
    question: "Yes, tell me more about mode of transportation impact.",
    hasTableType: true,
    followUpSuggestions: [
      "Yes, I would like to know more about warranty extensions  services.",
      "No, I don't need any additional services",
      "Let’s move on to the final costing.",
    ],

    mainRespone1: {
      firstSection:
        "By choosing Reduced Carbon Transport Service on the above sample shipment, you will reduce ~122.2 Tons of CO2e which is equivalent to:",
      secondSection: [
        {
          title: "313,266 miles",
          descriptionL:
            "the distance an average gasoline-powered passenger car would drive to emit the same amount of GHG emissions as this activity¹",
          logo: fuel,
        },
        {
          title: "2,017 tree seedlings",
          descriptionL:
            "that grow for 10 years can sequester the same amount of carbon as this activity¹",
          logo: tree,
        },
        {
          title: "Mimimum 70% CO2 emissions reduction²",
          descriptionL:
            "through the purchase of Sustainable Aviation Fuel credits.",
          logo: co2Cloud,
        },
      ],
      source: [
        "GHG Equivalencies Calculator, EPA",
        "Approximate percentage LCA GHG reduction versus fossil jet based on feedstock used in SAF such as waste and residue lipids, oilseed bearing trees on low-ILUC degraded land or as a rotational oil cover crop. Indirect CO2 emissions reduction benefit is obtained through the purchase of Sustainable Aviation Fuel credits.",
      ],
    },
    tableResponse: {
      desc: "Consider the same sample  shipment of ~19,400 kg from Hefei, China to Denmark :",

      firstSection: [
        "Total Order Weight (kg)",
        "Amount of Sustainable Aviation Fuel Claimed",
        "Total Scope 3 Emissions Reduced (kg)",
      ],
      secondSection: [
        {
          column1: "19,468.18",
          column2: "44,524 Liters",
          column3: "122.2 Tons CO2",
        },
      ],
    },
    hasTableType2: true,
    tableResponse2: {
      desc: "Assuming a sample shipment of  ~ 19,400 Kg from Hefei, China to Denmark, Sweden, and Finland, I’ve created a comparison between Sea and Air freight with their associated sustainability parameters. ",
      desc2:
        "Can’t afford to wait but still want a lower-carbon option to deliver your IT purchases?  You can opt for our Reduced Carbon Transport Service.",
      desc3:
        " Lenovo Reduced Carbon Transport service gives you the option to ship your IT devices via air with lower-carbon impact. This is made possible with Sustainable Aviation Fuel (SAF) credits and allocating them to your new IT purchases. Additionally, you may report the resulting CO2 emissions reduction from airfreight against your own Scope 3 emissions​.",
      firstSection: [
        "Origin",
        "Destination",
        "Sea Freight (MT CO2)",
        "Air Freight (MT CO2)",
        "Potential savings (%)",
      ],
      secondSection: [
        {
          column1: "Hefei",
          column2: "Finland",
          column3: "3.34",
          column4: "104.86",
          column5: "90%",
        },
        {
          column1: "Hefei",
          column2: "Sweden",
          column3: "3.20",
          column4: "120.01",
          column5: "90%",
        },
        {
          column1: "Hefei",
          column2: "Denmark",
          column3: "3.36",
          column4: "110.25",
          column5: "90%",
        },
      ],
    },
    followUpResponse:
      "Furthermore, our warranty extensions can help you prolong the life of the devices you are purchasing. Would you like to know about our warranty extensions ?",
    boatAcknowledged:
      "Lenovo offers different options to deliver your IT devices. Here's a quick look at the comparison of the different modes of transportation:",
  },
  {
    question: "Please tell me about your warranty and support services.",
    hasTableType: false,
    followUpSuggestions: [
      "Yes, I would like to know more about your CO2 Offset services.",
      "Can you give a final costing for the Lenovo TruScale DaaS ?",
      "I would like to proceed with the regular service.",
    ],

    mainRespone: {
      firstSection:
        "Premier Support Plus is a service that provides fast and proactive IT support for your Lenovo devices and helps you keep your devices in use longer. Some of the features are:",
      extraSection: "Warranty Extensions - Recommendations :",
      secondSection: [
        {
          title: "Accidental Damage Protection",
          descriptionL:
            " You can avoid the hassle and expense of repairing or replacing your device due to accidental spills or drops that are not covered by the standard or extended warranty.",
          logo: Icon,
        },

        {
          title: "Keep Your Drive",
          descriptionL:
            "You can keep your hard drive and data in case of replacement.",
          logo: hardwareReuse,
        },

        {
          title: "Extended Battery Protection",
          descriptionL:
            "You can extend your battery warranty up to 3 years which helps maintain performance and keep your devices in use for longer than typical refresh cycles.",
          logo: battery,
        },
        {
          title: "Proactive and Predictive Issue Detection",
          descriptionL:
            "AI-powered analytics to help optimize your device health and anticipate repairs early to avoid downtime.",
          logo: data,
        },
      ],
    },
    mainRespone1: {
      firstSection:
        "The main sustainability advantages of the warranty extensions are :",
      secondSection: [
        {
          title: "20%- 40% reduction in cost",
          descriptionL:
            "Extending device lifespans can represent 20%- 40% reduction in cost, GHG emissions, and e-waste¹.",
          logo: costReduction,
        },
        {
          title: "Up to 80% savings",
          descriptionL:
            "On repair costs² with ADP and avoid waste from replacing damaged devices.",
          logo: savings,
        },
      ],
      source: [
        "Independent 3rd party analyst company commissioned by Lenovo",
        "Lenovo internal data source.",
      ],
    },

    followUpResponse:
      "If you would like to see more sustainability solutions, you can learn more about our CO2 Offset Services as well. Would you like to explore our CO2 Offset Services?",
    boatAcknowledged:
      "Sure, here are some additional services that will go well with you purchase request.",
  },
  {
    question: "Yes, I would like to know more about your CO2 Offset services.",
    hasTableType: true,
    followUpSuggestions: [
      "Yes, I would like to know more about Asset Recovery services.",
      "Can you give a final costing for the 10 laptops ?",
      "What other device options do you provide?",
    ],

    mainRespone: {
      firstSection: "Some features are:",
      secondSection: [
        {
          title: "Easy and convenient:",
          descriptionL:
            "Offset the carbon cost for every hardware transaction, old and new, at point of purchase.",
          logo: rightIdea,
        },
        {
          title: "Transparent and verifiable:",
          descriptionL:
            "View details of the environmental project that your offset supports through your hardware serial number. You can also download a confirmation of offsets.",
          logo: ringsConnect,
        },
        {
          title: "Meaningful and sustainable:",
          descriptionL:
            "Your offsets support climate action projects verified by United Nations, Gold Standard®, and Climate Action Reserve, so you can trust the integrity of the climate action projects that your offsets support.",
          logo: Icon6,
        },
      ],
    },
    mainRespone1: {
      firstSection:
        "If you choose to add CO2 Offset Services to  your IT purchase of 50 devices  you will offset 25 metric tons of CO2e, which is equivalent to.",
      secondSection: [
        {
          title: "64,089 miles",
          descriptionL:
            "Is the distance that an average gasoline-powered passenger car would drive to emit the same amount of GHG emissions as this activity",
          logo: fuel,
        },
        {
          title: "413 tree seedlings",
          descriptionL:
            "that grow for 10 years can sequester the same amount of carbon as this activity",
          logo: tree,
        },
      ],
      source: ["GHG Equivalencies Calculator, EPA"],
    },
    tableResponse: {
      firstSection: [
        "Product",
        "PCF (Kg CO2e) ",
        "CO2 Offsets for purchase (In Tons)",
        "Quantity of devices",
        "Total CO2 Offsets  for purchase (Tons)",
      ],
      secondSection: [
        {
          column1: "Lenovo X1 Carbon Gen 10411",
          column2: "187",
          column3: "0.5",
          column4: "50",
          column5: "25",
        },
      ],
    },

    followUpResponse:
      "If you would like to see more sustainable solutions, you can learn more about our Asset Recovery Services which can help recycle your old hardware. Would you like to know more ?",
    boatAcknowledged:
      "Lenovo CO2 Offset Services helps offset emissions across the average lifecycle from production, shipping, usage and end of life by supporting global climate action projects.",
  },
  {
    question: "Yes, I would like to know more about Asset Recovery services.",
    hasTableType: false,
    followUpSuggestions: [
      "Yes, I would like to proceed with your recommendations.",
      "Can you give a final costing for the 10 laptops ?",
      "What other device options do you provide?",
    ],

    mainRespone: {
      firstSection:
        "Lenovo Asset Recovery Services help mitigate end-of-life IT and data security risks by refurbishing and recycling your old IT assets in an environmentally conscious way.  Below are some features:",
      secondSection: [
        {
          title: "Data sanitization and disk destruction",
          descriptionL:
            "We can erase or destroy your data according to NIST 800-88 standards.",
          logo: Icon,
        },
        {
          title: "Pickup and transport",
          descriptionL:
            "We can arrange to collect and transport your devices from your location.",
          logo: truck,
        },
        {
          title: "Recycling and reuse",
          descriptionL:
            "You can lower your environmental impact by recycling or reusing your devices or parts and support a circular economy.",
          logo: hardwareReuse,
        },
        {
          title: "Fair market value",
          descriptionL:
            "You can get a fair value for your re-marketable assets and receive detailed reports on your devices",
          logo: Icon3,
        },
        {
          title: "Donate to a charity of your choice",
          descriptionL:
            "After securely wiping the data of your devices, we can refurbish and donate your old IT assets to support a charity of your choice, giving the device a purposeful second life.",
          logo: Icon4,
        },
        {
          title: "89% of organisations",
          descriptionL:
            "Recycle less than 10% of their IT hardware¹. We can help you change that.",
          logo: recycle,
        },
        {
          title: "31,532 Metric Tons",
          descriptionL: `Products were collected for reuse and recycle through Lenovo ARS and product take-back programs in 2022. This is the equivalent of 3 times the weight of an Eiffel Tower.`,
          logo: Icon6,
        },
      ],
      source: [
        "Sustainable IT, Capgemini Research",
        "Lenovo ESG Report FY2022/23",
      ],
    },

    followUpResponse: "Would you like to purchase these services ?",
    boatAcknowledged:
      "Sure, here are some additional services that will go well with you purchase request. ",
  },
  {
    question: "Yes, I would like to proceed with your recommendations.",
    hasTableType: false,
    followUpSuggestions: [
      "Yes, I would like to proceed with your recommendation.",
      "Can you give a final costing for the 10 laptops ?",
      "What other device options do you provide?",
    ],
    mainRespone: {
      firstSection: "",
      secondSection: [],
    },
    followUpResponse: "",
    boatAcknowledged:
      "Please enter your email id and submit we will get back to you over email with the details of our conversation and take it forward.",
  },
  {
    question: "Useremail@gmail.com",
    hasTableType: false,
    followUpSuggestions: [
      "Yes,I would like to proceed with DaaS Service",
      "I would like to proceed with the regular service",
      "Please tell me about your other serices.",
    ],
    mainRespone: {
      firstSection: "",
      secondSection: [],
    },
    followUpResponse: "Would you need any other details ?",
    boatAcknowledged:
      "Your Email ID Useremail@gmail.com has been added. Our team will get back to you on this.",
  },
];

const chatData3 = [
  {
    question: "Can you get my purchase history?",
    boatAcknowledged:
      "Sure, here is the list with your purchase history. I have also highlighted their associated sustainability parameters.",
    hasTableType: true,
    followUpSuggestions: [
      "Yes, tell me more about TruScale IaaS and its advantages.",
      "Can you recommend some replacement products?",
      "What other services does Lenovo provide?",
    ],
    tableResponse: {
      firstSection: ["Product", "Quantity", "PCF (Kg CO2e)"],
      secondSection: [
        {
          column1: "THINKSYSTEM SR630 V3 7D72",
          column2: "10",
          column3: "12556",
        },
        {
          column1: "THINKSYSTEM SR650 V3 7D75",
          column2: "10",
          column3: "14745",
        },
        {
          column1: "THINKSYSTEM SR250 V2 7D7Q",
          column2: "10",
          column3: "6360",
        },
      ],
    },
    followUpResponse:
      "If you would like to see more sustainable ways to purchase these products, you can switch your purchase model to Lenovo TruScale IaaS. Would you like to explore Lenovo TruScale IaaS and its advantages?",
  },
  {
    question: " Yes, tell me more about TruScale IaaS and its advantages.",
    hasTableType: false,
    followUpSuggestions: [
      "Yes, I would like to know more about your CO2 Offset services.",
      "Can you give a final costing for the TruScale IaaS service ?",
      "I would like to proceed with the regular service.",
    ],
    mainRespone: {
      firstSection: "",
      secondSection: [
        {
          title: "Lifecycle management",
          descriptionL:
            "Lenovo sets up, deploys, and removes the hardware for you, and follows best-practices deployment. You can use a customer portal to monitor and manage your infrastructure.",
          logo: Icon,
        },
        {
          title: "Monitoring and management",
          descriptionL:
            "Lenovo measures utilization, monitors infrastructure health, temperature, power usage, updates, and assigns a customer success manager.",
          logo: monitor,
        },

        {
          title: "Transparency and verification",
          descriptionL:
            "You can see your infrastructure costs in real time, and enjoy predictable and no-surprise pricing. You can also save resources and costs by avoiding over-provisioning.",
          logo: hardwareReuse,
        },

        {
          title:
            "Solutions for hybrid and multi-cloud, infinite storage, and HPC",
          descriptionL:
            "You can get the benefits of on-premises infrastructure with a cloud-like experience, and explore specific solutions for your needs. Lenovo works with industry-leading providers like Microsoft Azure, VMware, Deloitte, and Intel to deliver the best solutions for you.",
          logo: dataCloud,
        },
      ],
    },

    mainRespone1: {
      firstSection:
        "Choosing TruScale IaaS will drive further sustainable impacts such as:",
      secondSection: [
        {
          title: "86,000 times per month",
          descriptionL:
            "Lenovo xClarity meters each server 86,000 times per month to accurately monitor power consumption¹",
          logo: speedo,
        },

        {
          title: "up to 20% reduction²",
          descriptionL:
            "Optimizing your data centre with Lenovo TruScale IaaS can reduce CO2 emissions and power consumption by up to 20%.2",
          logo: co2Cloud,
        },

        {
          title: "40% increase in productivity",
          descriptionL:
            "One TruScale customer estimated 40% more time for IT teams to focus on projects that directly benefit the business when Lenovo handled routine datacenter responsibilities.",
          logo: timer,
        },
      ],
      source: [
        "Lenovo Internal source",
        "TruScale IaaS accurately reports on power and CO2 emissions allowing managed infrastructures to be designed, implemented, and tuned not only for performance and capacity but also for CO2 emissions. Ongoing monitoring of the system using Lenovo XClarity Power Monitor and systems performance figures are used to optimize power consumption by the infrastructure. CO2 emissions will be calculated based on the localized carbon footprint of the power source used.",
        "Lenovo TruScale cost to value ebook",
      ],
    },

    followUpResponse:
      "If you would like to see more sustainable ways to purchase these products, you can learn more about our CO2 offset services. Would you like to explore our  CO2 offset services?",
    boatAcknowledged:
      "Lenovo TruScale IaaS is a pay-as-you-go service that gives you flexible, scalable, and secure IT infrastructure. It makes your data centre management and IT provisioning easier, and helps you find the right solutions for your hybrid and multi-cloud needs. Some features are:",
  },
  {
    question: "Yes, I would like to know more about your CO2 Offset services.",
    hasTableType: true,
    followUpSuggestions: [
      "Yes, I would like to know more about Asset Recovery services.",
      "Can you give a final costing for the 10 laptops ?",
      "What other device options do you provide?",
    ],

    mainRespone: {
      firstSection: "",
      // "By availing your current purchases and opting for RCT will have ~137 Kg of Carbon saving equivalent impact:",
      secondSection: [
        {
          title: "Easy and convenient",
          descriptionL:
            "Offset the carbon cost for every hardware transaction, old and new, at point of purchase.",
          logo: rightIdea,
        },
        {
          title: "Transparent and verifiable",
          descriptionL:
            "View details of the environmental project that your offset supports through your hardware serial number. You can also download a confirmation of offsets.",
          logo: ringsConnect,
        },
        {
          title: "Meaningful and sustainable",
          descriptionL:
            "Your offsets support climate action projects verified by United Nations, Gold Standard®, and Climate Action Reserve, so you can trust the integrity of the climate action projects that your offsets support.",
          logo: Icon6,
        },
      ],
    },
    mainRespone1: {
      firstSection:
        "If you choose to add CO2 Offset Services to  your IT purchase of 10 devices  you will offset 200 metric tons of CO2e, which is equivalent to :",
      secondSection: [
        {
          title: "512,710 miles",
          descriptionL:
            "Is the distance an average gasoline-powered passenger car would drive to emit the same amount of GHG emissions as this activity.",
          logo: fuel,
        },
        {
          title: "3,307 tree seedlings",
          descriptionL:
            "That grow for 10 years can sequester the same amount of carbon as this activity.",
          logo: tree,
        },
        // {
        //   title: "Tangible Scope 3 emission",
        //   descriptionL:
        //     "Reductions made possible with Lenovo Reduced Carbon Transport Service. By using Sustainable Aviation Fuel (SAF) credits for air shipping.",
        //   logo: Icon6,
        // },
      ],
      // source: ["GHG Equivalencies Calculator, EPA"],
    },
    tableResponse: {
      firstSection: [
        "Product",
        "PCF (Kg CO2e) ",
        "CO2 Offsets for purchase (In Tons)",
        "Quantity of devices",
        "Total CO2 Offsets  for purchase (Tons)",
      ],
      secondSection: [
        {
          column1: "THINKSYSTEM SR650 V3 7D75",
          column2: "14745",
          column3: "20",
          column4: "10",
          column5: "200",
        },
      ],
    },

    followUpResponse:
      "If you would like to see more sustainable solutions, you can learn more about our Asset Recovery Services which can help recycle your old hardware. Would you like to know more ?",
    boatAcknowledged:
      "Lenovo CO2 Offset Services helps offset emissions across the average lifecycle from production, shipping, usage and end of life by supporting global climate action projects. Some features are:",
  },

  {
    question: "Yes, I would like to know more about Asset Recovery services.",
    hasTableType: false,
    followUpSuggestions: [
      "Yes, I would like to proceed with your recommendations.",
      "Can you give a final costing for the 10 laptops ?",
      "What other device options do you provide?",
    ],

    mainRespone: {
      firstSection:
        "Lenovo Asset Recovery Services help mitigate end-of-life IT and data security risks by refurbishing and recycling your old IT assets in an environmentally conscious way.  Below are some features:",
      secondSection: [
        {
          title: "Data sanitization and disk destruction",
          descriptionL:
            "We can erase or destroy your data according to NIST 800-88 standards.",
          logo: Icon,
        },
        {
          title: "89% of organisations",
          descriptionL:
            "Recycle less than 10% of their IT hardware¹. We can help you change that.",
          logo: recycle,
        },
        {
          title: "Pickup and transport",
          descriptionL:
            "We can arrange to collect and transport your devices from your location.",
          logo: truck,
        },
        {
          title: "31,532 Metric Tons",
          descriptionL:
            "products were collected for reuse and recycling through Lenovo ARS and product take-back programs in 2022. This is the equivalent of 3 times the weight of an Eiffel Tower.",
          logo: Icon6,
        },
        {
          title: "Recycling and reuse",
          descriptionL:
            "You can lower your environmental impact by refurbishing devices, reusing parts and  recycling assets to support a circular economy.",
          logo: hardwareReuse,
        },
        {
          title: "Fair market value",
          descriptionL:
            "You can get a fair value for your re-marketable assets and receive detailed reports on your devices.",
          logo: Icon3,
        },
        {
          title: "Donate to a charity of your choice",
          descriptionL:
            "After securely wiping the data of your devices, we can refurbish and donate your old IT assets to support a charity of your choice, giving the device a purposeful second life.",
          logo: Icon4,
        },
      ],
      source: [
        "Sustainable IT, Capgemini Research",
        "Lenovo ESG Report FY2022/23",
      ],
    },

    followUpResponse: "Would you like to purchase these services ?",
    boatAcknowledged:
      "Sure, here are some additional services that will go well with you purchase request. ",
  },
  {
    question: "Yes, I would like to proceed with your recommendations.",
    hasTableType: false,
    followUpSuggestions: [
      "Yes, I would like to proceed with your recommendation.",
      "Can you give a final costing for the 10 laptops ?",
      "What other device options do you provide?",
    ],
    mainRespone: {
      firstSection: "",
      secondSection: [],
    },
    followUpResponse: "",
    boatAcknowledged:
      "Please enter your email id and submit we will get back to you over email with the details of our conversation and take it forward.",
  },
  {
    question: "Useremail@gmail.com",
    hasTableType: false,
    followUpSuggestions: [
      "Yes,I would like to proceed with DaaS Service",
      "I would like to proceed with the regular service",
      "Please tell me about your other serices.",
    ],
    mainRespone: {
      firstSection: "",
      secondSection: [],
    },
    followUpResponse: "Would you need any other details ?",
    boatAcknowledged:
      "Your Email ID Useremail@gmail.com has been added. Our team will get back to you on this.",
  },
];

const chatData4 = [
  {
    question: "Can you get my purchase history?",
    boatAcknowledged:
      "Sure, here is the list with your purchase history. I have also highlighted their associated sustainability parameters.",
    hasTableType: true,
    followUpSuggestions: [
      "Yes, let me know more about TruScale DaaS and its advantages.",
      "Can you recommend some products to upgrade?",
      "What other services does Lenovo provide",
    ],
    tableResponse: {
      firstSection: ["Product", "PCF (Kg CO2e)"],
      secondSection: [
        {
          column1: "THINKPAD T14 GEN3 AMD 21CF",
          column2: "218",
        },
        {
          column1: "THINKPAD X13 GEN3 AMD 21CM",
          column2: "197",
        },
      ],
    },
    followUpResponse:
      "If you would like to see more sustainable ways to purchase these products, you can switch your purchase model to TruScale DaaS. Would you like to explore Lenovo TruScale DaaS and its advantages?",
  },

  {
    question: "Yes, let me know more about DaaS and its advantages.",
    hasTableType: false,
    followUpSuggestions: [
      "Yes, I would like to know more about Bulk packaging.",
      "Can you give a final costing",
      " I would like to proceed with the regular service.",
    ],
    mainRespone: {
      firstSection: "",
      secondSection: [
        {
          title: "Lifecycle management",
          descriptionL:
            "Give your employees the latest technology without worrying about support and maintenance.",
          logo: Icon,
        },
        {
          title: "Pay-as-you-go model",
          descriptionL:
            "Pay for what you use. It inserts predictability and gives you financial flexibility to scale as per your needs.",
          logo: pay,
        },
        {
          title: "Customizable services",
          descriptionL:
            "You can pick the services that suit your needs, such as configuration, security, updates, recovery, and more.",
          logo: hardwareReuse,
        },
        {
          title: "Sustainability and circularity",
          descriptionL:
            "Reduce your environmental impact by avoiding waste, extending device life, and recycling old equipment. Lenovo TruScale also helps you optimize your resources and costs.",
          logo: Icon3,
        },
      ],
    },
    mainRespone1: {
      firstSection:
        "With as a Service usage models you can see further sustainable impacts like,",
      secondSection: [
        {
          title: "Up to 30% reduction",
          descriptionL:
            "In end-user device emissions by optimizing the number of devices.",
          logo: co2Cloud,
        },
        {
          title: "Up to 35% potential reduction",
          descriptionL:
            "in device related IT costs with Lenovo TruScale DaaS².",
          logo: savings,
        },
      ],
      source: [
        "The Green IT Revolution – McKinsey Report.",
        "Lenovo TruScale DaaS ROI Tool. Actual customer savings may vary.",
      ],
    },

    followUpResponse:
      "If you would like to see more sustainable ways to purchase these products, you can learn more about our Bulk packaging services. Would you like to explore our Bulk packaging ?",
    boatAcknowledged:
      "Lenovo TruScale Device as a Service offers an efficient way to manage your fleet of end-user devices. Deploy just what you need and responsibly dispose of what you don’t, Lenovo takes care of everything. Some features are:",
  },
  {
    question: "Yes, I would like to know more about Bulk packaging.",
    hasTableType: true,
    hasPoints: true,
    followUpSuggestions: [
      "Yes, I would like to know more about the mode of transportation impact.",
      "No I don't need any additional services.",
      "Let’s move on to the final costing.",
    ],
    mainRespone: {
      // firstSection:
      //   "Considering 50 laptops being shipping from Hefei to Copenhagen.",
      secondSection: [
        {
          title: "Packaging:",
          descriptionL:
            "Increasing the use of recycled and renewable materials in packaging.",
          logo: Icon8,
        },
        {
          title: "Bio Material:",
          descriptionL: "Increasing the use of bio-based materials.",
          logo: Icon6,
        },
        {
          title: "Recycling and reuse:",
          descriptionL: "Reducing the size of product packaging.",
          logo: recycle,
        },
        {
          title: "Reducing packaging waste:",
          descriptionL:
            "Expanding the use of bulk and reusable packaging solutions.",
          logo: Icon3,
        },
      ],
    },
    pointsResponse: {
      firstSection:
        "Considering 1000 laptops being shipping from China to Sydney.",
      secondSection: [
        {
          title: "Normal Packaging:",
          descriptionL:
            "Increasing the use of recycled and renewable materials in packaging.",
          points: [
            "1000 x T14 Gen3, Gwt: 2.40 kg",
            "1000 × X13 Gen3, Gwt: 2.00 kg",
            "Total 4.40 tons of cargo",
          ],
          logo: Icon8,
        },
        {
          title: "Bulk Packaging:",
          descriptionL:
            "Increasing the use of recycled and renewable materials in packaging.",
          points: [
            "1000 x T14 Gen3, Gwt: 2.05 kg",
            "1000 × X13 Gen3, Gwt: 1.73 kg",
            "Total 3.78 tons of cargo",
          ],
          logo: Icon8,
        },
      ],
    },

    tableResponse: {
      desc: "Total GHG emissions CO2e (WTW) in tons.",
      firstSection: [
        "",
        "Total Cargo (Tons)",
        "Sea Freight  + Truck",
        "Air Freight + Truck",
      ],
      secondSection: [
        {
          column1: "Single boxes",
          column2: "4.4",
          column3: "1.133",
          column4: "43.626",
        },
        {
          column1: "Bulk packaging",
          column2: "3.78",
          column3: "0.973",
          column4: "37.479",
        },

        {
          column1: "Total tons saved (%)",
          column2: "",
          column3: "~10%",
          column4: "~10%",
        },
      ],
    },
    mainRespone1: {
      secondSection: [
        {
          title: "~10% reduction in CO2 Emissions",
          descriptionL: "Just by choosing bulk packaging.",
          logo: co2Cloud,
        },
        {
          title: "620 Kg",
          descriptionL: "Of packaging waste saved",
          logo: Icon8,
        },
      ],
    },
    followUpResponse:
      "Furthermore we have options on how we ship packages, You can consider choosing a mode of transport with lower carbon footprint to further reduce your emissions. Would you like to know more about it ?",
    boatAcknowledged:
      "Lenovo’s intent with bulk packaging is to minimize the amount of packaging materials used while maintaining adequate protection for our products. Lenovo’s bulk packaging priorities focus on reducing our packaging waste, and thereby reducing carbon emissions during transit:",
  },
  {
    question:
      "Yes, I would like to know more about the mode of transportation impact.",
    hasTableType: true,
    followUpSuggestions: [
      "Yes, I would like to know more about CO2 Offset services.",
      "Can you give a final costing for the 1000 laptops ?",
      "No, I would like to purchase them without any additional services.",
    ],

    mainRespone1: {
      firstSection:
        "By choosing Reduced Carbon Transport Service on the above sample shipment, you will reduce ~122.2 Tons of CO2e which is equivalent to:",
      secondSection: [
        {
          title: "313,266 miles",
          descriptionL:
            "The distance an average gasoline-powered passenger car would drive to emit the same amount of GHG emissions as this activity¹.",
          logo: fuel,
        },
        {
          title: "2017 tree seedlings",
          descriptionL:
            "Tree seedlings that grow for 10 years can sequester the same amount of carbon as this activity¹.",
          logo: tree,
        },
        {
          title: "Mimimum 70% CO2 emissions reduction²",
          descriptionL:
            "Through the purchase of Sustainable Aviation Fuel credits.",
          logo: co2Cloud,
        },
      ],
      source: [
        " GHG Equivalencies Calculator, EPA.",
        " Approximate percentage LCA GHG reduction versus fossil jet based on feedstock used in SAF such as waste and residue lipids, oilseed bearing trees on low-ILUC degraded land or as a rotational oil cover crop. Indirect CO2 emissions reduction benefit is obtained through the purchase of Sustainable Aviation Fuel credits.",
      ],
    },
    tableResponse: {
      desc: "Consider the same sample  shipment of ~19,400 kg from Hefei, China to Denmark :",
      firstSection: [
        "Total Order Weight (kg)",
        "Amount of Sustainable Aviation Fuel Claimed",
        "Total Scope 3 Emissions Reduced (Tons)",
      ],
      secondSection: [
        {
          column1: "19,468.18 kg",
          column2: "44,524 Liters",
          column3: "122.2 Tons CO2",
        },
      ],
    },
    hasTableType2: true,
    //wrong Data
    tableResponse2: {
      desc: "Assuming a sample shipment of ~ 19,400 Kg from Hefei, China to Denmark, Sweden, and Finland, I’ve created a comparison between Sea and Air freight and also highlighted their associated sustainability parameters.",
      desc2:
        "Can’t afford to wait but still want a lower-carbon option to deliver your IT purchases?  You can opt for our Reduced Carbon Transport Service.",
      desc3:
        "Lenovo Reduced Carbon Transport service gives you the option to ship your IT devices via air with lower-carbon impact. This is made possible with Sustainable Aviation Fuel (SAF) credits and allocating them to your new IT purchases. Additionally, you may report the resulting CO2 emissions reduction from airfreight against your own Scope 3 emissions​.",
      firstSection: [
        "Origin",
        "Destination",
        "Sea Freight (MT CO2)",
        "Air Freight (MT CO2)",
        "Potential savings (%)",
      ],
      secondSection: [
        {
          column1: "Hefei",
          column2: "Finland",
          column3: "3.34",
          column4: "104.86",
          column5: "90%",
        },
        {
          column1: "Hefei",
          column2: "Sweden",
          column3: "3.20",
          column4: "120.01",
          column5: "90%",
        },
        {
          column1: "Hefei",
          column2: "Denmark",
          column3: "3.36",
          column4: "110.25",
          column5: "90%",
        },
      ],
    },
    followUpResponse:
      "Furthermore, our CO2 offset services can help you offset carbon against the devices you are purchasing. Would you like to know about our CO2 Offset Services?",
    boatAcknowledged:
      "Lenovo offers different options to deliver your IT devices. Here's a quick look at the comparison of the different modes of transportation: ",
  },

  {
    question:
      "Can you give details for CO2 Offset Services for the 1000 Lenovo laptops?",
    hasTableType: true,
    followUpSuggestions: [
      "Yes, I would like to know more about Asset Recovery services.",
      "Can you give a final costing for the 1000 laptops ?",
      "What other device options do you provide?",
    ],

    mainRespone: {
      firstSection:
        "Lenovo CO2 Offset Services helps offset emissions across the average lifecycle from production, shipping, usage and end of life by supporting global climate action projects. Some features are:",
      secondSection: [
        {
          title: "Easy and Convenient",
          descriptionL:
            "Offset the carbon cost for every hardware transaction, old and new, at point of purchase. ",
          logo: rightIdea,
        },
        {
          title: "Transparent and Verifiable",
          descriptionL:
            "View details of the Environmental project that your offset supports through your hardware serial number. You can also download a confirmation of offsets.",
          logo: ringsConnect,
        },
        {
          title: "Meaningful and Sustainable",
          descriptionL:
            "Your offsets support climate action projects verified by United Nations, Gold Standard®, and Climate Action Reserve, so you can trust the integrity of the climate action projects that your offsets support.",
          logo: Icon6,
        },
      ],
    },
    mainRespone1: {
      firstSection:
        "If you choose to add CO2 Offset Services to  your IT purchase of 10 devices  you will offset 200 metric tons of CO2e, which is equivalent to :",
      secondSection: [
        {
          title: "97 Homes",
          descriptionL: "Electricity used for one year.",
          logo: thunder,
        },
        {
          title: "1,281,774 Miles",
          descriptionL:
            "Is the distance that an average gasoline-powered passenger car would drive to emit the same amount of GHG emissions as this activity.",
          logo: fuel,
        },
        {
          title: "8,268 tree seedlings",
          descriptionL:
            "That grow for 10 years can sequester the same amount of carbon as this activity.",
          logo: tree,
        },
        // {
        //   title: "Tangible Scope 3 emission",
        //   descriptionL:
        //     "Reductions made possible with Lenovo Reduced Carbon Transport Service. By using Sustainable Aviation Fuel (SAF) credits for air shipping.",
        //   logo: Icon6,
        // },
      ],
      source: ["GHG Equivalencies Calculator, EPA"],
    },
    tableResponse: {
      firstSection: [
        "Product",
        "PCF (Kg CO2e) ",
        "CO2 Offsets for purchase (In Tons)",
        "Quantity of devices",
        "Total CO2 Offsets  for purchase (Tons)",
      ],
      secondSection: [
        {
          column1: "THINKPAD T14 GEN3 AMD 21CF",
          column2: "218",
          column3: "0.5",
          column4: "500",
          column5: "250",
        },
        {
          column1: "THINKPAD X13 GEN3 AMD 21CM",
          column2: "197",
          column3: "0.5",
          column4: "500",
          column5: "250",
        },
      ],
    },

    followUpResponse:
      "If you would like to see more sustainable solutions, you can learn more about our Asset Recovery Services which can help recycle your old hardware. Would you like to know more ?",
    boatAcknowledged:
      "Lenovo CO2 Offset Services helps offset emissions across the average lifecycle from production, shipping, usage and end of life by supporting global climate action projects.. Some features are:",
  },

  {
    question: "Yes, I would like know more about your Asset Recovery services.",
    hasTableType: false,
    followUpSuggestions: [
      "Yes, I would like to proceed with the recommended solutions .",
      "Can you give a final costing for the 1000 laptops ?",
      "What other device options do you provide?",
    ],

    mainRespone: {
      firstSection:
        "Lenovo Asset Recovery Services help mitigate end-of-life IT and data security risks by refurbishing and recycling your old IT assets in an environmentally conscious way.  Below are some features:",
      secondSection: [
        {
          title: "Data sanitization and disk destruction",
          descriptionL:
            "We can erase or destroy your data according to NIST 800-88 standards.",
          logo: Icon,
        },
        {
          title: "Pickup and Transport",
          descriptionL:
            "We can arrange to collect and transport your devices from your location.",
          logo: truck,
        },
        {
          title: "Recycling and Reuse",
          descriptionL:
            "You can lower your environmental impact by recycling or reusing your devices or parts and support a circular economy.",
          logo: hardwareReuse,
        },
        {
          title: "Fair market value",
          descriptionL:
            "You can get a fair value for your re-marketable assets and receive detailed reports on your devices.",
          logo: Icon3,
        },
        {
          title: " Donate to a charity of your choice",
          descriptionL:
            "After securely wiping the data of your devices, we can refurbish and donate your old IT assets to support a charity of your choice, giving the device a purposeful second life.",
          logo: Icon4,
        },
        {
          title: "89% of organisations",
          descriptionL:
            "Recycle less than 10% of their IT hardware¹. We can help you change that.",
          logo: recycle,
        },
        {
          title: "31,532 Metric Tons",
          descriptionL:
            "products were collected for reuse and recycling through Lenovo ARS and product take-back programs in 2022. This is the equivalent of 3 times the weight of an Eiffel Tower.",
          logo: Icon6,
        },
      ],
      source: [
        "Sustainable IT, Capgemini Research",
        "Lenovo ESG Report FY2022/23",
      ],
    },

    followUpResponse:
      "Would you like to proceed with any of these services ? I can help you with more information and help clear doubts, if you have any.",
    boatAcknowledged:
      "Lenovo Asset Recovery Services help mitigate end-of-life IT and data security risks by refurbishing and recycling your old IT assets in an environmentally conscious way.  Below are some features:",
  },
  {
    question: "Yes, I would like to proceed with the recommended solutions .",
    hasTableType: false,
    followUpSuggestions: [
      "Yes, I would like to proceed with your recommendation.",
      "Can you give a final costing for the 1000 laptops ?",
      "What other device options do you provide?",
    ],
    mainRespone: {
      firstSection: "",
      secondSection: [],
    },
    followUpResponse: "",
    boatAcknowledged:
      "Please enter your email id and submit we will get back to you over email with the details of our conversation and take it forward.",
  },
  {
    question: "Useremail@gmail.com",
    hasTableType: false,
    followUpSuggestions: [
      "Yes,I would like to proceed with DaaS Service",
      "I would like to proceed with the regular service",
      "Please tell me about your other serices.",
    ],
    mainRespone: {
      firstSection: "",
      secondSection: [],
    },
    followUpResponse: "Would you need any other details ?",
    boatAcknowledged:
      "Your Email ID Useremail@gmail.com has been added. Our team will get back to you on this.",
  },
];

const chatDataOld = [
  // {
  //   question: "Can you get my purchase history?",
  //   hasTableType: true,
  //   followUpSuggestions: [
  //     "Yes let me more about DaaS and it’s advantages ",
  //     "Can you recommend some replacement products ?",
  //     "What other services does Lenovo provide ?",
  //   ],
  //   mainRespone: {
  //     firstSection: [
  //       "Product",
  //       "Quantity",
  //       "Product Carbon Footprint",
  //       "Total Carbon Savings",
  //     ],
  //     secondSection: [
  //       {
  //         column1: "ThinkPad T10",
  //         column2: "300",
  //         column3: "10",
  //         column4: "3%",
  //       },
  //       { column1: "Tru laaS", column2: "100", column3: "8", column4: "12%" },
  //     ],
  //   },
  //   followUpResponse:
  //     "If you would like see more sustainable ways to purchase these product you can switch your purchase model to DaaS . Would you like to explore DaaS and it’s advantages ?",
  //   boatAcknowledged:
  //     "Sure, Here is the table with your purchase history. I have also highlighted their associated sustainability parameters.",
  // },
  // {
  //   question: "Yes let me more about DaaS and it’s advantages ",
  //   hasTableType: false,
  //   followUpSuggestions: [
  //     "Yes, I would like know more about your Carbon Offset services",
  //     "Can you give a final costing for the DaaS package ",
  //     "I would want to proceed with the regular service. ",
  //   ],
  //   mainRespone: {
  //     firstSection:
  //       "Lenovo TrueScale is a DaaS model that aligns user experience with business goals. It offers flexible, transparent, and customisable services, and reduces costs, resources, and e-waste. Lenovo handles everything from provisioning to returns.",
  //     secondSection: [
  //       {
  //         title: "Lifecycle management:",
  //         descriptionL:
  //           "You can give your employees the latest technology without worrying about maintenance. Lenovo takes care of everything.",
  //       },
  //       {
  //         title: "Pay-as-you-go model:",
  //         descriptionL:
  //           "You only pay for what you use, with clear and predictable pricing. You can also scale up or down as needed, without extra investment or waste.",
  //       },
  //       {
  //         title: "Sustainability and circularity:",
  //         descriptionL:
  //           "You can lower your environmental impact by avoiding e-waste, extending device life, and recycling old equipment. Lenovo TruScale DaaS also helps you optimize your resources and costs.",
  //       },
  //     ],
  //   },
  //   followUpResponse:
  //     "If you would like see more sustainable ways to purchase these product you can learn more about our Carbon Offset Services Would you like to explore about our Carbon Offset Services ?",
  //   boatAcknowledged:
  //     "Sure, Here is the table with your purchase history. I have also highlighted their associated sustainability parameters.",
  // },
  {
    question: "Can you get my purchase history?",
    hasTableType: true,
    followUpSuggestions: [
      "Yes, tell me more about sustainable bulk packaging",
      "Can you give a final costing for the 1000 laptops ?",
      "What other device options do you provide?",
    ],
    tableResponse: {
      firstSection: ["Product", "PCF (Kg CO2e) "],
      secondSection: [
        {
          column1: "THINKPAD T14 GEN3 AMD 21CF",
          column2: "218",
        },
        { column1: "THINKPAD X13 GEN3 AMD 21CM", column2: "179" },
      ],
    },
    followUpResponse:
      "If you would like to see more sustainable ways to purchase these products, you can learn more about our Bulk packaging services.",
    boatAcknowledged:
      "Sure, Here is the table with your purchase history. I have also highlighted their associated sustainability parameters.",
  },
  {
    question: "Yes, tell me more about sustainable bulk packaging",
    hasTableType: true,
    hasPoints: true,
    followUpSuggestions: [
      "Yes, I would like to know more about the mode of transportation impact.",
      "Can you give a final costing for the 1000 laptops ?",
      "What other device options do you provide ?",
    ],
    pointsResponse: {
      firstSection:
        "Considering 1000 laptops being shipping from China to Mexico City, MX.",
      secondSection: [
        {
          title: "Normal Packaging:",
          descriptionL:
            "Increasing the use of recycled and renewable materials in packaging.",
          points: [
            "1000 x T14 Gen3, Gwt: 2.40 kg",
            "1000 × X13 Gen3, Gwt: 2.00 kg",
            "Total 4.40 tons of cargo",
          ],
        },
        {
          title: "Bulk Packaging:",
          descriptionL:
            "Increasing the use of recycled and renewable materials in packaging.",
          points: [
            "1000 x T14 Gen3, Gwt: 2.05 kg",
            "1000 × X13 Gen3, Gwt: 1.73 kg",
            "Total 3.78 tons of cargo",
            "Total GHG emissions CO2e (WTW) in tons",
          ],
        },
      ],
    },
    mainRespone: {
      firstSection:
        "Considering 1000 laptops being shipping from China to Mexico City, MX.",
      secondSection: [
        {
          title: "Packaging:",
          descriptionL:
            "Increasing the use of recycled and renewable materials in packaging.",
          // points: [
          // "1000 x T14 Gen3, Gwt: 2.40 kg",
          // "1000 × X13 Gen3, Gwt: 2.00 kg",
          // "Total 4.40 tons of cargo",
          // ],
        },
        {
          title: "Bio Material:",
          descriptionL: "Increasing the use of bio-based materials.",
          // points: [
          //   "1000 x T14 Gen3, Gwt: 2.05 kg",
          //   "1000 × X13 Gen3, Gwt: 1.73 kg",
          //   "Total 3.78 tons of cargo",
          //   "Total GHG emissions CO2e (WTW) in tons",
          // ],
        },
        {
          title: "Recycling and reuse:",
          descriptionL: "Reducing the size of product packaging.",
          // points: [
          //   "1000 x T14 Gen3, Gwt: 2.05 kg",
          //   "1000 × X13 Gen3, Gwt: 1.73 kg",
          //   "Total 3.78 tons of cargo",
          //   "Total GHG emissions CO2e (WTW) in tons",
          // ],
        },
        {
          title: "Value recovery and reporting:",
          descriptionL:
            "Expanding the use of bulk and reusable packaging solutions.",
          // points: [
          //   "1000 x T14 Gen3, Gwt: 2.05 kg",
          //   "1000 × X13 Gen3, Gwt: 1.73 kg",
          //   "Total 3.78 tons of cargo",
          //   "Total GHG emissions CO2e (WTW) in tons",
          // ],
        },
      ],
    },
    tableResponse: {
      firstSection: [
        "",
        "Total Cargo (Tons)",
        "Sea Freight  + Truck",
        "Air Freight + Truck",
      ],
      secondSection: [
        {
          column1: "Single boxes",
          column2: "4.4",
          column3: "1.133",
          column4: "43.626",
        },
        {
          column1: "Bulk packaging",
          column2: "3.78",
          column3: ".973",
          column4: "37.479",
        },

        {
          column1: "Total tons saved (%)",
          column2: "322",
          column3: "-10%",
          column4: "-10%",
        },
      ],
    },
    followUpResponse:
      "~10% reduction in CO2 emissions just by choosing bulk packaging. 620 kg of packaging waste saved. Furthermore we have options on how we ship packages. You can consider choosing a mode of transport with lower carbon footprint to further reduce your emissions.  Would you like to know more about it ?",
    boatAcknowledged:
      "Lenovo’s intent is on reducing the size of our packaging to minimize the materials used while maintaining adequate protection for our products. Lenovo’s packaging priorities focus on reducing our packaging consumption, waste, and carbon emissions levels by:",
  },
  // {
  //   question: "Yes, I would like know more about your Carbon Offset services",
  //   hasTableType: false,
  //   followUpSuggestions: [
  //     "Yes, tell me more about mode of transportation impact.",
  //     "I would want to proceed with the DaaS service.",
  //     "I would want to proceed with the regular service.",
  //   ],
  //   mainRespone: {
  //     firstSection:
  //       "Lenovo CO2 Offset Services is a service that reduces your Lenovo devices' environmental impact by supporting global climate projects. It offsets your device's carbon emissions from production, shipping, and usage by investing in projects that lower or prevent greenhouse gas emissions. Some features are:",
  //     secondSection: [
  //       {
  //         title: "Easy and convenient:",
  //         descriptionL:
  //           "You can join the service when you buy your device, and pay a fixed amount based on your device's emissions. You don't need to do anything else.",
  //       },
  //       {
  //         title: "Transparent and verifiable:",
  //         descriptionL:
  //           "You can see the project details and download a certificate by entering your device's serial number on the Lenovo website. All the projects are verified by independent organizations like UN, CDM, Gold Standard®, etc",
  //       },
  //       {
  //         title: "Meaningful and sustainable:",
  //         descriptionL:
  //           "You can pick from projects that benefit the environment and the communities, such as wind power in India, forest conservation in Peru, and biogas in China. You can also help Lenovo achieve its sustainability goals and vision of smarter technology.",
  //       },
  //     ],
  //   },
  //   followUpResponse:
  //     "If you would like see more sustainable ways to purchase these product you can learn more about our Carbon Offset Services Would you like to explore about our Carbon Offset Services ?",
  //   boatAcknowledged: "Sure, Let me get the Carbon Offset services",
  // },
  {
    question: "Yes, tell me more about mode of transportation impact.",
    hasTableType: true,
    followUpSuggestions: [
      "I would like to proceed with the regular service",
      "Please tell me about your other serices.",
      "Yes,I would like to proceed with DaaS Service",
    ],
    tableResponse: {
      firstSection: [
        "Product",
        "Quantity",
        "Product Carbon Footprint",
        "Total Carbon Savings",
      ],
      secondSection: [
        {
          column1: "ThinkPad T10",
          column2: "300",
          column3: "10",
          column4: "3%",
        },
        { column1: "Tru laaS", column2: "100", column3: "8", column4: "12%" },
        {
          column1: "MoT Air Carbon Footprint",
          column2: "100",
          column3: "8",
          column4: "12%",
        },
        {
          column1: "MoT Sea Carbon ",
          column2: "100",
          column3: "8",
          column4: "12%",
        },
      ],
    },
    followUpResponse:
      "By availing your current purchases through DaaS Service with Mot Air will have a Carbon equivalent impact: \n GHG emissions from 2,563,549 miles driven by an average gasoline-powered passenger vehicle \n Carbon sequestered by 16,535 tree seedlings grown for 10 years \n Would you like to proceed to opt for this service ?",
    boatAcknowledged:
      "Sure, Here is the table with your purchase history. I have also highlighted their associated sustainability parameters.",
  },
  {
    question: "Yes,I would like to proceed with DaaS Service",
    hasTableType: false,
    followUpSuggestions: [
      "Yes,I would like to proceed with DaaS Service",
      "I would like to proceed with the regular service",
      "Please tell me about your other serices.",
    ],
    mainRespone: {
      firstSection: "",
      secondSection: [],
    },
    followUpResponse: "",
    boatAcknowledged:
      "Great, Please enter your email id and submit we will get back to you over email with the details of our conversation and take it forward.",
  },
  {
    question: "Useremail@gmail.com",
    hasTableType: false,
    followUpSuggestions: [
      "Yes,I would like to proceed with DaaS Service",
      "I would like to proceed with the regular service",
      "Please tell me about your other serices.",
    ],
    mainRespone: {
      firstSection: "",
      secondSection: [],
    },
    followUpResponse: "Would you need any other details ?",
    boatAcknowledged:
      "Your Email ID Useremail@gmail.com has been added. Our team will get back to you on this.",
  },
];
function useChatData() {
  const { askMessage, newOrganization } = useSelector(
    (state) => state.esgGptReducer
  );
  const persona = () => {
    if (newOrganization?._id === 0) return chatData;
    if (newOrganization?._id === 1) return chatData2;
    if (newOrganization?._id === 2) return chatData3;
    if (newOrganization?._id === 3) return chatData4;
    return null;
  };

  // const [chatData, setChatData] = React.useState(data);

  // React.useEffect(() => {
  //   if (askMessage) {
  //     setChatData((preValue) =>
  //       preValue.map((c, i) =>
  //         i === 5
  //           ? {
  //               ...c,
  //               question: askMessage,
  //               boatAcknowledged: `Your Email ID ${askMessage} has been added. Our team will get back to you on this.`,
  //             }
  //           : c
  //       )
  //     );
  //   }
  // }, [askMessage]);

  // console.log(askMessage);
  return { chatData: persona() };
}

export default useChatData;
