import React from "react";

import { Paper } from "@material-ui/core";

import EsgGptlayout from "../EsgGptlayout";
import EsgGptChatField from "../EsgGptChatField";

import { ModifiedList } from "../esgGptAddNewCompany/EsgGptUpdatedList";
import analytics from "../../../../images/Analytics.png";
import { ChatPlayGround } from "../esgGptCompanyProfile/EsgGptCompanyRight";

function EsgGptChat() {
  const [chatInitiate, setChatInitiate] = React.useState(false);
  const handleChat = () => {
    setChatInitiate(true);
  };
  return (
    <EsgGptlayout EsgCompanyList={<ModifiedList />}>
      <Paper
        style={{
          height: "73vh",
          width: "100%",
          overflow: "scroll",
          marginBottom: "3%",
        }}
        elevation={0}
      >
        {!chatInitiate && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "60%",
            }}
          >
            <img src={analytics} alt="analytics" />
          </div>
        )}
        <div style={{ marginTop: "10px" }} onClick={handleChat}>
          <ChatPlayGround />
        </div>
      </Paper>

      <EsgGptChatField />
    </EsgGptlayout>
  );
}

export default EsgGptChat;
