import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import blueIcon from "../../../images/IconBlue.svg";
import greenIcon from "../../../images/IconGreen.svg";
import purpuleIcon from "../../../images/IconPurpule.svg";
import yellowIcon from "../../../images/IconYellow.svg";
import orangeIcon from "../../../images/IconOrange.svg";
import { useTheme } from "@material-ui/core";
import { useSelector } from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend);

// const greenColor = "rgba(0, 135, 108, 1)";
// const purpuleColor = "rgba(131, 41, 163, 1)";
// const blueColor = "rgba(41, 100, 163, 1)";
// const orangeColor = "rgba(214, 153, 92, 1)";
// const yellowColor = "rgba(235, 217, 71, 1)";

const greenColor = "rgba(106, 195, 70, 1)";
const purpuleColor = "rgba(130, 70, 175, 1)";
const blueColor = "rgba(255, 118, 117, 1)";
const orangeColor = "rgba(255, 106, 0, 1)";
const yellowColor = "rgba(255, 180, 61, 1)";

const colorArr = [
  greenColor,
  greenColor,
  greenColor,
  greenColor,
  purpuleColor,
  purpuleColor,
  purpuleColor,
  purpuleColor,
  blueColor,
  blueColor,
  blueColor,
  blueColor,
  orangeColor,
  orangeColor,
  orangeColor,
  orangeColor,
  yellowColor,
  yellowColor,
  yellowColor,
  yellowColor,
];

export const dataOuter = {
  labels: [],
  datasets: [
    {
      label: "# of Votes",
      data: [3, 4, 5, 6, 3, 4, 5, 6, 3, 4, 5, 6, 3, 4, 5, 6, 3, 4, 5, 6],
      backgroundColor: colorArr,
      borderColor: colorArr,
      borderWidth: 1,
      borderRadius: 4,
      borderJoinStyle: "bevel",
      spacing: 30,
    },
  ],
};

export const dataInner = {
  labels: [],
  datasets: [
    {
      label: "# of Votes",
      data: [18, 18, 17, 18, 18],

      backgroundColor: [
        "rgba(10, 10, 10, 0)",
        "rgba(10, 10, 10, 0)",
        "rgba(10, 10, 10, 0)",
        "rgba(10, 10, 10, 0)",
        "rgba(10, 10, 10, 0)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 0)",
        "rgba(54, 162, 235, 0)",
        "rgba(255, 206, 86, 0)",
        "rgba(75, 192, 192, 0)",
        "rgba(153, 102, 255, 0)",
      ],
      borderWidth: 1,
      borderRadius: 40,
    },
  ],
};

const optionsOuter = {
  cutout: "65%",
  plugins: {
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
  },
};

const optionsInner = {
  cutout: "70%",

  plugins: {
    tooltip: {
      enabled: false,
    },
    labels: {
      render: "label",
    },
    legend: {
      display: false,
    },
  },
};

// const textCenterTitle = {
//   id: "textCenterTitlee",
//   beforeDatasetDraw(chart, args, pluginoptiopns) {
//     const { ctx, data } = chart;

//     ctx.save();
//     ctx.font = "bolder 15px sans-serif";
//     ctx.fillStyle = "rgba(21, 67, 40, 0.7)";
//     ctx.textAlign = "center";
//     ctx.marginBottom = "5px";
//     ctx.fillText(
//       "ESG Score",
//       chart.getDatasetMeta(0).data[0].x,
//       chart.getDatasetMeta(0).data[0].y
//     );
//   },
// };

// const textCenterValue = {
//   id: "textCenterValue",
//   beforeDatasetDraw(chart, args, pluginoptiopns) {
//     const { ctx, data } = chart;

//     ctx.save();
//     ctx.fillStyle = "rgba(21, 67, 40, 1)";
//     ctx.textAlign = "center";
//     ctx.fillText(
//       `${54} / 100`,
//       chart.getDatasetMeta(0).data[0].x,
//       chart.getDatasetMeta(0).data[0].y + 35
//     );
//   },
// };

const imageURLs = [greenIcon, purpuleIcon, blueIcon, orangeIcon, yellowIcon];
const images = imageURLs.map((v) => {
  var image = new Image();
  image.src = v;
  return image;
});

const dounutLabelImage = {
  id: "dounutLabelImage",
  afterDraw(chart, args, options) {
    const {
      ctx,
      chartArea: { top, bottom, left, right, width, height },
    } = chart;

    chart.data.datasets.forEach((datasets, i) => {
      chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
        const { x, y } = datapoint.tooltipPosition();
        ctx.translate(x, y);
        // (right/left,top/bott)
        index === 0 && ctx.drawImage(images[0], -15, -20); //env
        index === 1 && ctx.drawImage(images[1], -30, -30); //soc
        index === 2 && ctx.drawImage(images[2], -40, -30); // gov
        index === 3 && ctx.drawImage(images[3], -30, -20);
        index === 4 && ctx.drawImage(images[4], -40, -20);

        ctx.translate(-x, -y);
        ctx.restore();
      });
    });
  },
};

function ScoreChart({ scores }) {
  const theme = useTheme();

  const textCenterTitle = {
    id: "textCenterTitlee",
    beforeDatasetDraw(chart, args, pluginoptiopns) {
      const { ctx, data } = chart;

      ctx.save();
      ctx.font = "bolder 15px sans-serif";
      ctx.fillStyle = theme.palette.primary.main;
      ctx.textAlign = "center";
      ctx.marginBottom = "5px";
      ctx.fillText(
        "ESG Score",
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y
      );
    },
  };

  const textCenterValue = {
    id: "textCenterValue",
    beforeDatasetDraw(chart, args, pluginoptiopns) {
      const { ctx, data } = chart;

      ctx.save();
      ctx.fillStyle = theme.palette.primary.main;
      ctx.textAlign = "center";
      ctx.fillText(
        `${54} / 100`,
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y + 35
      );
    },
  };
  // const dataOuter = {
  //   labels: [
  //     ...Object.keys(scores.scoreObject.environment.data_points),
  //     ...Object.keys(scores.scoreObject.social.data_points),
  //     ...Object.keys(scores.scoreObject.governance.data_points),
  //   ],
  //   datasets: [
  //     {
  //       label: "# of Votes",
  //       data: [
  //         ...Object.values(scores.scoreObject.environment.data_points),
  //         ...Object.values(scores.scoreObject.social.data_points),
  //         ...Object.values(scores.scoreObject.governance.data_points),
  //       ],
  //       backgroundColor: colorArr,
  //       borderColor: colorArr,
  //       borderWidth: 1,
  //       borderRadius: 4,
  //       borderJoinStyle: "bevel",
  //       spacing: 30,
  //     },
  //   ],
  // };

  return (
    <div
      style={{
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ height: "21vh", width: "28vw" }}>
        <Doughnut
          options={optionsOuter}
          // height="400px"
          // width="400px"
          data={dataOuter}
        />
      </div>
      <div
        style={{
          height: "40vh",
          width: "16vw",
          // zIndex:100
          // alignSelf:"flex-start"
          marginTop: "-9vh",
        }}
      >
        <Doughnut
          redraw={theme}
          options={optionsInner}
          // height="250px"
          // width="280px"
          data={dataInner}
          plugins={[textCenterTitle, textCenterValue, dounutLabelImage]}
        />
      </div>
    </div>
  );
}

export default ScoreChart;
