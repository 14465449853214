import React from "react";

import { Grid, Paper } from "@material-ui/core";

import EsgGptMainRight from "./EsgMainRight";
import CustomAppBar from "../../UI/appBar/appBar";
import EsgGptMainLeft from "./EsgMainLeft";
import EsgMOreBUttons from "../../widgets/esgGpt/EsgGptMoreBUtton";

function EsgGptMain() {
  return (
    <Grid container component={Paper} elevation={0} style={{ height: "100%" }}>
      <Grid item md={12} sm={12}>
        <CustomAppBar logOut={false} isOpen={true} />
      </Grid>
      <Grid item md={12} sm={12}>
        <EsgGptMainRight />
      </Grid>
      {/* <EsgGptMainLeft width={100}>
        <EsgMOreBUttons />
      </EsgGptMainLeft> */}
    </Grid>
  );
}

export default EsgGptMain;
