import React from "react";

import EsgGptlayout from "../EsgGptlayout";
import EsgGptUpdatedList from "./EsgGptUpdatedList";
import EsgGptCompanyForm from "./EsgGptCompanyForm";


function EsgGptAddNewCompany() {
  return (
    <EsgGptlayout EsgCompanyList={<EsgGptUpdatedList />}>
      <EsgGptCompanyForm/>
    </EsgGptlayout>
  );
}

export default EsgGptAddNewCompany;
