import React from "react";

import { Grid, Paper, Typography } from "@material-ui/core";
import ScoreChart from "./ScoreChart";
import { getColoredLogo } from "../../../utils/riskUtils/riskUtils";
import { green } from "@material-ui/core/colors";
import { TopicData, TopicHeader } from "./esgComponents";


function EsgScore({ scores }) {
  const { scoreObject } = scores;
  const { environment, social, governance } = scoreObject;

  // const TopicHeader = ({ topic, score }) => {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "space-between",
  //         width: "100%",
  //         borderBottom: "1px solid lightblue",
  //         marginTop: "10px",
  //       }}
  //     >
  //       <div style={{ display: "flex", alignItems: "center" }}>
  //         <img src={getColoredLogo(topic)} alt="leaf" />
  //         <Typography style={{ fontSize: "14px", fontWeight: 600 }}>
  //           {topic}
  //         </Typography>
  //       </div>
  //       <div>
  //         <Typography style={{ fontSize: "22px", fontWeight: 600 }}>
  //           {score}
  //         </Typography>
  //       </div>
  //     </div>
  //   );
  // };
  // const TopicData = ({ topic, score }) => {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "space-between",
  //         width: "100%",
  //         marginTop: "8px",
  //       }}
  //     >
  //       <div style={{ display: "flex", alignItems: "center" }}>
  //         <Typography
  //           style={{ fontSize: "14px", marginLeft: "5px", fontWeight: 400 }}
  //         >
  //           {topic}
  //         </Typography>
  //       </div>
  //       <div>
  //         <Typography style={{ fontSize: "16px", fontWeight: 400 }}>
  //           {score}
  //         </Typography>
  //       </div>
  //     </div>
  //   );
  // };

  const dataNew = [
    {
      topic: "Environment",
      score: (environment.score * 1000).toFixed(2),
      subTopic: [
        { topic: "Energy", score: environment.data_points.energy.toFixed(2) },
        { topic: "Water", score: environment.data_points.water.toFixed(2) },
        { topic: "Waste", score: environment.data_points.waste.toFixed(2) },
        {
          topic: "Analytic CO2",
          score: environment.data_points.analytic.toFixed(2),
        },
        {
          topic: "Green revenues",
          score: environment.data_points.green.toFixed(2),
        },
      ],
    },
    {
      topic: "Social",
      score: (social.score * 1000).toFixed(2),
      subTopic: [
        {
          topic: "Diversity and Inclusion",
          score: social.data_points.diversity.toFixed(2),
        },
        {
          topic: "Human Rights",
          score: social.data_points.humanRights.toFixed(2),
        },
        {
          topic: "Career development and training",
          score: social.data_points.career.toFixed(2),
        },
        {
          topic: "Health and Safety",
          score: social.data_points.health.toFixed(2),
        },
        {
          topic: "Data privacy",
          score: social.data_points.dataPrivacy.toFixed(2),
        },
        {
          topic: "Responsible Marketing",
          score: social.data_points.product.toFixed(2),
        },
      ],
    },
    {
      topic: "Governance",
      score: (governance.score * 1000).toFixed(2),
      subTopic: [
        { topic: "Structure", score: 4 },
        { topic: "Compensation", score: 3 },
      ],
    },
    // {
    //   topic: "Business",
    //   score: 30,
    //   subTopic: [
    //     { topic: "Product Quality and Safety", score: 10 },
    //     { topic: "Customer Privacy and Data Protection", score: 6 },
    //     { topic: "Supply Chain Management", score: 28 },
    //     { topic: "Marketing and Advertising", score: 25 },
    //     { topic: "Innovation and Intellectual Property", score: 3},
    //   ],
    // },
    // {
    //   topic: "Human Capital",
    //   score: 7,
    //   subTopic: [
    //     { topic: "Work-Life Balance", score: 2 },
    //     { topic: "Employment Training & Development", score: 2 },
    //     { topic: "Diversity and Inclusion", score: 1 },
    //     { topic: "Employee engagement", score: 2 },
    //   ],
    // },
  ];
  return (
    <div style={{ paddingBottom: "18px", marginTop: "30px", width:"100%" }}>
      {" "}
      <Grid container md={12}>
        <Grid item md={6}>
          {/* <Typography
            style={{ fontSize: "13px", padding: "5px" }}
            component={Paper}
          >
            The score reflects the company's ESG performance and can guide
            stakeholders in making informed decisions. (Out of 100) 54
          </Typography> */}

          {dataNew.map((data) => {
            return (
              <>
                <TopicHeader topic={data.topic} score={data.score} />
                {data.subTopic.map((sub) => (
                  <TopicData topic={sub.topic} score={sub.score} />
                ))}
              </>
            );
          })}
        </Grid>
        <Grid item md={6}>
          <ScoreChart scores={scores} />
        </Grid>
      </Grid>
    </div>
  );
}

export default EsgScore;
