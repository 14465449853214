import {
  Box,
  Grid,
  Icon,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import CustomTextfield from "../../UI/textfield/textfield";
import CustomButton from "../../UI/button/button";
import debounce from "lodash.debounce";
function RiskDashboard(props) {
  const { login, getElasticOrganizations, riskDashboard } = props;
  const useStyles = makeStyles(() => ({
    mainDiv: {
      padding: "5px 20px",
      minWidth: 700,
      maxWidth: 700,
      overflowY: "hidden",
    },
    headerText: {
      fontWeight: "600",
    },
    cardTypography: {
      fontWeight: "600",
    },

    addIcon: {
      color: "#DADBE6",
    },

    removeChipIcon: {
      display: "none",
    },
    tableHeader: {
      marginTop: "10px",
      backgroundColor: "#EBF1F8",
      padding: "5px",
      borderRadius: "8px",
      height: "15px",
    },
    tableData: {
      padding: "6px",
    },
    statusOrange: {
      padding: "3px",
      borderRadius: "50%",
      backgroundColor: "orange",
      width: "8px",
      height: "8px",
    },
    headerIcon: { color: "#fff", paddingLeft: "5px" },
  }));
  const classes = useStyles();
  const history = useHistory();
  let limit = 5;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);

  const [pages, setPages] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [searchCompanyText, setSearchCompanyText] = useState("");
  const [startingAfter, setStartingAfter] = useState(0);

  useEffect(() => {
    login?.token && getElasticOrganizations(login?.token, limit, startingAfter);
  }, [login.token, startingAfter]);

  const handleChangePage = (event, newPage) => {
    // rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    // console.log("new ", page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    setPage(newPage - 1);
  };

  const documents = [
    {
      _id: "8bwJ-IkB5EUh95iqdYAf",
      company_name: "United Spirits Ltd",
      year: "2022-2023",
    },
    {
      _id: "8rwo-IkB5EUh95iqtYCq",
      company_name: "PI Industries Ltd",
      year: "2021-2022",
    },
    {
      _id: "9ryA-IkB5EUh95iqKoDv",
      company_name: "Hero MotoCorp Ltd",
      year: "2022-2023",
    },
    {
      _id: "9bxj-IkB5EUh95iqVYCx",
      company_name: "Hero MotoCorp Ltd",
      year: "2020-2021",
    },
    {
      _id: "87xF-IkB5EUh95iq3ICd",
      company_name: "Hero MotoCorp Ltd",
      year: "2022-2023",
    },
    {
      _id: "8Lz194kB5EUh95iqQoD3",
      company_name: "United Spirits Ltd",
      year: "2022-2023",
    },
    {
      _id: "-LyW-IkB5EUh95iqXYAc",
      company_name: "MphasiS Ltd",
      year: "2022-2023",
    },
    {
      _id: "-ryk-IkB5EUh95iqh4B8",
      company_name: "MphasiS Ltd",
      year: "2021-2022",
    },
    {
      _id: "9LxT-IkB5EUh95iqDYCO",
      company_name: "Hero MotoCorp Ltd",
      year: "2021-2022",
    },
    {
      _id: "-bye-IkB5EUh95iqC4Ds",
      company_name: "MphasiS Ltd",
      year: "2021-2022",
    },
    {
      _id: "B7xC-YkB5EUh95iqpIEJ",
      company_name: "PI Industries Ltd",
      year: "2021-2022",
    },
    {
      _id: "C7yl-YkB5EUh95iq4oEs",
      company_name: "Steel Authority of India Ltd",
      year: "2020-2021",
    },
    {
      _id: "CLxw-YkB5EUh95iqzoHH",
      company_name: "PI Industries Ltd",
      year: "2020-2021",
    },
    {
      _id: "DbzB-YkB5EUh95iqCoHb",
      company_name: "Gujarat Gas Ltd",
      year: "2020-2021",
    },
    {
      _id: "DLyz-YkB5EUh95iqDoG3",
      company_name: "Gujarat Gas Ltd",
      year: "2021-2022",
    },
    {
      _id: "Bbwg-YkB5EUh95iqlYEk",
      company_name: "PI Industries Ltd",
      year: "2021-2022",
    },
    {
      _id: "Brwn-YkB5EUh95iqrYEO",
      company_name: "PI Industries Ltd",
      year: "2020-2021",
    },
    {
      _id: "Cbx--YkB5EUh95iqlIGk",
      company_name: "Steel Authority of India Ltd",
      year: "2021-2022",
    },
    {
      _id: "CryL-YkB5EUh95iqyIEd",
      company_name: "Steel Authority of India Ltd",
      year: "2021-2022",
    },
    {
      _id: "97yO-IkB5EUh95iq24DX",
      company_name: "Hero MotoCorp Ltd",
      year: "2021-2022",
    },
    {
      _id: "BLwU-YkB5EUh95iq4YES",
      company_name: "United Spirits Ltd",
      year: "2022-2023",
    },
    {
      _id: "-7yv-IkB5EUh95iqV4De",
      company_name: "MphasiS Ltd",
      year: "2020-2021",
    },
    {
      _id: "_by7-IkB5EUh95iqBYBp",
      company_name: "Torrent Pharmaceuticals Ltd",
      year: "2022-2023",
    },
    {
      _id: "_rzK-IkB5EUh95iqSYBK",
      company_name: "Torrent Pharmaceuticals Ltd",
      year: "2021-2022",
    },
    {
      _id: "Abz5-IkB5EUh95iqL4G7",
      company_name: "United Spirits Ltd",
      year: "2021-2022",
    },
    {
      _id: "ArwF-YkB5EUh95iqN4Et",
      company_name: "United Spirits Ltd",
      year: "2020-2021",
    },
    {
      _id: "ALzn-IkB5EUh95iqF4Fy",
      company_name: "United Spirits Ltd",
      year: "2022-2023",
    },
    {
      _id: "_7zZ-IkB5EUh95iqKoCT",
      company_name: "Torrent Pharmaceuticals Ltd",
      year: "2020-2021",
    },
    {
      _id: "_Lyx-IkB5EUh95iqNYCF",
      company_name: "Jubilant Foodworks Ltd",
      year: "2021-2022",
    },
    {
      _id: "DrzK-YkB5EUh95iqfIF1",
      company_name: "Bajaj Holdings and Investment Ltd",
      year: "2022-2023",
    },
    {
      _id: "D7zZ-YkB5EUh95iqW4FC",
      company_name: "Bajaj Holdings and Investment Ltd",
      year: "2021-2022",
    },
    {
      _id: "ELzf-YkB5EUh95iq24Eu",
      company_name: "Bajaj Holdings and Investment Ltd",
      year: "2022-2023",
    },
    {
      _id: "Ebzn-YkB5EUh95iqPIEK",
      company_name: "Bajaj Holdings and Investment Ltd",
      year: "2021-2022",
    },
    {
      _id: "Erzu-YkB5EUh95iqsIH3",
      company_name: "Bajaj Holdings and Investment Ltd",
      year: "2020-2021",
    },
    {
      _id: "E7wH-okB5EUh95iqM4Gm",
      company_name: "Alkem Laboratories Ltd",
      year: "2022-2023",
    },
  ];

  const viewCompanies = (toMap) => {
    return (
      toMap
        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, i) => (
          <TableRow
            key={row.company_name + i}
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push({
                pathname: `/risk_admin/doc`,
                state: riskDashboard?.companies[i],
              });
            }}
          >
            <TableCell
              className={classes.tableData}
              // component="th"
              // scope="row"
            >
              {row.company_name}
            </TableCell>

            <TableCell className={classes.tableData} align="right">
              {row.year}
            </TableCell>
            {/* <TableCell className={classes.tableData} align="right">
              <Typography>Me</Typography>
              {/* <CustomButton
            // onClick={() => setShareDialogOpen(true)}
            style={{ backgroundColor: "white" }}
          >
            <Icon fontSize="small">more_vert</Icon>
          </CustomButton>  
            </TableCell> */}
            <TableCell className={classes.tableData} align="right">
              <Typography
                style={{
                  textAlign: "right",
                  diaplay: "inline-flex",
                  alighItems: "center",
                }}
              >
                <Box
                  display="inline-flex"
                  alignItems="center"
                  // bgcolor={row.is_reviewed  ? '#EBF6EF' : '#E9E9E9'}
                  p={1}
                  style={{
                    padding: "8px",
                    textAlign: "right",
                  }}
                  borderRadius="8px"
                >
                  <Box
                    borderRadius="50%"
                    height={10}
                    width={10}
                    bgcolor={
                      row.is_reviewed ? "#5BB37F" : "#FFC250"
                      // : "#9FA1AF"
                    }
                  />
                  <Box
                    style={{
                      paddingLeft: "5px",
                      fontSize: "14px",
                      color:
                        row.is_reviewed 
                          ? "#5BB37F"
                          : 
                           "inherit"
                          // : "#9FA1AF",
                    }}
                  >
                    {row.is_reviewed ? "Reviewed" : "Yet to be Reviewed"}
                  </Box>
                </Box>
              </Typography>
            </TableCell>
          </TableRow>
        ))
    );
  };

  useEffect(() => {
    riskDashboard?.companies &&
      riskDashboard?.companies.length > 0 &&
      riskDashboard?.companies.filter((comp) => {
        return comp.company_name === searchCompanyText;
      });
    riskDashboard?.companies &&
    riskDashboard?.companies.length > 0 &&
    searchCompanyText
      ? setCompanies(
          riskDashboard?.companies.filter((comp) => {
            return comp.company_name
              .toLowerCase()
              .includes(searchCompanyText.toLowerCase());
          })
        )
      : setCompanies([]);
  }, [searchCompanyText]);

  useEffect(() => {
    setPages(Math.ceil(riskDashboard?.totalCompanies / limit));
  }, [riskDashboard?.totalCompanies]);

  // pagination function
  const handleChange = (event, value) => {
    setStartingAfter(value * limit - limit);
    return;
  };

  const debouncedFetchData = useMemo(
    () =>
      debounce((query) => {
        getElasticOrganizations(login?.token, limit, query);
      }, 1200),
    [searchCompanyText] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onSearchHandler = (e) => {
    setSearchCompanyText(e.target.value);
    debouncedFetchData(e.target.value);
  };

  return (
    <div style={{ paddingTop: "40px" }}>
      <Grid>
        <Typography
          style={{ fontWeight: 600, fontSize: "18px", paddingLeft: "20px" }}
        >
          Risk Assessment Admin Dashboard
        </Typography>
      </Grid>
      <Grid
        md={12}
        sm={12}
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          alignItems: "center",
        }}
      >
        <Pagination
          style={{ padding: "0px 65px" }}
          // count={Math.round(documents.length / rowsPerPage)}
          count={pages}
          siblingCount={0}
          defaultPage={1}
          // onChange={handleChangePage}
          onChange={handleChange}
          variant="outlined"
          color="primary"
          size="small"
        />
        {/* <CustomTextfield
          name="Search"
          variant="outlined"
          // fullWidth
          size="small"
          type="text"
          label="Search"
          onChange={(e) => {
            // setSearchCompanyText(e.target.value);
            onSearchHandler(e);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={searchCompanyText}
        /> */}
      </Grid>
      {/* <CustomButton
              style={{ width: "95%", marginTop: "15px", backgroundColor: "grey" }}
              onClick={() => {
                setCompaniesData((prev) => {
                  prev.push(companyDetails);
                  return [...prev];
                });
              }}
            >
              Add Company
            </CustomButton> */}
      <TableContainer style={{ padding: "10px 80px", marginTop: "25px" }}>
        <Table style={{ width: "100%" }}>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell className={classes.tableData}>
                Name Of The Company
              </TableCell>
              <TableCell className={classes.tableData} align="right">
               Year of Assessment
              </TableCell>
              {/* <TableCell className={classes.tableData} align="right">
                Shared By
              </TableCell> */}
              <TableCell className={classes.tableData} align="right">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.length > 0 ? (
              searchCompanyText ? (
                viewCompanies(companies)
              ) : (
                <Typography style={{ textAlign: "center", marginTop: "25px" }}>
                  No Data Found.
                </Typography>
              )
            ) : !searchCompanyText &&
              riskDashboard?.companies &&
              riskDashboard?.companies.length > 0 ? (
              viewCompanies(riskDashboard?.companies)
            ) : (
              <Typography style={{ textAlign: "center", marginTop: "25px" }}>
                No Companies Found.
              </Typography>
            )}
            {/* {documents ? (
              viewCompanies(documents)
            ) : (
              <Typography style={{ textAlign: "center", marginTop: "25px" }}>
                No Survey Assigned
              </Typography>
            )} */}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default RiskDashboard;
