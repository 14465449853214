import React from "react";

import { useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";

import { Button, TextField, Paper } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import CachedIcon from "@material-ui/icons/Cached";
import { makeStyles } from "@material-ui/core/styles";
import { setAskMessage } from "../../../redux/actions/gpt/esgGptAction";
import lenovoButton from "../../../images/lenovoButton.svg";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.light,
      borderRadius: "80px",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.light,
      borderRadius: "80px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.dark,
      borderRadius: "80px",
    },
    "& .MuiOutlinedInput-input": {
      color: theme.palette.primary.main,
    },
    "&:hover .MuiOutlinedInput-input": {
      color: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: theme.palette.primary.main,
    },
    "& .MuiInputLabel-outlined": {
      color: theme.palette.primary.main,
    },
    "&:hover .MuiInputLabel-outlined": {
      color: theme.palette.primary.main,
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: theme.palette.primary.main,
    },
    // color: "#3374B9",
    backgroundColor:"#00000066",
    borderRadius: "80px",
    
    // borderColor: "#F6F6F6",
  },
}));

function EsgGptChatField() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { location } = history;

  const showAddButton = location.pathname === "/esg_gpt/chat";

  const [message, setMessage] = React.useState("");

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleMessageSend = () => {
    dispatch(setAskMessage(message));
    setMessage("")
  }

  return (
    <Paper
    elevation={0}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // border: "solid",
        height: "50px",
        marginBottom:"10px"
      }}
    >
      {showAddButton && (
        <IconButton
          style={{ color: "#3374B9", marginRight: "10px" }}
          aria-label="add to shopping cart"
        >
          <Icon style={{ color: "#3374B9" }}>add</Icon>
        </IconButton>
      )}
      <TextField
        className={classes.root}
        id="outlined-basic"
        label="Ask Question be specific and use company names for better results."
        variant="outlined"
        style={{
          width: "70%",
          margin: "2px 5px",
        }}
        name="message"
        value={message}
        onChange={handleMessageChange}
      />
      {/* <Button
        variant="contained"
        // color="primary"
        style={{ backgroundColor: "#3374B9", borderRadius: "8px" }}
        // className={classes.button}
        endIcon={<Icon style={{ color: "#EBF1F8" }}>send</Icon>}
      /> */}
        
      <img src={lenovoButton} alt="button" style={{cursor:"pointer"}}  onClick={handleMessageSend} />
      {/* <IconButton
        variant="outlined"
        style={{
          border: "1px solid #E1251B",
          backgroundColor: "#E1251B",
          marginLeft: "10px",
          padding: "5px",
          height: "100%",
          borderRadius: "46px",
          width: "50px",
        }}
        size="small"
        onClick={handleMessageSend}
      >
        <Icon style={{ color: "#FFFFFF" }}>send</Icon>
      </IconButton> */}
      {/* <IconButton
        style={{ color: "#3374B9", marginLeft: "10px" }}
        aria-label="add to shopping cart"
      >
        <CachedIcon style={{ fontSize: "30px" }} />
      </IconButton> */}
    </Paper>
  );
}

export default EsgGptChatField;
