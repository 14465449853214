import React from "react";
import PercentageDoughnutChart from "../../UI/percentageDoughnut.js/percentageDoughnutChart";
import { Card, Typography } from "@material-ui/core";
import charge from "../../../images/Folder.png";
import heart from "../../../images/SelfAssessment.png";
function CompanyProfile({scores}) {
   
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Card style={{ padding: "5px", width: "100%" }}>
          <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
            Overall ESG Score
          </Typography>
          <Typography
            style={{ fontWeight: 400, fontSize: "13px", color: "#15314E" }}
          >
            Our Overall ESG Score is the average of ESG score and ESG
            Controversy Score and provides valuable insights into your ESG
            practices, helping you meet stakeholder expectations and work
            towards a more sustainable future.
          </Typography>
          <div style={{ display: "flex", paddingBotttom: "20px" }}>
              <div style={{ width: "60%", marginTop: "15px" }}>
                <div style={{ width: "65%", display: "flex" }}>
                  <img
                    src={charge}
                    alt="charge"
                    style={{ width: "40px", height: "40px" }}
                  />

                  <PercentageDoughnutChart
                    color="rgb(54, 162, 235)"
                    emptyColor="#EBF6EF"
                    percentage={Math.round(scores.finalEsg)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "rgb(54, 162, 235)",
                      borderRadius: "100%",
                      width: "12px",
                      height: "12px",
                    }}
                  />{" "}
                  <Typography style={{ marginLeft: "10px",fontSize:'16px',fontWeight:500  }}>
                    Overall ESG Score
                  </Typography>
                </div>
              </div>
              <div style={{ width: "60%", marginTop: "15px" }}>
                <div style={{ width: "65%", display: "flex" }}>
                  <img
                    src={charge}
                    alt="charge"
                    style={{ width: "40px", height: "40px" }}
                  />

                  <PercentageDoughnutChart
                    color="rgb(255, 159, 64)"
                    emptyColor="#EBF6EF"
                    percentage={Math.round(scores.esgScore)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "rgb(255, 159, 64)",
                      borderRadius: "100%",
                      width: "12px",
                      height: "12px",
                    }}
                  />{" "}
                  <Typography style={{ marginLeft: "10px" ,fontSize:'16px',fontWeight:500 }}>
                    ESG Score
                  </Typography>
                </div>
              </div>
              <div style={{ width: "60%", marginTop: "15px" }}>
                <div style={{ width: "65%", display: "flex" }}>
                  <img
                    src={charge}
                    alt="charge"
                    style={{ width: "40px", height: "40px" }}
                  />

                  <PercentageDoughnutChart
                    color="#f67019"
                    emptyColor="#EBF6EF"
                    percentage={Math.round(scores.esgcScore)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#f67019",
                      borderRadius: "100%",
                      width: "12px",
                      height: "12px",
                    }}
                  />{" "}
                  <Typography style={{ marginLeft: "10px",fontSize:'16px',fontWeight:500  }}>
                    ESG Controversy Score
                  </Typography>
                </div>
              </div>
            </div>
        </Card>
        {/* <div style={{ width: "30%", padding: "5px" }}>
          <Typography style={{ fontWeight: 400, fontSize: "16px" }}>
            Deviation Percentage
          </Typography>
          <Typography
            style={{ fontWeight: 500, fontSize: "24px", padding: "16px 0px" }}
          >
            12%
          </Typography>
          <Typography
            style={{ fontWeight: 400, fontSize: "13px", color: "#15314E" }}
          >
            The deviation may indicate a gap between a company's actual ESG
            practices and what is being publicly disclosed, highlighting the
            need for improved transparency and accountability.
          </Typography>
        </div> */}
      </div>
    </div>
  );
}

export default CompanyProfile;
