import React from "react";

import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  setAcknowledged,
  setNewCompanyDetails,
  setNewCompanyLogo,
} from "../../../redux/actions/gpt/esgGptAction";

export const useAddCompanyStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.body2,
    backgroundColor: theme.palette.secondary.dark,
    width: "94%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "10px",
    color: theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius,
    textTransform: "capitalize",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.secondary.main,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.secondary.main,
    },
  },
}));

export default function EsgGptAddCompanyButton() {
  const classes = useAddCompanyStyles();
  const history = useHistory();
  const dispath = useDispatch();
  const { location } = history;

  const buttonText =
    location.pathname === "/esg_gpt/chat" ? "Add New Chat" : "Add New Company";

  const handleChangeRoute = () => {
    let path = "/esg_gpt/add_company";
    location.pathname !== "/esg_gpt/chat" && history.push(path);

    dispath(setAcknowledged(false));
    dispath(setNewCompanyDetails({}));
    dispath(setNewCompanyLogo(""));
  };
  return (
    <div style={{ width: "20vw",  display:"flex", alignItems:"center", justifyContent:"center" }}>
      <Button
        className={classes.root}
        onClick={handleChangeRoute}
        variant="outlined"
        size="large"
      >
        {buttonText}
      </Button>
    </div>
  );
}
