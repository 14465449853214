import {
  GET_CALCULATED_SURVEY,
  RESET_RISK_EVALUATOR,
  SET_CALCULATED_SURVEY,
  SET_CALCULATED_SENTIMENT,
  SET_CALCULATED_SENTIMENT_TOPIC_WISE,
  SET_CALCULATED_SENTIMENT_BAR_GRAPH,
  SET_CALCULATED_SENTIMENT_ARTICLE,
  SET_SENTIMENT_SOCRE_DATA,
  SET_OVERALL_DATA,
  SET_SCORES,
  SET_CONTROVERSY_DATA,
  SET_PEER_SCORES,
} from "../../../constants/riskEvaluatorConstants";

const initial_state = {
  riskEvaluator: "",
  calculatedEsgDisclosure: "",
  sentimentRiskWiseData: "",
  sentimentBarGraph: "",
  sentimentArticle: "",
  sentimentScore: "",
  overallData: "",
  scores: "",
  peerScores: "",
  controversyData: "",
};

export default function riskEvaluatorReducer(state = initial_state, action) {
  switch (action.type) {
    case SET_CALCULATED_SURVEY:
      return (state = { ...state, calculatedEsgDisclosure: action.payload });
    case SET_CALCULATED_SENTIMENT_TOPIC_WISE:
      return (state = { ...state, sentimentRiskWiseData: action.payload });
    case SET_CALCULATED_SENTIMENT_BAR_GRAPH:
      return (state = { ...state, sentimentBarGraph: action.payload });
    case SET_CALCULATED_SENTIMENT_ARTICLE:
      return (state = { ...state, sentimentArticle: action.payload });
    case SET_SENTIMENT_SOCRE_DATA:
      return (state = { ...state, sentimentScore: action.payload });
    case SET_OVERALL_DATA:
      return (state = { ...state, overallData: action.payload });
    case SET_SCORES:
      return (state = { ...state, scores: action.payload });
    case SET_PEER_SCORES:
      return (state = { ...state, peerScores: action.payload });
    case SET_CONTROVERSY_DATA:
      return (state = { ...state, controversyData: action.payload });
    case RESET_RISK_EVALUATOR:
      return (state = initial_state);
    default:
      return state;
  }
}
