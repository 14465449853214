import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";

import CancelIcon from "@material-ui/icons/Cancel";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { setNewCompanyLogo } from "../../../../redux/actions/gpt/esgGptAction";

export const brmRightStyles = makeStyles((theme) => ({
  logoSelecter: {
    border: "2px dashed #DADBE6",
    width: 150,
    height: 150,
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      "& $addIcon": {
        color: "#3374B9",
      },
    },
  },
  addIcon: {
    color: "#DADBE6",
  },
  companyLogo: {
    border: "2px solid #DADBE6",
    width: 150,
    height: 150,
    borderRadius: 5,
    zIndex: 1,
    "& img": {
      width: "100%",
      height: "100%",
    },
    position: "relative",
  },
  ClearImage: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: 2,
  },
}));

function EsgGptCompanyLogo() {
  const classes = brmRightStyles();

  const dispatch = useDispatch();
  
  const {newLogo} = useSelector((state) => state.esgGptReducer);

  const [companyLogoURI, setCompanyLogoURI] = React.useState("");
  // const [companyLogo, setCompanyLogo] = React.useState("")

  const handleUploadCompanyLogo = (e) => {
    const reader = new FileReader();

    const file = e.target.files[0];
    dispatch(setNewCompanyLogo(file))
    // setCompanyLogo(file);
    reader.onloadend = () => {
      setCompanyLogoURI(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleClearCompanyLogo = () => {
    // setCompanyLogo("");
    setCompanyLogoURI("");
  };

  return (
    <>
      <Box my={2} style={{ display:"flex", justifyContent:"center"}}>
        {!newLogo ? (
          <>
            <input
              id="company-logo"
              type="file"
              onChange={(event) => handleUploadCompanyLogo(event)}
              style={{ display: "none" }}
              cursor="pointer"
            />
            <label htmlFor="company-logo">
              <div className={classes.logoSelecter}>
                <AddCircleOutlineIcon
                  name="addLogoIcon"
                  fontSize="large"
                  className={classes.addIcon}
                />
              </div>
            </label>
          </>
        ) : (
          <div className={classes.companyLogo}>
            <img alt="company-logo" src={companyLogoURI} />
            <IconButton
              onClick={handleClearCompanyLogo}
              className={classes.ClearImage}
            >
              <CancelIcon style={{ color: "gray" }} fontSize="small" />
            </IconButton>
          </div>
        )}
      </Box>
    </>
  );
}

export default EsgGptCompanyLogo;
