import {
  AppBar,
  Button,
  Drawer,
  Grid,
  MenuItem,
  MuiThemeProvider,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CustomTextfield from "../../UI/textfield/textfield";
import CustomButton from "../../UI/button/button";
import TabPanel from "../../UI/tabPanel/tabPanel";
import { appbarStyles } from "../esgCompanyProfile/esgCompantProfile";
import { appTheme } from "../riskAssessment/brmDetaails";
import OverallProfile from "../esgCompanyProfile/overallProfile";
import RefreshIcon from "@material-ui/icons/Refresh";
import PublishIcon from "@material-ui/icons/Publish";
import CustomCheckBox from "../../UI/checkBox/checkBox";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import CloseIcon from "@material-ui/icons/Close";
import MoreVertIcon from "@material-ui/icons/MoreVert";
function RiskDocument(props) {
  const { state } = props.location;
  const {
    login,
    riskDashboard,
    updateCompanyData,
    getCompanyData,
    getControversyLinks,
    getControversyData,
    updateControversyType,
    controversyData,
    publishLinks,
  } = props;
  const history = useHistory();
  const [answers, setAnswers] = useState({});
  const [isControversyOpen, setIsControversyOpen] = useState(false);
  const [currentControversy, setCurrentControversy] = useState(false);
  const [kpiList, setKpiList] = useState(
    riskDashboard?.currentCompanyData || {}
  );
  // 54 ,

  const classes = appbarStyles();

  const [adminTabNo, setAdminTabNo] = useState(0);
  var result = { ...answers }; //<- copy roomRent into result
  const handleInputChange = (key, value, i) => {
    // setKpiList((prev) => {
    //   return {
    //     ...prev,
    //     [key]: [prev[key][0], value],
    //   };
    // });

    // console.log(answers);

    result[key] = returnType(key) === "number" ? Number(value) : value;

    // setAnswers(result);
    // result = result.map((x) => { //<- use map on result to find element to update using id
    //   if (x.id === id) x.room = value;
    //   return x;
    // });

    // setAnswers({
    //   ...answers,
    //   [key]: returnType(key) === "number" ? Number(value) : value,
    // });
  };

  const returnType = (key) => {
    return key.toLowerCase().includes("percentage")
      ? "number"
      : key.toLowerCase().includes("rate") ||
        key.toLowerCase().includes("per") ||
        key.toLowerCase().includes("number") ||
        key.toLowerCase().includes("total") ||
        key.toLowerCase().includes("kilo") ||
        key.toLowerCase().includes("liter")
      ? "number"
      : "text";
  };

  useEffect(() => {
    adminTabNo === 0 && getCompanyData(login.token, state._id);
    adminTabNo === 1 && getControversyLinks(login.token, state?.isin);
    adminTabNo === 1 && getControversyData(login.token, state?.isin);
    // adminTabNo === 2 && getCompanyData(login.token, state._id);

    return () => {
      result = {};
      setAnswers({});
    };
  }, [login.token, adminTabNo]);

  useEffect(() => {
    setLinksList(
      riskDashboard?.controversyLinks.map((link) => {
        if (link.is_published) {
          link.isSelected = true;
        }else{
          link.isSelected = false
        }
        return link
      })
    );
  }, [riskDashboard.controversyLinks]);

  useEffect(() => {
    riskDashboard?.currentCompanyData &&
      setKpiList(riskDashboard?.currentCompanyData);
    let ans = {};
    if (riskDashboard?.currentCompanyData) {
      for (let keyOfIndex in riskDashboard?.currentCompanyData) {
        ans[keyOfIndex] = riskDashboard?.currentCompanyData[keyOfIndex][1];
      }
      setAnswers(ans);
    }
  }, [riskDashboard?.currentCompanyData]);

  // {
  //   "Total electricity consumption in Joules  from renewable sources": [
  //     " 15861 MWh.",
  //     "1",
  //   ],
  // "Total fuel consumption in Joules  from renewable sources": " 15861 MWh.",
  // "Total Energy consumption  in Joules  from renewable sources ":
  //   " 15861 MWh.",
  // "Total electricity consumption in Joules  from non renewable sources":
  //   " This report does not provide direct information",
  // "Total fuel consumption in Joules  fromnon renewable sources":
  //   " The report does not provide direct information",
  // "Total Energy consumption  in Joules  from non renewable sources ":
  //   " 216352",
  // "Water withdrawal by source (in kilolitres)  from surface water":
  //   " 663 ML.",
  // "Water withdrawal by source (in kilolitres)  from ground water":
  //   " 1008 ML,",
  // "Water withdrawal by source (in kilolitres)  from third party water ":
  //   " 6 ML,",
  // "Amount of rainwater was utilized  (in kilolitres) ": 0,
  // " Volume of water withdrawal  (in kilolitres)": " 1018 million litres.",
  // "Total volume of water consumption (in kilolitres)  ":
  //   " 40,000 cubic meters.",
  // " Water intensity per rupee of turnover (Water consumed / turnover) ":
  //   " 0.057 ML/INR.",
  // "total Scope 1 emission in metric tonnes of CO2": " 3,631.86 MT CO2.",
  // "total Scope 2 emission in metric tonnes of CO2": " 4,804 - 4,633)",
  // "Total Scope 1 and Scope 2 emissions per rupee of\nTurnover": 0,
  // "total Scope 3 emission in metric tonnes of CO2 ": " Scope 3.",
  // "Total Scope 3 emission under the category 1 – Purchased goods & services in  metric tonnes of CO2 ":
  //   " 40,807.",
  // "Total Scope 3 emission under the Category 2 – Capital goods  in  metric tonnes of CO2 ": 0,
  // "Total Scope 3 emission under the Category 3 Fuel and energy related activities (not included in Scope \n1 or 2)s in  metric tonnes of CO2":
  //   " 684,000",
  // "Total Scope 3 emission under theCategory 4 – Upstream transportation and distribution  in  metric tonnes of CO2  ":
  //   " 15,000",
  // "Total Scope 3 emission under the Category 5 – Waste generated in operations   in  metric tonnes of CO2":
  //   " 85,259",
  // "Total Scope 3 emission under the Category 6 – Business travel   in  metric tonnes of CO2":
  //   " 1,500",
  // "Total Scope 3 emission under the  Category 7 – Employee commuting in  metric tonnes of CO2":
  //   " 12,000 metric tons of CO2,",
  // "Total Scope 3 emissions per rupee of\nTurnover": 0,
  // "proportion of female employees among the total workforce": " 77.4%.",
  // " percentage of differently-abled male employees ":
  //   " It's not clear if there's any information in this text.",
  // " percentage of differently-abled female employees ":
  //   "We do not collect data on the disability status of our employees,",
  // "percentage of female representation on the board of directors": " 22.2%.",
  // "percentage of female representation in  the key management personnel":
  //   " 21%.",
  // "percentage of female representation  in  the Senior management": " 1.0.",
  // "total turn over rate for permanent employees ": " 10 / 100 * 2000",
  // " total number of\ncomplaints \nfiled from communities":
  //   " number of complaints received from communities.",
  // " total number of\ncomplaints \nfiled from investors other than shareholders":
  //   " 0",
  // "total number of complaints filed from shareholders": " 0.",
  // "total number of complaints filed from customers": " 0",
  // "total number of complaints filed from employees": " 0",
  // " total number of complaints filed from value chain partners":
  //   "There is no direct information",
  // " Percentage of R&D  investments in specific technologies ":
  //   " it is not possible to determine the percentage",
  // "Percentage of capital expenditure (capex) investments in specific technologies":
  //   "Diageo India United Spirits Limited invested INR 17.67 crores",
  // "Percentage of  total employees covered by health insurance ":
  //   " (90/110) x 100%.",
  // "Percentage of  total employees covered by accident insurance ": " 83.",
  // "Percentage of  total employees covered by maternity benefits":
  //   " no specific percentage",
  // "Percentage of  total employees covered by paternity benefits": " 331.",
  // "Percentage of  total employees covered by day care facilities ": " 331.",
  // "Percentage of Return to work  of permanent male employees  that took parental leave":
  //   "It is not possible to determine the percentage",
  // "Percentage of Return to work  of permanent female employees that took parental leave":
  //   " not provided in the given information.",
  // "Percentage of Return to work  of  total permanent  employees that took parental leave":
  //   " is not provided.",
  // "Percentage of Retention rates  of permanent male employees that took parental leave":
  //   " there is no specific information",
  // "Percentage of Retention rates  of permanent female employees that took parental leave":
  //   " (18/20) x 100% = 90%.",
  // "Percentage of Retention ratesof  total permanent  employees":
  //   " is not provided.",
  // "percentage of total employees were given training on health and safety measures ":
  //   " 3,560 Man Days",
  // " percentage of total employees were given training on skill upgradation":
  //   " 89%.",
  // " Lost Time Injury Frequency Rate (LTIFR) ": " is not reported",
  // "Total recordablework-related injuries": " 83,",
  // "Total  No. of fatalities": " 83 cases of LTAs.",
  // "Number of High consequence work-related injury ": " 83",
  // " number of employees having suffered high consequence work- related injury / ill-health / fatalities":
  //   " does not provide specific numbers",
  // "number of employees who have been are rehabilitated and placed in suitable employment or whose family \nmembers have been placed in suitable employment i":
  //   " 0",
  // "percentage of total employees who have been provided training on human rights issues and policy(ies) ":
  //   " 152.7%.",
  // " percentage of male permanent employees who are paid with mininmum wage":
  //   " 100%,",
  // " percentage of female permanent employees who are paid with mininmum wage":
  //   " 100%,",
  // "percentage of male permanent employees who are paid with more than  mininmum wage  ":
  //   " 57.69%.",
  // "number of Complaints on Sexual harassement by employees":
  //   " Human Rights Global Policy.",
  // "number of Complaints on Child Labour": " quarterly,",
  // " number of Complaints on Discrimination at \nworkplace by employees":
  //   " 30",
  // "number of Complaints on \nForced Labour/\nInvoluntary Labour by employees ":
  //   " company does not consider it material to their business at this time.",
  // " number of Complaints on \nWages ": "0-99",
  // " number of Complaints on \nOther human rights \nrelated issues by employees":
  //   " 3",
  // "number of  consumer complaints in data privacy ": " it does not",
  // "number of consumer complaints in Advertising": " not",
  // "number of consumer complaints in Cyber-security": " no",
  // "number of consumer complaints in Delivery of essential services":
  //   " no mention of consumer complaints",
  // "number of consumer complaints in Restrictive Trade Practices ":
  //   " 2022-23.",
  // " number of consumer complaints in Unfair Trade Practices":
  //   " grievances and complaints, but does not provide specific information about consumer complaints.",
  // companyname: "United Spirits Ltd",
  // isin: "INE603J01033",
  // year: "2022-2023",
  // }

  const viewKpiDataList = (key, i) => {
    return (
      <div
        key={key}
        name={key + i}
        style={{
          display: "flex",
          borderBottom: "1px solid #EBF1F8",
          borderRadius: 5,
          padding: "10px 0px 5px 5px",
          alignItems: "center",
          // backgroundColor: "#FEFEFE",
        }}
        //   onClick={() => handleViewDetailsOrganization(row?._id)}
      >
        <div style={{ width: "50%", display: "flex" }}>
          {/* <Icon
          style={{ cursor: "pointer", color: "#15314E" }}
          //   onClick={() => handleViewDetailsOrganization(row._id)}
          fontSize="small"
        >
          descriptionicon
        </Icon> */}

          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              padding: "0px 5px",
            }}
          >
            {`${i + 1})`}
          </Typography>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              padding: "0px 5px",
            }}
          >
            {key}
          </Typography>
        </div>
        <div style={{ width: "30%" }} align="center">
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              padding: "0px 5px",
            }}
          >
            {kpiList[key][0]}
          </Typography>
        </div>
        <div style={{ width: "20%" }} align="center" n>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              padding: "0px 5px",
            }}
          >
            <CustomTextfield
              name={key + i + "1"}
              margin="dense"
              variant="outlined"
              size="small"
              value={result[key]}
              type={returnType(key)}
              onChange={(e) => {
                handleInputChange(key, e.target.value, i);
              }}
            />
          </Typography>
        </div>
        {/* <div style={{ width: "5%" }} align="center">
        <CustomButton margin="dense" variant="outlined" size="small">
          Submit
        </CustomButton>
      </div> */}
      </div>
    );
  };

  const contoData = {
    null: 0,
    ...controversyData,
    // "Anti-competition controversy": 0,
    // "Business ethics controversies": 1,
    // "Intellectual property controversies": 1,
    // "Critical countries controversies": 0,
    // "Public health controversies": 0,
    // "Tax fraud controversies": 0,
    // "Child labour controversies": 0,
    // "Human rights controversies": 0,
    // "Management compensation controversies count": 1,
    // "Consumer controversies": 0,
    // "Customer health and safety controversies": 0,
    // "Privacy controversies": 0,
    // "Product access controversies": 0,
    // "Responsible marketing controversies": 0,
    // "Responsible R&D controversies": 0,
    // "Environmental controversies": 0,
    // "Accounting controversies count": 0,
    // "Insider dealings controversies": 0,
    // "Shareholder rights controversies": 0,
    // "Diversity and opportunity controversies": 0,
    // "Employee health and safety controversies": 0,
    // "Wages or working conditions controversies": 0,
    // Strikes: 0,
  };

  const [linksList, setLinksList] = useState([]);

  const viewControversyList = (name, value) => {
    return (
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid #EBF1F8",
          borderRadius: 5,
          padding: "10px 0px 5px 5px",
        }}
      >
        <div style={{ width: "50%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              padding: "0px 5px",
            }}
          >
            {name}
          </Typography>
        </div>

        <div style={{ width: "35%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              padding: "0px 5px",
            }}
          >
            {value}
          </Typography>
        </div>
        <div style={{ width: "10%" }}>
          {/* <CustomButton
            onClick={() => {
              setIsControversyOpen(true);
              setCurrentControversy(name);
            }}
            margin="dense"
            variant="outlined"
            size="small"
          >
            view
          </CustomButton> */}
          <MoreVertIcon />
        </div>
      </div>
    );
  };

  const [currentLink, setCurrentLink] = useState("");

  const viewLinks = (links, index) => {
    let { isSelected, title, url, sentiment_label, is_published } = links;
    return (
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid #EBF1F8",
          borderRadius: 5,
          padding: "10px 0px 5px 5px",
          alignItems: "center",
        }}
      >
        <div>
          <CustomCheckBox
            key={title}
            onChange={() => {
              setLinksList((prev) => {
                return [...prev].map((li) => {
                  if (li.url === url && !li.is_published) {
                    li.isSelected = !li.isSelected;
                  }
                  return li;
                });
              });
            }}
            checked={isSelected}
          />
        </div>

        <div style={{ width: "20%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              padding: "0px 5px",
            }}
          >
            {title}
          </Typography>
        </div>
        <div style={{ width: "40%", padding: 5 }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontSize: "14px", paddingLeft: "5px" }}
            href={url}
          >
            {url}
          </a>
        </div>

        {/* <div style={{ width: "35%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              padding: "0px 5px",
            }}
          >
            <KeyboardArrowRightIcon />
          </Typography>
        </div> */}
        <div style={{ width: "20%" }} align="center">
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              padding: "3px 8px",
              bordere: "none",
              backgroundColor:
                sentiment_label[0] === "Neutral"
                  ? "yellow"
                  : sentiment_label[0] === "Positive"
                  ? "green"
                  : "red",
              color: sentiment_label[0] === "Neutral" ? "inherit" : "#FFF",
              width: "45%",
              borderRadius: "8px",
            }}
          >
            {sentiment_label[0]}
          </Typography>
        </div>
        <div style={{ width: "20%" }} align="center">
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              padding: "0px 5px",
            }}
          >
            <KeyboardArrowRightIcon
              style={{ cursor: "pointer" }}
              onClick={() => setCurrentLink(links)}
            />
          </Typography>
        </div>
      </div>
    );
  };
  // console.log(currentLink);
  const linkDrawer = () => {
    return (
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        open={currentLink}
        onClose={() => setCurrentLink("")}
        variant="persistent"
        width="lg"
      >
        <div
          className="scroll"
          style={{ width: "50vw", height: "90vh", padding: 10 }}
        >
          <CloseIcon
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => setCurrentLink("")}
          />
          {currentLink.sentiment_label[0] === "Negative" && (
            <Select
              fullWidth
              style={{
                border: "1px solid #C0D4E9",
                backgroundColor: "#FEFEFE",
                borderRadius: "8px",
                // height: "40px",
                margin: "15px 0px",
              }}
              value={currentLink.controversy}
              onChange={(event) => {
                const {
                  target: { value },
                } = event;
                setCurrentLink((prev) => ({
                  ...prev,
                  controversy: value,
                }));
              }}
              input={<OutlinedInput />}
            >
              {Object.keys(contoData).map((item) => {
                return (
                  <MenuItem
                    key={item}
                    value={item}
                    style={{ backgroundColor: "white" }}
                  >
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          )}

          <Typography
            style={{
              fontSize: "16px",
              fontWeight: 400,
              padding: "5px 5px",
              marginTop: currentLink.sentiment_label[0] !== "Negative" ? 30 : 0,
            }}
          >
            Title : {currentLink.title}
          </Typography>
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: 400,
              padding: "5px 5px",
            }}
          >
            Sentiment : {currentLink.sentiment_label[0]}
          </Typography>
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: 400,
              padding: "5px 5px",
            }}
          >
            Overall Sentiment : {currentLink.overall_sentiment[0]}
          </Typography>
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: 400,
              padding: "5px 5px",
            }}
          >
            Link :
            <a
              rel="noopener noreferrer"
              style={{ fontSize: "16px", paddingLeft: "5px" }}
              href={currentLink.url}
              target="_blank"
            >
              {currentLink.url}
            </a>
          </Typography>
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: 400,
              padding: "5px 5px",
            }}
          >
            Sentences : {currentLink.sentences}
          </Typography>
        </div>
        <div style={{ position: "fixed", bottom: 0, right: 2 }}>
          {/* <CustomButton
            style={{
              textTransform: "none",
              margin: "20px 10px",
              float: "right",
            }}
            size="small"
            color="primary"
            variant="outlined"
          >
            <Typography>Hide Controversy</Typography>
          </CustomButton> */}
          {currentLink.sentiment_label[0] === "Negative" && (
            <CustomButton
              style={{
                textTransform: "none",
                margin: "20px 10px",
                float: "right",
              }}
              size="small"
              color="primary"
              variant="contained"
              onClick={() => {
                updateControversyType(
                  login.token,
                  currentLink.id,
                  currentLink.controversy,
                  state?.isin
                );
              }}
            >
              <PublishIcon style={{ paddingRight: 5 }} />
              <Typography>{` Save `}</Typography>
            </CustomButton>
          )}
        </div>
      </Drawer>
    );
  };

  const ViewControversy = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            marginTop: "2%",
            paddingLeft: 10,
            paddingTop: "10px",
          }}
        >
          <ArrowBackIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsControversyOpen(false);
              setCurrentControversy("");
            }}
          />{" "}
          <Typography style={{ paddingLeft: 10 }}>
            {currentControversy}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            padding: "5px 25px",
          }}
        >
          <CustomButton
            style={{ textTransform: "none", marginRight: 10 }}
            size="small"
            color="primary"
            variant="contained"
          >
            <RefreshIcon style={{ paddingRight: 5 }} />{" "}
            <Typography>Regenerate Links</Typography>
          </CustomButton>
          {/* <CustomButton
            style={{ textTransform: "none", marginRight: 10 }}
            size="small"
            color="primary"
            variant="outlined"
          >
            <Typography>Hide Controversy</Typography>
          </CustomButton> */}
        </div>
        <div>
          {linksList?.length > 0 &&
            linksList.map((link, i) => {
              return (
                <div
                  style={{
                    display: "flex",
                    paddingLeft: 10,
                    padding: "8px 35px",
                  }}
                >
                  <Typography> {`${i + 1})`}</Typography>{" "}
                  <a
                    rel="noopener noreferrer"
                    style={{ fontSize: "16px", paddingLeft: "5px" }}
                    href={link.link}
                    target="_blank"
                  >
                    {link.link}
                  </a>
                </div>
              );
            })}
        </div>
      </>
    );
  };

  const handlePublish = () => {
    let selectedToPublsh = linksList
      .filter((link) => (link?.isSelected ? link.id : null))
      .map((link) => link.id);
    console.log(selectedToPublsh);
    publishLinks(
      login.token,
      {
        ids: selectedToPublsh,
      },
      state?.isin
    );
  };

  return (
    <div style={{ overflow: "none" }}>
      <div style={{ display: "flex", paddingLeft: 10 }}>
        <ArrowBackIcon
          style={{ cursor: "pointer" }}
          onClick={() => history.goBack()}
        />
        <Typography style={{ paddingLeft: 10 }}>
          {state.company_name}
        </Typography>
      </div>

      <div>
        <MuiThemeProvider theme={appTheme}>
          <AppBar position="static" color="inherit" className={classes.AppBar}>
            <Tabs
              value={adminTabNo}
              onChange={(e, newVal) => {
                setAdminTabNo(newVal);
              }}
              // onChange={handleTabChange}
            >
              <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}>KPI Data</span>}
              />
              <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}>Controversies</span>}
              />
              <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}> Insights</span>}
              />
            </Tabs>
          </AppBar>
        </MuiThemeProvider>
        <TabPanel value={adminTabNo} index={0}>
          {/* <CompanyProfile /> */}
          <Grid
            className="scroll"
            style={{ height: "75vh", padding: "10px 60px", marginTop: "20px" }}
          >
            <div
              style={{
                display: "flex",
                borderRadius: 5,
                backgroundColor: "#EBF1F8",
              }}
            >
              <div style={{ width: "3%" }}>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    padding: "0px 5px",
                  }}
                >
                  {`No.`}
                </Typography>
              </div>
              <div style={{ width: "47%" }}>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    padding: "0px 5px",
                  }}
                >
                  {`Field`}
                </Typography>
              </div>
              <div style={{ width: "30%" }} align="center">
                <Typography> Values</Typography>
              </div>
              <div style={{ width: "20%" }} align="center">
                <Typography>Actions</Typography>
              </div>
              {/* <div style={{ width: "20%" }} align="center">
              <Typography> country</Typography>
            </div>
            <div style={{ width: "10%" }} align="center">
              <Typography> Actions</Typography>
            </div> */}
            </div>

            <div style={{ marginTop: "15px", paddingBottom: "40px" }}>
              {Object.keys(kpiList).length > 0 &&
                Object.keys(kpiList).map((key, i) => viewKpiDataList(key, i))}
              {/* {viewKpiDataList(kpiList)} */}
              {/* {riskDashboard &&
              riskDashboard?.currentCompanyData &&
              viewKpiDataList(riskDashboard?.currentCompanyData)} */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  paddingTop: 10,
                }}
              >
                {/* {console.log("rrrrrrrrrr", result)} */}
                <CustomButton
                  margin="dense"
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    updateCompanyData(
                      login.token,
                      state._id,
                      result,
                      setAdminTabNo
                    );
                  }}
                >
                  Submit
                </CustomButton>
              </div>
            </div>
          </Grid>
        </TabPanel>
        <TabPanel value={adminTabNo} index={1}>
          <div>
            {currentLink && linkDrawer()}
            {!isControversyOpen ? (
              <Grid style={{ padding: "10px 60px", marginTop: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    borderRadius: 5,
                    backgroundColor: "#EBF1F8",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 500,
                        padding: "0px 5px",
                      }}
                    >
                      {`Name of the controversy`}
                    </Typography>
                  </div>

                  <div style={{ width: "20%" }}>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 500,
                        padding: "0px 5px",
                      }}
                    >
                      {`Count`}
                    </Typography>
                  </div>

                  <div style={{ width: "20%" }} align="right">
                    <Typography>Actions</Typography>
                  </div>
                </div>

                <div style={{ marginTop: "15px", paddingBottom: "40px" }}>
                  {Object.entries(contoData).map(
                    ([key, value]) =>
                      key !== "null" && viewControversyList(key, value)
                  )}
                </div>

                <div style={{ marginTop: "15px", paddingBottom: "40px" }}>
                  <div
                    style={{
                      display: "flex",
                      borderRadius: 5,
                      backgroundColor: "#EBF1F8",
                    }}
                  >
                    <div style={{ width: "4%" }}></div>
                    <div style={{ width: "22%" }}>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: 500,
                          padding: "0px 5px",
                        }}
                      >
                        {`Title`}
                      </Typography>
                    </div>

                    <div style={{ width: "40%" }}>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: 500,
                          padding: "0px 5px",
                        }}
                      >
                        {`Links`}
                      </Typography>
                    </div>
                    <div style={{ width: "20%" }} align="center">
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: 500,
                          padding: "0px 5px",
                        }}
                      >
                        {`Sentiment`}
                      </Typography>
                    </div>

                    <div style={{ width: "20%" }} align="center">
                      <Typography>Actions</Typography>
                    </div>
                  </div>
                  {linksList &&
                    linksList.map((link, index) => viewLinks(link, index))}
                </div>
                <div>
                  <CustomButton
                    style={{
                      textTransform: "none",
                      margin: "20px 10px",
                      float: "right",
                    }}
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={handlePublish}
                  >
                    <PublishIcon style={{ paddingRight: 5 }} />
                    <Typography>Publish Controversies</Typography>
                  </CustomButton>
                </div>
              </Grid>
            ) : (
              <ViewControversy />
            )}
          </div>
        </TabPanel>
        <TabPanel value={adminTabNo} index={2}>
          <div style={{ padding: 20 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                padding: "5px 25px",
              }}
            >
              <CustomButton
                style={{ textTransform: "none", marginRight: 10 }}
                size="small"
                color="primary"
                variant="contained"
              >
                <RefreshIcon style={{ paddingRight: 5 }} />{" "}
                <Typography>Regenerate Insights</Typography>
              </CustomButton>
              {/* <CustomButton
                style={{ textTransform: "none", marginRight: 10 }}
                size="small"
                color="primary"
                variant="outlined"
              >
                <Typography>Hide Controversy</Typography>
                
              </CustomButton>
                */}
            </div>
            <OverallProfile {...props} />
          </div>
        </TabPanel>
      </div>
    </div>
  );
}

export default RiskDocument;
