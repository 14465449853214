import React, { useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core";

import Divider from "@material-ui/core/Divider";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    // border: "1px solid #EBF3FB",
    padding: "5px 20px 5px 10px",
    cursor: "pointer",
    "&:hover": {
      ...theme.typography.body1,
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.dark,
      // fontSize: "14px",
      // fontWeight: 600,
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },
  },
  typography: {
    ...theme.typography.h5,
    color: theme.palette.primary.dark,
    padding: "10px",
    // border:"solid",
    // textAlign:"center"
  },
  typography2: {
    ...theme.typography.body1,
    color: theme.palette.primary.dark,
    padding: "10px",
    // border:"solid",
    // textAlign:"center"
  },
}));

export function CompanyTitle({ title }) {
  const classes = useStyles();
  return <Typography className={classes.typography}>{title}</Typography>;
}

export function CompanyDetailRow({ heading, info }) {
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        padding: "5px",
        flexWrap: "wrap",
        gap: "15px",
      }}
    >
      <Typography
        className={classes.typography2}
        style={{
          // color: "#15314E",
          // fontSize: "14px",
          // fontWeight: 700,
          // lineHeight: "20px",
          // padding: "10px",
          width: "15%",
          // fontFamily: "Lato",
        }}
      >
        {heading}
      </Typography>
      <Typography
        className={classes.typography2}
        style={{
          // color: "#333F48",
          // fontSize: "14px",
          // fontWeight: 700,
          // lineHeight: "20px",
          // padding: "10px",
          // fontFamily: "Lato",
          marginLeft: "auto",
        }}
      >
        :
      </Typography>
      <Typography
        className={classes.typography2}
        style={{
          // color: "#15314E",
          // fontSize: "14px",
          // fontWeight: 500,
          // lineHeight: "20px",
          // padding: "10px",
          width: "75%",
        }}
      >
        {info}
      </Typography>
    </div>
  );
}

function EsgGptNewCompanyDetails({ height = "100%" }) {
  const { newOrganization } = useSelector((state) => state.esgGptReducer);

  // console.log('newOrganization',newOrganization)

  React.useEffect(() => {
    scrollToTop();
  }, [newOrganization]);
  const compantTopRef = useRef(null);
  
  const scrollToTop = () => {
    compantTopRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  return (
    <div
      style={{
        height,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
      }}
    >
      <div ref={compantTopRef} />
      <CompanyTitle title={newOrganization?.name} />

      <Divider />

      <CompanyDetailRow heading={"Sector"} info={"Information technology"} />

      <Divider />

      <CompanyDetailRow
        heading={"Industry"}
        info={newOrganization?.industry}
      />

      <Divider />

      <CompanyDetailRow
        heading={"Market Cap"}
        info={newOrganization?.mark}
      />

      <Divider />
    </div>
  );
}

export default EsgGptNewCompanyDetails;
