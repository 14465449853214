import React from "react";

import { useDispatch, useSelector } from "react-redux";

import Divider from "@material-ui/core/Divider";
import { Button, TextField, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { getSectorIndustries } from "../../../../redux/actions/brm/BrmActions";
import { createOrganizationEsgGpt } from "../../../../redux/actions/gpt/esgGptAction";
import useSector from "./useSector";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    ...theme.typography.body2,
    backgroundColor: theme.palette.secondary.dark,
    width: "48%",
    marginLeft: "auto",
    marginTop: "10px",
    color: theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius,
    textTransform: "capitalize",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.secondary.main,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.secondary.main,
    },
  },
}));

function EsgGptFormField() {
  const theme = useTheme();
  const classes = useStyles();

  const dispath = useDispatch();

  const login = useSelector((state) => state.login);

  const {sectorIndustries} = useSector();

  // const { sectorIndustries } = useSelector((state) => state.brmData);

  const { newLogo } = useSelector((state) => state.esgGptReducer);

  const [sector, setSector] = React.useState("");

  const [filterIndustry, setFilterIndustry] = React.useState([]);

  const [industry, setIndustry] = React.useState({
    industry: "",
    industry_code: "",
  });

  const [companyDetails, setCompanyDetails] = React.useState({
    name: "",
    isin: "",
    url: "",
    cin: "",
    gst: "",
    image: "",
  });

  const handleSectorChange = (event) => {
    const targetValue = event.target.value;

    setSector(targetValue);

    const filterIndustr = sectorIndustries.find(
      (value) => value.Sector === targetValue
    );

    filterIndustr !== undefined && filterIndustr.industry_data
      ? setFilterIndustry(filterIndustr?.industry_data)
      : setFilterIndustry([]);
  };

  const handleIndustryChange = (event, industry) => {
    const targetValue = event.target.value;

    const findIndustryCode = industry.find((v) => v.Industry === targetValue);

    setIndustry((preValue) => ({
      ...preValue,
      industry: targetValue,
      industry_code: findIndustryCode?.industry_code,
    }));
  };

  const handleCompanyDetails = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setCompanyDetails((preValue) => ({
      ...preValue,
      [targetName]: targetValue,
    }));
  };

  const handleDetailSubmission = () => {
    dispath(
      createOrganizationEsgGpt(
        login.token,
        companyDetails.name,
        companyDetails.isin,
        sector,
        industry.industry,
        industry.industry_code,
        newLogo
      )
    );
  };

  React.useEffect(() => {
    dispath(getSectorIndustries(login.token));
  }, []);

  const infoArr = [
    { label: "Enter Company Name", name: "name" },
    { label: "ISIN No", name: "isin" },
    { label: "Enter Company URL", name: "url" },
    { label: "CIN No", name: "cin" },
    { label: "GST No", name: "gst" },
  ];

  const infoAddressArr = [
    { label: "Operation Address 1", name: "Op_Add_1" },
    { label: "Operation Address 2", name: "Op_Add_2" },
    { label: "City/State", name: "city" },
    { label: "Pincode", name: "pin" },
  ];

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        // border: "solid blue",
      }}
    >
      <Typography
        style={{
          color: theme.palette.primary.main,
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "20px",
          padding: "10px",
          // flex: 1,
        }}
      >
        Basic Details
      </Typography>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "4px",
          flexWrap: "wrap",
          // gap: "15px",
        }}
      >
        {infoArr.map(({ label, name }) => (
          <TextField
            style={{ marginTop: "5px", width: "48%" }}
            id="outlined-basic"
            label={label}
            variant="outlined"
            name={name}
            onChange={handleCompanyDetails}
          />
        ))}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "48%" }}>
          <Typography
            style={{
              color: theme.palette.primary.main,
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "20px",
              padding: "10px",
            }}
          >
            Sector
          </Typography>
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "4px",
              flexWrap: "wrap",
              // gap: "15px",
            }}
          >
            <FormControl
              variant="outlined"
              style={{ width: "100%", marginTop: "10px" }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Sector
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={sector}
                onChange={handleSectorChange}
                label="Sector"
                MenuProps={{
                  PaperProps: {
                    style: {
                      backdropFilter: "blur(10px)",
                      backgroundColor: "#00000030",
                    },
                  },
                }}
              >
                {sectorIndustries.map(({ _id, Sector }) => (
                  <MenuItem key={_id} value={Sector}>
                    {Sector}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div style={{ width: "48%" }}>
          <Typography
            style={{
              color: theme.palette.primary.main,
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "20px",
              padding: "10px",
            }}
          >
            Industry
          </Typography>
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "4px",
              flexWrap: "wrap",
              // gap: "15px",
            }}
          >
            <FormControl
              variant="outlined"
              style={{ width: "100%", marginTop: "10px" }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Industry
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={industry?.industry}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backdropFilter: "blur(10px)",
                      backgroundColor: "#00000030",
                    },
                  },
                }}
                onChange={(e) => handleIndustryChange(e, filterIndustry)}
                label="Industry"
              >
                {filterIndustry.map(({ _id, Industry, industry_code }) => (
                  <MenuItem key={_id} value={Industry}>
                    {Industry}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>

      <Typography
        style={{
          color: theme.palette.primary.main,
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "20px",
          padding: "10px",
        }}
      >
        Geography (If available)
      </Typography>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "5px",
          flexWrap: "wrap",
          // gap: "15px",
        }}
      >
        {infoAddressArr.map(({ label, name }) => (
          <TextField
            style={{ width: "48%", marginTop: "5px" }}
            id="outlined-basic"
            label={label}
            variant="outlined"
            name={name}
          />
        ))}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          height: "60px",
          marginTop: "20px",
        }}
      >
        <Button
          variant="contained"
          className={classes.root}
          //   color="primary"

          // onClick={handleDetailSubmission}
          // className={classes.button}
        >
          Acquire Details
        </Button>
      </div>
    </div>
  );
}

export default EsgGptFormField;
